import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { get } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Column, Row } from 'styled-components-grid';
import { Popover, Icon, Text, H4 } from 'base-components';
import Tag from 'blocks/Tag';

const assetAttributes = [
  ['makeName', 'modelName', 'year'],
  'licensePlate',
  'licenseStateAbbreviation',
  'vin',
  'odometer',
];

const labelByAttribute = {
  makeName: t`Make`,
  modelName: t`Model`,
  year: t`Year`,
  licensePlate: t`License Plate`,
  licenseStateAbbreviation: t`License State`,
  vin: t`VIN`,
  odometer: t`Odometer`,
};

/**
 *  Get asset attributes
 * @param {*} asset - Asset data
 * @returns  {Array} Array of objects with label and value keys
 */
function getAssetAttributes(asset) {
  const attributes = assetAttributes.reduce((acc, attribute) => {
    if (Array.isArray(attribute)) {
      const values = attribute.map((key) => get(asset, key)).filter(Boolean);
      if (values.length) {
        const label = attribute
          .map((key) => labelByAttribute[key])
          .filter(Boolean)
          .join('/');
        const value = values.join(' ');
        acc.push({ label, value });
      }
    } else {
      const value = get(asset, attribute);
      if (value) {
        const label = labelByAttribute[attribute] || '';
        acc.push({ label, value });
      }
    }
    return acc;
  }, []);
  return attributes;
}

const AssetAttributes = ({ values }) => {
  const attributes = getAssetAttributes(values);
  if (!attributes.length) return null;

  if (attributes.length <= 2) {
    return (
      <Row modifiers="middle" style={{ columnGap: px2rem(4) }}>
        <Icon modifiers={['mini', 'info']} name="info-circle" />
        {attributes.map(({ label, value }) => (
          <Tag key={label} modifiers="inline">
            <Tag.Text>
              <Trans id={label} />
              {` ${value}`}
            </Tag.Text>
          </Tag>
        ))}
      </Row>
    );
  }

  const mainTag = attributes[0];

  return (
    <Popover position="bottom" showOnHover>
      {({ isVisible }) => (
        <>
          <Popover.Target>
            <Row modifiers="middle">
              <Column modifiers={['center', 'padScale_0']}>
                <Row modifiers="middle" style={{ columnGap: px2rem(4) }}>
                  <Icon modifiers={['mini', 'info']} name="info-circle" />
                  <Tag key={mainTag.label} modifiers="inline">
                    <Tag.Text>{mainTag.value}</Tag.Text>
                  </Tag>
                </Row>
              </Column>
              <Column modifiers={['center', 'padScaleY_0']}>
                <Icon
                  modifiers={['mini', isVisible && 'info']}
                  name={isVisible ? 'chevron-up' : 'chevron-down'}
                />
              </Column>
            </Row>
          </Popover.Target>
          <Popover.Content>
            <Container
              style={{
                width: px2rem(300),
                maxHeight: px2rem(200),
                overflowY: 'auto',
                textAlign: 'left',
              }}
            >
              <Row>
                <Column modifiers={['col', 'padScaleX_3']}>
                  <H4 modifiers="fontWeightRegular">
                    <Trans>Asset Attributes</Trans>
                  </H4>
                </Column>
              </Row>
              <Row>
                <Column modifiers={['col', 'padScaleX_3']}>
                  {attributes.map(({ label, value }) => (
                    <Row key={label} modifiers="middle">
                      <Column modifiers={['col_5']}>
                        <Text modifiers="textLight">
                          <Trans id={label} />
                        </Text>
                      </Column>
                      <Column modifiers={['col']}>
                        <Text modifiers="textLight">
                          {value ? ` ${value}` : <span>&mdash;</span>}
                        </Text>
                      </Column>
                    </Row>
                  ))}
                </Column>
              </Row>
            </Container>
          </Popover.Content>
        </>
      )}
    </Popover>
  );
};

AssetAttributes.propTypes = {
  values: PropTypes.shape({
    makeName: PropTypes.string,
    modelName: PropTypes.string,
    year: PropTypes.string,
    vin: PropTypes.string,
    licensePlate: PropTypes.string,
    licenseStateAbbreviation: PropTypes.string,
    odometer: PropTypes.string,
  }),
};

export default AssetAttributes;
