import { isEmpty, values, flatten, startCase } from 'lodash';
import { compose, mapProps, withState } from 'recompose';

import withRequestAssets from 'compositions/CaseRequestsPanel/RequestAssetsForm/withRequestAssets';
import assetTypeNames from 'compositions/CaseRequestsPanel/RequestAssetsForm/RequestAsset/assetTypeNames';
import unitAttributeNames from 'compositions/CaseRequestsPanel/RequestAssetsForm/RequestAsset/unitAttributeNames';

const normalizeAttribute = (str) => (str ? startCase(str.toLowerCase()) : '');

const catchAllPreference = 'Alternate Options';

const getAttributeName = (unitAttribute) =>
  unitAttributeNames[unitAttribute] || normalizeAttribute(unitAttribute);

const buildAssetGroups = (assets) =>
  assets
    .map(({ assetType, unitAttribute }) =>
      assetType && unitAttribute
        ? `${assetTypeNames[assetType]} - ${getAttributeName(unitAttribute)}`
        : assetType && assetTypeNames[assetType],
    )
    .filter(Boolean);

const filterTirePreferencesBy = (tirePreferences, filter) =>
  Object.keys(tirePreferences).reduce(
    (acc, key) => ({
      ...acc,
      [key]: tirePreferences[key].filter(filter),
    }),
    {},
  );

export const filterByAssets = (props) => {
  const { tirePreferences, assets } = props;

  const assetsGroups = buildAssetGroups(assets);
  const hasAssets = assetsGroups?.length > 0;
  const matchAssets = (application) =>
    application.toLowerCase().startsWith(catchAllPreference.toLowerCase()) ||
    assetsGroups.some((asset) =>
      application.toLowerCase().startsWith(asset.toLowerCase()),
    );

  const unusedTirePreferences = filterTirePreferencesBy(
    tirePreferences,
    ({ application }) => hasAssets && !matchAssets(application),
  );

  const canFilterByAssets =
    hasAssets && !isEmpty(flatten(values(unusedTirePreferences)));

  const baseProps = { ...props, canFilterByAssets };

  if (!props.shouldFilterByAssets || !canFilterByAssets) {
    return baseProps;
  }

  const matchingTirePreferences = filterTirePreferencesBy(
    tirePreferences,
    ({ application }) => matchAssets(application),
  );

  return {
    ...baseProps,
    canFilterByAssets,
    tirePreferences: matchingTirePreferences,
  };
};

export default compose(
  withState('shouldFilterByAssets', 'setFilterbyAssets', false),
  withRequestAssets,
  mapProps((props) => ({
    ...props,
    assets: [props.primaryAsset, ...(props.relatedAssets || [])].filter(
      Boolean,
    ),
  })),
  mapProps(filterByAssets),
);
