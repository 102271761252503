import React, { Component } from 'react';
import { get, isEmpty } from 'lodash';
import { Trans, t } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';
import { withReadOnlyCase } from 'compositions/CaseStatus';
import VisuallyHidden from 'elements/VisuallyHidden';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import PropTypes from '../prop-types';
import withRemovePaymentMutation from '../withRemovePaymentMutation';
import withSetPaymentDetails from '../withSetPaymentDetails';
import NationalExistingCard from './NationalExistingCard';
import NationalNoCreditCard from './NationalNoCreditCard';

import {
  fieldIds,
  CREDIT_CARD_RULE_TYPES,
  PAYMENT_METHODS,
  PANEL_TITLE,
} from '../constants';

const OPTIONS = {
  MICHELIN_LINE_OF_CREDIT: {
    key: 'national',
    value: PAYMENT_METHODS.MICHELIN_LINE_OF_CREDIT,
    label: t`National Account`,
  },
  CARD_ON_FILE: {
    key: 'national-existing-credit-card',
    value: PAYMENT_METHODS.CARD_ON_FILE,
    label: t`Existing Credit Card`,
  },
  CREDIT_CARD_NO_PREAUTH: {
    key: 'other-pre-auth',
    value: PAYMENT_METHODS.CREDIT_CARD_NO_PREAUTH,
    label: t`Credit Card - No Preauth`,
  },
  OTHER_NATIONAL_ACCOUNT: {
    key: 'national-other-national-account',
    value: PAYMENT_METHODS.OTHER_NATIONAL_ACCOUNT,
    label: t`Other National Account`,
  },
  NONE: {
    key: 'national-none',
    value: PAYMENT_METHODS.NONE,
    label: t`Continue without payment method`,
  },
};

const creditCardTypes = [OPTIONS.CARD_ON_FILE.key];

function hasCreditCardType(selectedOption) {
  return creditCardTypes.includes(selectedOption);
}

function getPaymentOption(paymentMethod) {
  return get(OPTIONS[paymentMethod], 'key', '');
}

function getPaymentLabel(paymentMethod) {
  return get(OPTIONS[paymentMethod], 'label', '');
}

class National extends Component {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    caseNumber: PropTypes.string.isRequired,
    creditCardRuleAbbreviation: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    removePaymentDetails: PropTypes.func.isRequired,
    removePaymentDetailsLoading: PropTypes.bool.isRequired,
    tokenizedCard: PropTypes.tokenizedCard,
    setPaymentDetails: PropTypes.func.isRequired,
    onExistingCCFocusRequested: PropTypes.func.isRequired,
    onNewCCFocusRequested: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tokenizedCard: null,
  };

  state = {
    selectedOption: getPaymentOption(this.props.paymentMethod),
  };

  componentDidUpdate(prevProps, prevState) {
    /*
      This logic tracks when the selectedOption is between existing or new credit card.
    */
    const optionHasChanged =
      this.state.selectedOption !== prevState.selectedOption;

    const paymentMethodHasChanged =
      prevProps.paymentMethod !== this.props.paymentMethod;

    // Checks to see if the cache has changed
    if (paymentMethodHasChanged && !isEmpty(this.props.paymentMethod)) {
      // eslint-disable-next-line
      this.setState({
        selectedOption: getPaymentOption(this.props.paymentMethod),
      });
    }

    const changingFromCreditCardToCreditCard =
      hasCreditCardType(this.state.selectedOption) &&
      hasCreditCardType(prevState.selectedOption);

    /*
        Clears the paymentMethod so we can clear the tokenizedCard when switching between
        existing and new credit card.
      */
    if (
      optionHasChanged &&
      changingFromCreditCardToCreditCard &&
      this.props.tokenizedCard
    ) {
      this.clearPaymentDetails();
    }
  }

  clearPaymentDetails = () => {
    const { caseId } = this.props;
    this.props.removePaymentDetails({ variables: { caseId } });
  };

  handleChange = (selectedOption) => {
    this.props.setPaymentDetails({ paymentMethod: selectedOption.value });
    this.setState({ selectedOption: selectedOption.key });
  };

  renderForm = () => {
    const { selectedOption } = this.state;
    const {
      creditCardRuleAbbreviation,
      caseId,
      caseNumber,
      removePaymentDetailsLoading,
      tokenizedCard,
      isReadOnlyCase,
    } = this.props;

    /*
      When switching from existing to new credit card or vice versa, we are waiting for the
      paymentMethod(along with the tokenizedCard) to clear. So if we are removing the paymentMethod
      we just don't display the tokenizedCard;
    */
    const displayedTokenizedCard = !removePaymentDetailsLoading
      ? tokenizedCard
      : null;

    const creditCardOptional =
      CREDIT_CARD_RULE_TYPES[creditCardRuleAbbreviation] ===
      CREDIT_CARD_RULE_TYPES.O;

    return (
      <Row>
        <Column modifiers="col">
          {creditCardOptional && (
            <Row>
              <Column modifiers={['col', 'padScaleY_2']}>
                <RadioButton
                  id={OPTIONS.MICHELIN_LINE_OF_CREDIT.key}
                  checked={
                    selectedOption === OPTIONS.MICHELIN_LINE_OF_CREDIT.key
                  }
                  label={<Trans id={OPTIONS.MICHELIN_LINE_OF_CREDIT.label} />}
                  onChange={() =>
                    this.handleChange(OPTIONS.MICHELIN_LINE_OF_CREDIT)
                  }
                  readOnly={isReadOnlyCase}
                />
              </Column>
            </Row>
          )}
          <Row ref={this.props.onExistingCCFocusRequested}>
            <Column modifiers="col">
              <Row>
                <Column modifiers="padScaleX_0">
                  <RadioButton
                    id={OPTIONS.CARD_ON_FILE.key}
                    checked={selectedOption === OPTIONS.CARD_ON_FILE.key}
                    label={<Trans id={OPTIONS.CARD_ON_FILE.label} />}
                    onChange={() => this.handleChange(OPTIONS.CARD_ON_FILE)}
                    readOnly={isReadOnlyCase}
                  />
                </Column>
              </Row>
              {selectedOption === OPTIONS.CARD_ON_FILE.key && (
                <NationalExistingCard
                  caseId={caseId}
                  caseNumber={caseNumber}
                  isReadOnlyCase={isReadOnlyCase}
                  tokenizedCard={displayedTokenizedCard}
                />
              )}
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.CREDIT_CARD_NO_PREAUTH.key}
                checked={selectedOption === OPTIONS.CREDIT_CARD_NO_PREAUTH.key}
                label={<Trans id={OPTIONS.CREDIT_CARD_NO_PREAUTH.label} />}
                onChange={() =>
                  this.handleChange(OPTIONS.CREDIT_CARD_NO_PREAUTH)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_2']}>
              <RadioButton
                id={OPTIONS.OTHER_NATIONAL_ACCOUNT.key}
                checked={selectedOption === OPTIONS.OTHER_NATIONAL_ACCOUNT.key}
                label={<Trans id={OPTIONS.OTHER_NATIONAL_ACCOUNT.label} />}
                onChange={() =>
                  this.handleChange(OPTIONS.OTHER_NATIONAL_ACCOUNT)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          {!creditCardOptional && (
            <Row>
              <Column modifiers={['col', 'padScaleY_2']}>
                <RadioButton
                  id={OPTIONS.NONE.key}
                  checked={selectedOption === OPTIONS.NONE.key}
                  label={<Trans id={OPTIONS.NONE.label} />}
                  onChange={() => this.handleChange(OPTIONS.NONE)}
                  readOnly={isReadOnlyCase}
                />
              </Column>
            </Row>
          )}
        </Column>
      </Row>
    );
  };

  render() {
    const { creditCardRuleAbbreviation, paymentMethod } = this.props;

    const isNationalAccount = [
      CREDIT_CARD_RULE_TYPES.N,
      CREDIT_CARD_RULE_TYPES.Z,
    ].includes(CREDIT_CARD_RULE_TYPES[creditCardRuleAbbreviation]);

    const selectedOptionLabel = getPaymentLabel(paymentMethod);

    return (
      <Row>
        <Column modifiers={['col', 'padScaleX_0']}>
          {isNationalAccount ? <NationalNoCreditCard /> : this.renderForm()}
        </Column>

        {selectedOptionLabel && (
          <VisuallyHidden>
            <Trans id={PANEL_TITLE + ':'} />
            <Trans id={selectedOptionLabel} />
          </VisuallyHidden>
        )}
      </Row>
    );
  }
}

export default compose(
  setDisplayName('National'),
  withRemovePaymentMutation,
  withSetPaymentDetails,
  withReadOnlyCase,
  withFocusReceiver(fieldIds.national.existingCC, 'onExistingCCFocusRequested'),
  withFocusReceiver(fieldIds.national.newCC, 'onNewCCFocusRequested'),
)(National);
