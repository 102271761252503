import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Dropdown, Text } from 'base-components';

import withAssetTypes from '../withAssetTypes';
import assetTypeNames from '../assetTypeNames';
import trailerTypeNames from '../trailerTypeNames';
import withTrailerTypes from '../withTrailerTypes';
import withInboundProgram from '../../withInboundProgram';
import {
  unitAttributeTypes,
  tractorAttributeNames,
  trailerAttributeNames,
} from '../unitAttributeNames';

import withUnitAttributes from '../withUnitAttributes';

import UnitTypeInputField from './UnitTypeInputField';
import UnitTypesDropdownList from './UnitTypesDropdownList';
import UnitAttributesDropdown from './UnitAttributesDropdown';
import UnitAttributesDropdownList from './UnitAttributesDropdownList';
import ErrorMessage from './ErrorMessage';

import {
  getAssetBaseName,
  getInputValue,
  getIsLoading,
  parseInputValue,
  useInitialInputValue,
  useUnitTypes,
} from './utils';

export function UnitTypeDropdown(props) {
  const { name, handleChange, onValidate, setFieldValue } = props;
  const { readOnly, innerRef, isUsxpress, focusReceiverId } = props;

  const { unitAttributes } = props;
  const tractorAttributes = unitAttributes?.[unitAttributeTypes.TRACTOR];
  const trailerAttributes = unitAttributes?.[unitAttributeTypes.TRAILER];

  const [inputValue, setInputValue] = useInitialInputValue(props);
  const { activeTypeValue, unitAttributeValue } = parseInputValue(inputValue);

  const [isInvalid, setIsInvalid] = useState(false);
  const assetBaseName = getAssetBaseName(props);
  const isLoading = getIsLoading(props);
  const isLastAsset = props.index === 2;

  const {
    filteredAssetTypes,
    filteredTrailerTypes,
    activeType,
    isInvalidType,
    isNotFound,
  } = useUnitTypes(props, activeTypeValue);

  const handleTrailerTypeChange = (value) => {
    setInputValue(trailerTypeNames[value]);
    setIsInvalid(false);
    setFieldValue(name, 'TRAILER', false);
    setFieldValue(`${assetBaseName}.unitAttribute`, null, false);
    handleChange({
      target: {
        name: `${assetBaseName}.trailerType`,
        value,
      },
    });
    onValidate(true);
  };

  const handleAssetTypeChange = (value) => {
    const [assetType, unitAttribute] = value.split(':');
    const attributeValue =
      (assetType !== unitAttribute && unitAttribute) || null;

    setInputValue(getInputValue({ assetType, unitAttribute }));
    setIsInvalid(false);
    setFieldValue(`${assetBaseName}.trailerType`, null, false);
    setFieldValue(`${assetBaseName}.unitAttribute`, attributeValue, false);
    handleChange({ target: { name, value: assetType } });
    onValidate(true);
  };

  const handleDropdownChange = (_, value) => {
    if (trailerTypeNames[value]) {
      handleTrailerTypeChange(value);
    } else if (value) {
      handleAssetTypeChange(value);
    }
  };

  const handleReset = () => {
    setInputValue('');
    setIsInvalid(false);
    setFieldValue(`${assetBaseName}.trailerType`, null, false);
    setFieldValue(`${assetBaseName}.unitAttribute`, null, false);
    handleChange({ target: { name, value: null } });
    onValidate(true);
  };

  const validate = (invalid) => {
    setIsInvalid(invalid);
    props.onValidate(!invalid);
  };

  const onInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  return (
    <Dropdown
      fullWidth
      hideOnChange
      onChange={handleDropdownChange}
      readOnly={readOnly}
      disabled={isLoading}
      activeItem={activeType}
      zIndex={2}
      position={isLastAsset ? 'bottomRight' : 'bottom'}
    >
      {({ show, toggle, isVisible }) => (
        <>
          <UnitAttributesDropdown.Provider isAvailable={isVisible}>
            <Dropdown.Target onClick={show}>
              <UnitTypeInputField
                isDropdownVisible={isVisible}
                onChange={onInputChange}
                onToggle={toggle}
                onReset={handleReset}
                onBlur={() => validate(isNotFound || isInvalidType)}
                value={inputValue}
                readOnly={readOnly}
                isValid={!isInvalid}
                focusReceiverId={focusReceiverId}
                innerRef={innerRef}
              />
            </Dropdown.Target>

            <Dropdown.Content
              style={{
                maxHeight: px2rem(300),
                minWidth: isNotFound ? px2rem(300) : 'unset',
                overflowY: 'auto',
                ...(isLastAsset
                  ? { right: 0, left: 'unset' }
                  : { left: isNotFound ? 'auto' : 0 }),
              }}
            >
              {isUsxpress && !!filteredTrailerTypes.length && (
                <>
                  <Dropdown.SectionHeader>
                    <Text
                      modifiers={['small', 'noWrap', 'capitalize', 'textLight']}
                    >
                      Trailer
                    </Text>
                  </Dropdown.SectionHeader>

                  <UnitTypesDropdownList
                    types={filteredTrailerTypes}
                    getItemValue={(type) => trailerTypeNames[type]}
                    highlightText={activeTypeValue}
                    indentList
                  />
                </>
              )}

              {isUsxpress && !!filteredAssetTypes.length && (
                <Dropdown.SectionHeader>
                  <Text
                    modifiers={['small', 'noWrap', 'capitalize', 'textLight']}
                  >
                    Other
                  </Text>
                </Dropdown.SectionHeader>
              )}

              <UnitTypesDropdownList
                types={filteredAssetTypes}
                getItemValue={(type) => assetTypeNames[type]}
                highlightText={activeTypeValue}
                indentList={isUsxpress && !!filteredAssetTypes.length}
              />

              {isNotFound && <ErrorMessage />}
            </Dropdown.Content>

            <UnitAttributesDropdownList
              parentType={unitAttributeTypes.TRAILER}
              types={trailerAttributes}
              getItemValue={(type) => trailerAttributeNames[type] || type}
              highlightText={unitAttributeValue}
            />

            <UnitAttributesDropdownList
              parentType={unitAttributeTypes.TRACTOR}
              types={tractorAttributes}
              getItemValue={(type) => tractorAttributeNames[type] || type}
              highlightText={unitAttributeValue}
            />
          </UnitAttributesDropdown.Provider>
        </>
      )}
    </Dropdown>
  );
}

UnitTypeDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  // for this component we really don't care the shape of `values`
  // so long it contains something at a key corresponding to our `name`
  assetTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  readOnly: PropTypes.bool.isRequired,
  trailerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValidate: PropTypes.func.isRequired,
  isUsxpress: PropTypes.bool.isRequired,
  focusReceiverId: PropTypes.string.isRequired,
  innerRef: PropTypes.func,
  unitAttributes: PropTypes.shape({
    [unitAttributeTypes.TRAILER]: PropTypes.arrayOf(PropTypes.string),
    [unitAttributeTypes.TRACTOR]: PropTypes.arrayOf(PropTypes.string),
  }),

  /* eslint-disable react/no-unused-prop-types */
  values: PropTypes.shape().isRequired,
  isLoadingAssetTypes: PropTypes.bool.isRequired,
  isLoadingTrailerTypes: PropTypes.bool.isRequired,
  isLoadingInboundProgram: PropTypes.bool.isRequired,
  /* eslint-enable react/no-unused-prop-types */
};

export default compose(
  setDisplayName('UnitTypeDropdown'),
  withAssetTypes,
  withTrailerTypes,
  withInboundProgram,
  withUnitAttributes,
)(UnitTypeDropdown);
