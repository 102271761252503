import React, { useCallback } from 'react';
import { get, omit } from 'lodash';
import { useMutation, gql } from '@apollo/client';

import { RequestLineFieldsFragment } from '../constants';
import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';
import { parseRequestLinesPatch } from '../utils';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation updateSuppliedLine($id: ID!, $patch: CaseSuppliedLinePatch!) {
    updateCaseSuppliedLine(input: { id: $id, patch: $patch }) {
      requestSuppliedLine {
        supplied
        suppliedLine {
          ...RequestLineFields
        }
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withUpdateCaseSuppliedLineMutation = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const updateLine = useCallback(
    ({ patch = {}, ...rest }) => {
      const line = omit(patch.suppliedLine, 'asset');
      const assetId = get(patch, 'suppliedLine.asset.id');
      const suppliedLine = parseRequestLinesPatch({ ...line, assetId });
      const variables = { ...rest, patch: { ...patch, suppliedLine } };

      return mutate({ variables });
    },
    [mutate],
  );

  return <Component {...props} updateRequestSuppliedLine={updateLine} />;
};

export default withUpdateCaseSuppliedLineMutation;
