/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { QuickActionButton } from 'base-components';

import withContext from 'utils/withContext';
import USER_ROLES from 'features/rbac/constants';
import withCaseSavingStatus from 'compositions/CaseSaveIndicator/withCaseSavingStatus';
import withCaseMismatches from 'compositions/CaseMismatchesBar/withCaseMismatches';

import StatusBar from './StatusBar';
import CaseCancelButton from './CaseCancelButton';
import CaseRestoreButton from './CaseRestoreButton';
import UpdateStatusModal from './UpdateStatusModal';
import CaseStatusContext from '../CaseStatusContext';
import { PANEL_STATUSES, CASE_STATUS } from '../constants';

const allStatuses = Object.values(CASE_STATUS);
const canceledStatuses = [CASE_STATUS.canceled, CASE_STATUS.dry_run_canceled];

const { complete, invalid } = PANEL_STATUSES;
const { rolling, closed_canceled, closed } = CASE_STATUS;

const getNextLogicStatus = (status) => {
  const currentIndex = allStatuses.indexOf(status);

  return currentIndex === -1 || !allStatuses[currentIndex + 1]
    ? status
    : allStatuses[currentIndex + 1];
};

export class CasePanelStatuses extends Component {
  static propTypes = {
    role: PropTypes.string.isRequired,
    caseId: PropTypes.string,
    status: PropTypes.string.isRequired,
    isSaving: PropTypes.bool.isRequired,
    reopenCase: PropTypes.func.isRequired,
    caseNumber: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string),
    isReadOnlyCase: PropTypes.bool.isRequired,
    hasClosedStatus: PropTypes.bool.isRequired,
    validationStatus: PropTypes.string.isRequired,
    updateCaseStatus: PropTypes.func.isRequired,
    casePanelStatuses: PropTypes.shape({}),
    hasBeenDispatched: PropTypes.bool.isRequired,
    hasDispatchedStatus: PropTypes.bool.isRequired,
    isLoadingCaseStatus: PropTypes.bool.isRequired,
    isUpdatingCaseStatus: PropTypes.bool.isRequired,
    isPermissionsBasedRole: PropTypes.bool.isRequired,
    caseAssignedToCurrentUser: PropTypes.bool.isRequired,
    mismatches: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  static defaultProps = {
    caseId: undefined,
    permissions: [],
    casePanelStatuses: {},
  };

  state = { showModal: false };

  get isReadOnlyAdmin() {
    const userRole = USER_ROLES[this.props.role];

    return userRole === USER_ROLES.ONCALL_ADMIN_READ_ONLY;
  }

  get hasUpdateStatusPermission() {
    const { permissions, isPermissionsBasedRole } = this.props;
    const hasPermission = permissions.includes('case:update_status');

    // If the user is not a "permissions based role",
    // we assume he has permission to manage the case status.
    return !isPermissionsBasedRole || hasPermission;
  }

  showModal = () => this.setState({ showModal: true });

  hideModal = () => this.setState({ showModal: false });

  renderMainActionButton = () => {
    const { status, isLoadingCaseStatus, validationStatus } = this.props;
    const { caseId, hasBeenDispatched, hasDispatchedStatus } = this.props;
    const { hasClosedStatus, isUpdatingCaseStatus, isSaving } = this.props;
    const { updateCaseStatus, caseAssignedToCurrentUser: isOwner } = this.props;

    if (!isOwner || hasClosedStatus || canceledStatuses.includes(status))
      return null;

    const isDisabled = [
      !caseId,
      isSaving, // wait for any mutation in-flight to complete
      hasClosedStatus,
      isLoadingCaseStatus,
      isUpdatingCaseStatus,
      this.isReadOnlyAdmin,
      !this.hasUpdateStatusPermission,
      !hasBeenDispatched && validationStatus !== complete,
    ].includes(true);

    const onClick = hasDispatchedStatus
      ? this.showModal
      : () => updateCaseStatus(getNextLogicStatus(status));

    return (
      <QuickActionButton
        onClick={onClick}
        disabled={isDisabled}
        modifiers={isDisabled && 'disabled'}
      >
        <QuickActionButton.Text>
          {hasDispatchedStatus ? (
            <Trans>Update Status</Trans>
          ) : (
            <Trans>SUBMIT</Trans>
          )}
        </QuickActionButton.Text>
      </QuickActionButton>
    );
  };

  renderCloseButton = () => {
    const { reopenCase, hasClosedStatus } = this.props;
    const { caseAssignedToCurrentUser: isOwner } = this.props;
    const { validationStatus, updateCaseStatus, status } = this.props;
    const { mismatches } = this.props;

    const isRolling = status === rolling;
    const isRollingOrCancelled = [rolling, ...canceledStatuses].includes(
      status,
    );

    if (!isOwner || (!hasClosedStatus && !isRollingOrCancelled)) return null;

    const onClick = () =>
      hasClosedStatus
        ? reopenCase()
        : updateCaseStatus(
            canceledStatuses.includes(status) ? closed_canceled : closed,
          );

    const hasMismatches = !!mismatches.length;

    const isDisabled =
      this.isReadOnlyAdmin ||
      (!hasClosedStatus && hasMismatches) ||
      (hasClosedStatus ? false : isRolling && validationStatus !== complete);

    const text = hasClosedStatus ? <Trans>Reopen</Trans> : <Trans>Close</Trans>;

    const button = (
      <QuickActionButton
        onClick={onClick}
        disabled={isDisabled}
        modifiers={[isDisabled && 'disabled']}
      >
        <QuickActionButton.Text>{text}</QuickActionButton.Text>
      </QuickActionButton>
    );

    return button;
  };

  renderCancelButton = () => {
    const { hasClosedStatus, isReadOnlyCase } = this.props;
    const { caseAssignedToCurrentUser: isOwner } = this.props;
    const { caseId, caseNumber, validationStatus } = this.props;

    if (
      !isOwner ||
      hasClosedStatus ||
      this.isReadOnlyAdmin ||
      (isReadOnlyCase && !this.hasUpdateStatusPermission)
    ) {
      return null;
    }

    return (
      <CaseCancelButton
        caseId={caseId}
        textWhite={validationStatus !== invalid}
        caseNumber={caseNumber}
      />
    );
  };

  renderRestoreButton = () => {
    const { caseId, status, isReadOnlyCase } = this.props;
    const { caseAssignedToCurrentUser: isOwner } = this.props;

    if (
      !isOwner ||
      !canceledStatuses.includes(status) ||
      this.isReadOnlyAdmin ||
      (isReadOnlyCase && !this.hasUpdateStatusPermission)
    ) {
      return null;
    }

    return <CaseRestoreButton caseId={caseId} />;
  };

  render() {
    const { status, caseNumber } = this.props;
    const { validationStatus, casePanelStatuses } = this.props;

    const indicators = Object.entries(
      casePanelStatuses,
    ).map(([panelName, panel]) => ({ ...panel, key: panelName }));

    return (
      <>
        <StatusBar
          status={status}
          caseNumber={caseNumber}
          indicators={indicators}
          closeButton={this.renderCloseButton()}
          cancelButton={this.renderCancelButton()}
          actionButton={this.renderMainActionButton()}
          restoreButton={this.renderRestoreButton()}
          validationStatus={validationStatus}
        />
        {this.state.showModal && <UpdateStatusModal onClose={this.hideModal} />}
      </>
    );
  }
}

export default compose(
  setDisplayName('CasePanelStatuses'),
  withCaseSavingStatus,
  withContext(CaseStatusContext),
  withCaseMismatches,
)(CasePanelStatuses);
