import { t } from '@lingui/macro';
import buildEnum from 'utils/buildEnum';

export const unitAttributeTypes = buildEnum(['TRACTOR', 'TRAILER']);

export const tractorAttributeNames = {
  TRACTOR: t`A Tractor`,
  'Day Cab': t`Day Cab`,
  Sleeper: t`Sleeper`,
  'Yard Dog': t`Yard Dog`,
  // for compatibility with inconsistent data coming from external services
  Daycab: t`Day Cab`,
};

export const trailerAttributeNames = {
  TRAILER: t`A Trailer`,
  Standard: t`Standard`,
  '3 Axle': t`3 Axle`,
  '4 Axle': t`4 Axle`,
  Liftgate: t`Liftgate`,
  Reefer: t`Reefer`,
  'Spread Axle': t`Spread Axle`,
  'Front Axle Spread Axle': t`Front Axle Spread Axle`,
  'Rear Axle Spread Axle': t`Rear Axle Spread Axle`,
};

export const unitAttributeNames = {
  ...tractorAttributeNames,
  ...trailerAttributeNames,
};

export default unitAttributeNames;
