import { t } from '@lingui/macro';

import { CASE_STATUS } from 'compositions/CaseStatus';
import { COLUMN_NAMES } from 'compositions/CasesDataTable/constants';

export const ITEMS_PER_PAGE = 25;

const OPEN_STATUS_GROUP_STATUSES = [
  CASE_STATUS.new,
  CASE_STATUS.dispatch,
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
  CASE_STATUS.canceled,
];

const CLOSED_STATUS_GROUP_STATUSES = [
  CASE_STATUS.closed,
  CASE_STATUS.closed_canceled,
];

const ESCALATION_LEVEL_GROUP_STATUSES = [
  CASE_STATUS.new,
  CASE_STATUS.dispatch,
  CASE_STATUS.dispatched,
  CASE_STATUS.enRoute,
  CASE_STATUS.arrived,
  CASE_STATUS.rolling,
];

export const STATUS_GROUPS_NAMES = {
  all: 'all',
  open: 'open',
  closed: 'closed',
  escalation: 'escalation',
};

export const STATUS_GROUPS = {
  [STATUS_GROUPS_NAMES.all]: [
    ...OPEN_STATUS_GROUP_STATUSES,
    ...CLOSED_STATUS_GROUP_STATUSES,
  ],
  [STATUS_GROUPS_NAMES.open]: OPEN_STATUS_GROUP_STATUSES,
  [STATUS_GROUPS_NAMES.closed]: CLOSED_STATUS_GROUP_STATUSES,
  [STATUS_GROUPS_NAMES.escalation]: ESCALATION_LEVEL_GROUP_STATUSES,
};

export const DEFAULT_STATUS_GROUP = STATUS_GROUPS_NAMES.open;

export const FILTERABLE_OPEN_STATUSES = [
  CASE_STATUS.new,
  CASE_STATUS.dispatch,
  CASE_STATUS.dispatched,
  CASE_STATUS.rolling,
  CASE_STATUS.canceled,
];

const FILTERABLE_CLOSED_STATUSES = [
  CASE_STATUS.closed,
  CASE_STATUS.closed_canceled,
];

export const FILTERABLE_STATUSES_PER_GROUP = {
  [STATUS_GROUPS_NAMES.all]: [
    ...FILTERABLE_OPEN_STATUSES,
    ...FILTERABLE_CLOSED_STATUSES,
  ],
  [STATUS_GROUPS_NAMES.open]: FILTERABLE_OPEN_STATUSES,
  [STATUS_GROUPS_NAMES.closed]: FILTERABLE_CLOSED_STATUSES,
};

export const STATUS_TITLES = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.closed_canceled]: t`Closed - Canceled`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.dry_run_canceled]: t`Canceled - Dry Run`,
};

export const SORTABLE_COLUMNS_ALL_CLOSED = [
  COLUMN_NAMES.status,
  COLUMN_NAMES.timeSummaries,
  COLUMN_NAMES.inboundProgram,
  COLUMN_NAMES.caseNumber,
  COLUMN_NAMES.customer,
  COLUMN_NAMES.unitNo,
  COLUMN_NAMES.dealer,
];

export const SORTABLE_COLUMNS_ESCALATION = [
  COLUMN_NAMES.status,
  COLUMN_NAMES.timeSummaries,
  COLUMN_NAMES.inboundProgram,
  COLUMN_NAMES.eta,
  COLUMN_NAMES.caseNumber,
  COLUMN_NAMES.customer,
  COLUMN_NAMES.unitNo,
  COLUMN_NAMES.dealer,
];

export const SORTABLE_COLUMNS_PER_GROUP = {
  [STATUS_GROUPS_NAMES.all]: SORTABLE_COLUMNS_ALL_CLOSED,
  [STATUS_GROUPS_NAMES.open]: SORTABLE_COLUMNS_ESCALATION,
  [STATUS_GROUPS_NAMES.closed]: SORTABLE_COLUMNS_ALL_CLOSED,
};

export const COLUMN_NAME_TO_API_NAME = {
  [COLUMN_NAMES.status]: 'STATUS',
  [COLUMN_NAMES.timeSummaries]: 'INSERTED_AT',
  [COLUMN_NAMES.inboundProgram]: 'INBOUND_PROGRAM_NAME',
  [COLUMN_NAMES.caseNumber]: 'CASE_NUMBER',
  [COLUMN_NAMES.customer]: 'CUSTOMER_NAME',
  [COLUMN_NAMES.unitNo]: 'PRIMARY_ASSET_UNIT_NUMBER',
  [COLUMN_NAMES.dealer]: 'DEALER_NAME',
  [COLUMN_NAMES.eta]: 'ESCALATION_LEVEL',
};

export const DEFAULT_SORT_BY = COLUMN_NAMES.timeSummaries;

export const SORT_DIRECTIONS = { asc: 'asc', desc: 'desc' };

export const DEFAULT_SORT_DIRECTIONS = {
  [COLUMN_NAMES.status]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.timeSummaries]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.inboundProgram]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.caseNumber]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.customer]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.unitNo]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.dealer]: SORT_DIRECTIONS.asc,
  [COLUMN_NAMES.eta]: SORT_DIRECTIONS.asc,
};
