import React from 'react';
import produce from 'immer';
import { defineMessage } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { filter, find, flatten, get, uniq, without, compact } from 'lodash';

import { CASE_STATUS, PANEL_STATUSES } from 'compositions/CaseStatus';
import { getVisibleStatus as baseGetVisibleStatus } from 'compositions/CaseStatus/utils';

import columnsConfigBuilder from '../CaseRequestLines/BaseRequestLinesTable/columnsConfigBuilder';
import { optionsByType } from './withRequestLineOptions/constants';
import {
  productTypeIds,
  allRequestLineKeys,
  requestsTabIndices,
  optionalRequestLineKeys,
  linesFieldValidationPrefixes,
} from './constants';

const { incomplete, partial, complete, invalid } = PANEL_STATUSES;

const spread = produce(Object.assign);

export const getRowLocation = (row, { dataPath } = {}) =>
  [['asset', 'id'], ['tirePosition'], ['productType']]
    .map((path) => get(row, [dataPath, ...path].filter(Boolean)))
    .join('-');

export const getItemById = (items, id, defaultValue) =>
  items.find((item) => item.id === id) || defaultValue;

export const addItem = produce((items, item) => {
  items.push(item);
});

export const removeItemById = produce((items, id) => {
  const index = items.findIndex((item) => item.id === id);

  if (index !== -1) items.splice(index, 1);
});

export const updateItemById = produce((items, id, newValues) => {
  spread(getItemById(items, id, {}), newValues);
});

export const insertItemAt = produce((items, newItem, atIndex) => {
  items.splice(atIndex, 0, newItem);
});

export const findByPropPathValue = (data, propPath, id, defaultValue = {}) =>
  find(data, (item) => get(item, propPath) === id) || defaultValue;

const getKeysWithGenericValue = (line, genericOptions = []) =>
  Object.entries(line).reduce((acc, [key, value]) => {
    if (!genericOptions.includes(`${key}:${value}`)) return acc;

    return [...acc, key];
  }, []);

export const requestLineHasGenericOptionsSelected = (line, state) =>
  !!getKeysWithGenericValue(line, state.genericTireOptions).length;

export const areRequestLinesEqual = (firstLine, secondLine) =>
  allRequestLineKeys.every((key) => {
    const path = key === 'asset' ? 'asset.id' : key;

    return get(firstLine, path) === get(secondLine, path);
  });

const getVisibleStatus = (props) =>
  baseGetVisibleStatus({ ...props, status: props.caseStatus });

export const getShouldShowAgreedTab = (props) =>
  getVisibleStatus(props) !== CASE_STATUS.new;

export const getShouldShowSuppliedTab = (props) =>
  [CASE_STATUS.rolling, CASE_STATUS.closed].includes(getVisibleStatus(props));

export const getActiveRequestsTab = (props) => {
  if (props.caseStatus === CASE_STATUS.new) {
    return requestsTabIndices.requested;
  }

  return getShouldShowSuppliedTab(props)
    ? requestsTabIndices.supplied
    : requestsTabIndices.agreed;
};

const getCompletenessStatusOfKeys = (keys, ofObject) => {
  const keysStatus = keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: get(ofObject, key) ? 'complete' : 'missing',
    }),
    {},
  );

  let status = incomplete;
  const booleans = Object.values(keysStatus);

  if (booleans.includes('complete')) {
    status = booleans.includes('missing')
      ? PANEL_STATUSES.partial
      : PANEL_STATUSES.complete;
  }

  return { status, keysStatus };
};

const getAssetsStatus = (props, state) => {
  const { assets = [] } = props;
  const { unitNumberInvalidStatus, assetTypeInvalidStatus } = state;
  const fieldKeys = ['assetType', 'unitNumber'];

  return Array.from({ length: 3 }).reduce((acc, _, index) => {
    const key = !index ? 'asset' : `relatedAssets.${index - 1}`;
    const entry = getCompletenessStatusOfKeys(fieldKeys, assets[index]);
    const isAssetTypeInvalid = get(assetTypeInvalidStatus, key) === true;
    const isUnitNumberInvalid = get(unitNumberInvalidStatus, key) === true;

    if (isAssetTypeInvalid) entry.keysStatus.assetType = 'invalid';
    if (isUnitNumberInvalid) entry.keysStatus.unitNumber = 'invalid';
    if (isAssetTypeInvalid || isUnitNumberInvalid) entry.status = invalid;

    return [...acc, entry];
  }, []);
};

const getIgnoredKeysForLine = (line = {}) => [
  ...optionalRequestLineKeys,
  productTypeIds[line.productType] === 'Retread' ? 'loadRange' : '',
];

const allDepsHaveValues = (key, rowData, { props }) => {
  const columns = columnsConfigBuilder(props);
  const column = columns.find((c) => c.name === key) || {};
  const config = column.getRuntimeConfig
    ? column.getRuntimeConfig({ ...props, rowData })
    : {};

  return (config.dependsOn || []).every((name) => !!rowData[name]);
};

export const hasCheckedLineForSameLocation = (line, lines, options = {}) => {
  const { isCheckedPath, dataPath } = options;
  const location = getRowLocation(line, options);

  const getId = (row) => get(row, [dataPath, 'id'].filter(Boolean));

  return lines
    .filter((row) => getId(row) !== getId(line) && !!get(row, isCheckedPath))
    .some((row) => getRowLocation(row, options) === location);
};

const getRequestLinesStatus = (lines = [], options = {}) => {
  const { dataPath, isCheckedPath, state } = options;
  const { genericTireOptions: genericOptions } = state;

  return lines.map((line) => {
    const data = {
      ...(dataPath ? get(line, dataPath) : line),
      [isCheckedPath]: get(line, isCheckedPath),
    };

    const lineHasCheckedLineForSameLocation = hasCheckedLineForSameLocation(
      line,
      lines,
      options,
    );

    const allKeys = without(allRequestLineKeys, ...getIgnoredKeysForLine(data));

    // Don't check fields that have unmet dependencies
    const keysToCheck = allKeys.filter((key) =>
      allDepsHaveValues(key, data, options),
    );

    // Only test the "checkbox" field if every other field has a value
    // and there isn't another line for the same location that is checked.
    if (
      !!isCheckedPath &&
      keysToCheck.length === allKeys.length &&
      !!data[keysToCheck[keysToCheck.length - 1]] &&
      !lineHasCheckedLineForSameLocation
    ) {
      keysToCheck.push(isCheckedPath);
    }

    const result = getCompletenessStatusOfKeys(keysToCheck, data);

    if (!lineHasCheckedLineForSameLocation) {
      const invalidKeys = getKeysWithGenericValue(data, genericOptions);

      if (invalidKeys.length) {
        // eslint-disable-next-line no-return-assign
        invalidKeys.forEach((key) => (result.keysStatus[key] = 'invalid'));
        result.status = invalid;
      }
    }

    result.id = data.id;

    return result;
  });
};

const buildFieldPrefix = (opts) => (
  <span>
    {i18n._(opts.titlePrefix, opts.values)}
    &nbsp;
  </span>
);

const buildFieldStatus = (options) => {
  const {
    id,
    type,
    name,
    index,
    idPrefix,
    severity,
    titlePrefix,
    ...other
  } = options;

  const key = compact([idPrefix, type, `${id || index}`, name]).join('.');
  const values = { id, index: index + 1 };
  const prefix = buildFieldPrefix({ ...other, values, titlePrefix });
  const translationKey = `${idPrefix}.${name}`;

  return { [key]: { ...other, prefix, severity, translationKey } };
};

const buildAssetFieldsStatus = (assetsStatus) =>
  assetsStatus.reduce((acc, { status, keysStatus }, index) => {
    if (status === incomplete) return acc;

    return {
      ...acc,
      ...Object.entries(keysStatus).reduce((acc2, [name, severity]) => {
        if (severity === 'complete') return acc2;

        const idPrefix = 'request.assets';
        const titlePrefix = defineMessage({ message: 'Asset {index}:' });
        const config = { index, severity, name, idPrefix, titlePrefix };

        return { ...acc2, ...buildFieldStatus(config) };
      }, {}),
    };
  }, {});

const buildLineFieldsStatus = (linesStatus) =>
  Object.entries(linesStatus).reduce(
    (acc, [type, typeLinesStatus]) => ({
      ...acc,
      ...typeLinesStatus.reduce((acc2, { id, status, keysStatus }, index) => {
        // If the whole line is empty, we add a single entry to the popover
        const entries = status !== incomplete ? keysStatus : { all: 'missing' };
        const common = { id, type, index };

        return {
          ...acc2,
          ...Object.entries(entries).reduce((acc3, [name, severity]) => {
            if (severity === 'complete') return acc3;

            const config = { ...common, name, severity };

            config.idPrefix = 'request.lines';
            config.titlePrefix = linesFieldValidationPrefixes[type];

            return { ...acc3, ...buildFieldStatus(config) };
          }, {}),
        };
      }, {}),
    }),
    {},
  );

export const getNewStatusPanelStatus = (props, state) => {
  const { requestSuppliedLines: suppliedLines } = props;
  const { requestAgreementLines: agreementLines } = props;
  const { isLoadingAssets, isLoadingRequestLines, requestLines } = props;

  if (isLoadingAssets || isLoadingRequestLines) return [];

  const assetsStatus = getAssetsStatus(props, state);
  const hasPartialAssets = assetsStatus.some((s) => s.status === partial);
  const hasInvalidAssets = assetsStatus.some((s) => s.status === invalid);
  const hasNonEmptyAssets = assetsStatus.some((e) => e.status !== incomplete);

  const lines = {
    requested: requestLines,
    agreed: getShouldShowAgreedTab(props) ? agreementLines : [],
    supplied: getShouldShowSuppliedTab(props) ? suppliedLines : [],
  };

  const linesStatus = {
    requested: getRequestLinesStatus(lines.requested, {
      props,
      // We don't enforce non-generic selections for request lines
      state: { ...state, genericTireOptions: [] },
    }),

    agreed: getRequestLinesStatus(lines.agreed, {
      props,
      state,
      dataPath: 'agreementLine',
      isCheckedPath: 'agreed',
    }),

    supplied: getRequestLinesStatus(lines.supplied, {
      props,
      state,
      dataPath: 'suppliedLine',
      isCheckedPath: 'supplied',
    }),
  };

  const allLineStatus = flatten(Object.values(linesStatus)).map(
    (s) => s.status,
  );
  const hasPartialLines = allLineStatus.includes(partial);
  const hasInvalidLines = allLineStatus.includes(invalid);
  const hasNonEmptyLines = allLineStatus.some((s) => s !== incomplete);

  let fields = {};
  let newPanelStatus = incomplete;

  if (
    hasPartialLines ||
    hasPartialAssets ||
    compact([hasNonEmptyAssets, hasNonEmptyLines]).length === 1 ||
    (allLineStatus.includes(complete) && allLineStatus.includes(incomplete))
  ) {
    newPanelStatus = partial;
  }

  if (hasInvalidAssets || hasInvalidLines) newPanelStatus = invalid;

  if (
    hasNonEmptyLines &&
    hasNonEmptyAssets &&
    assetsStatus.every((e) => [incomplete, complete].includes(e.status)) &&
    (allLineStatus.every((status) => status === complete) ||
      (allLineStatus.length === 1 && allLineStatus[0] === incomplete))
  ) {
    newPanelStatus = complete;
  }

  if ([partial, invalid].includes(newPanelStatus)) {
    fields = {
      ...buildAssetFieldsStatus(assetsStatus),
      ...buildLineFieldsStatus(linesStatus),
    };
  }

  return [newPanelStatus, fields];
};

export const buildTireOptions = (type, options) =>
  options.map((option) => ({
    // this is used directly in request lines
    id: option.name,
    // this is then mapped to a translation
    title: get(optionsByType, [type, option.id]) || option.name,
    isGeneric: !!option.generic,
    upstreamId: option.id, // this is used to query dependent tire options
  }));

export const addTireOptions = produce((draft, type, options) => {
  if (!draft[type]) {
    draft[type] = options; // eslint-disable-line no-param-reassign
  } else {
    options.forEach((o) => {
      if (!draft[type].find(({ id }) => id === o.id)) draft[type].push(o);
    });
  }
});

export const getAllGenericTireOptions = (tireOptionsByType) =>
  uniq(
    flatten(
      Object.values(tireOptionsByType).map((lineOptions) =>
        Object.entries(lineOptions).reduce((acc, [type, options]) => {
          const genericOptions = filter(options, 'isGeneric');

          return [...acc, ...genericOptions.map((o) => `${type}:${o.title}`)];
        }, []),
      ),
    ),
  );

export const parseRequestLinesPatch = (values) => {
  // mutation expects empty values to be null
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (value === '') return { ...acc, [key]: null };
    return { ...acc, [key]: value };
  }, {});
};
