import { Trans, t, defineMessage } from '@lingui/macro';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';
import VisuallyHidden from 'elements/VisuallyHidden';
import { withReadOnlyCase } from 'compositions/CaseStatus';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import withSetPaymentDetails from '../withSetPaymentDetails';
import { PAYMENT_METHODS, fieldIds, PANEL_TITLE } from '../constants';

const OPTIONS = {
  STORE_ACCOUNT: {
    key: 'store-account',
    value: PAYMENT_METHODS.STORE_ACCOUNT,
    label: defineMessage({
      message: 'Store Account {accountNumber}',
    }),
  },
  NONE: {
    key: 'store-none',
    value: PAYMENT_METHODS.NONE,
    label: t`Continue without payment method`,
  },
};

function getPaymentLabel(paymentMethod) {
  return get(OPTIONS[paymentMethod], 'label', '');
}

class Store extends Component {
  static propTypes = {
    customer: PropTypes.shape({
      accountNumber: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    setPaymentDetails: PropTypes.func.isRequired,
    onFocusRequested: PropTypes.func.isRequired,
  };

  state = {
    selectedOption: get(OPTIONS[this.props.paymentMethod], 'key', ''),
  };

  handleChange = (selectedOption) => {
    this.props.setPaymentDetails({ paymentMethod: selectedOption.value });
    this.setState({ selectedOption: selectedOption.key });
  };

  render() {
    const {
      customer: { accountNumber },
      isReadOnlyCase,
      paymentMethod,
    } = this.props;
    const { selectedOption } = this.state;

    const selectedOptionLabel = getPaymentLabel(paymentMethod);

    return (
      <Row ref={this.props.onFocusRequested}>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.STORE_ACCOUNT.key}
                checked={selectedOption === OPTIONS.STORE_ACCOUNT.key}
                label={
                  <Trans
                    id={OPTIONS.STORE_ACCOUNT.label}
                    values={{
                      accountNumber: accountNumber ? `#${accountNumber}` : '',
                    }}
                  />
                }
                onChange={() => this.handleChange(OPTIONS.STORE_ACCOUNT)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.NONE.key}
                checked={selectedOption === OPTIONS.NONE.key}
                label={<Trans id={OPTIONS.NONE.label} />}
                onChange={() => this.handleChange(OPTIONS.NONE)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
        </Column>
        {selectedOptionLabel && (
          <VisuallyHidden>
            <Trans id={PANEL_TITLE + ':'} />
            <Trans
              id={selectedOptionLabel}
              values={{
                accountNumber: accountNumber ? `#${accountNumber}` : '',
              }}
            />
          </VisuallyHidden>
        )}
      </Row>
    );
  }
}

export default compose(
  setDisplayName('Store'),
  withSetPaymentDetails,
  withReadOnlyCase,
  withFocusReceiver(fieldIds.store.type),
)(Store);
