import { parseLatLng, latLngToString } from 'utils/geoLocation';
import getAddressComponents from 'utils/getAddressComponents';

export function getLocationSearchUpdates(response, searchValueTried) {
  const { address, addressComponents, location, locationString } = response;

  const hasStreetNumber = !!addressComponents?.streetNumber;
  const useCoordinates = !hasStreetNumber;
  const travelEstimateDestination = useCoordinates ? locationString : address;
  const searchValue = parseLatLng(searchValueTried)?.latitude
    ? locationString
    : address;

  return {
    searchValue,
    travelEstimateDestination,
    useCoordinates,
    searchValueTried: latLngToString(searchValueTried),
    location,
    address,
    addressComponents,
    countryCode: addressComponents?.countryCode,
  };
}

export function parseGeocodeResult(result, originalLatLng) {
  if (!result) return {};

  const rawlocation = result?.geometry?.location;
  const location = originalLatLng || {
    latitude: rawlocation?.lat(),
    longitude: rawlocation?.lng(),
  };

  const { formattedAddress, ...addressComponents } = getAddressComponents(
    result,
  );

  return {
    location,
    addressComponents,
    address: formattedAddress || result?.formatted_address, // eslint-disable-line camelcase
    locationString: latLngToString(location),
  };
}

async function handleResponse(response, originalLatLng) {
  if (!response.result && !response.results) return {};

  // when we're reverse geocoding a lat/lng from user input,
  // instead of getting the first result, we actually want
  // the location of the geometric center of given coordinates
  const results = response?.results?.filter(
    (result) => result.geometry.location_type === 'GEOMETRIC_CENTER',
  );

  // if there are more than one geometric center, priorize the one with a street number or country code
  let result =
    results?.find(hasStreetNumber) ||
    results?.find(hasCountryCode) ||
    results?.[0];

  // if somehow none of the results are of type geometric center
  // then fallback to using the first result
  if (!result) {
    result = response.result;
  }

  return parseGeocodeResult(result, originalLatLng);
}

export async function geocodeSearchValue({
  searchValue,
  latLng,
  geocode,
  reverseGeocode,
}) {
  if (!searchValue && !latLng) return {};

  latLng = latLng || parseLatLng(searchValue);

  // Use the latitude and longitude from user input if available, ex: 32.9132145,-80.1598145
  // if the user input is not simple coordinates, geocode the input value
  const response = latLng
    ? await reverseGeocode({ lat: latLng.latitude, lng: latLng.longitude })
    : await geocode(searchValue);

  return handleResponse(response, latLng);
}

function hasStreetNumber(result) {
  const { addressComponents } = parseGeocodeResult(result);
  return !!addressComponents?.streetNumber;
}

function hasCountryCode(result) {
  const { addressComponents } = parseGeocodeResult(result);
  return !!addressComponents?.countryCode;
}
