import gql from 'graphql-tag';

export default gql`
  query searchStoreCustomersQuery($searchString: String, $billTo: String) {
    searchStoreCustomers(
      filters: { searchString: $searchString, billTo: $billTo }
    ) {
      customers {
        accountNumber
        city
        id
        name
        state
        zip
      }
    }
  }
`;
