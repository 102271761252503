import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

import Dropdown from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

function Target({ children, ...rest }, context) {
  const { fullWidth, handleToggle, handleHover, handleKeyDown } = context[
    DROPDOWN_CONTEXT
  ];
  const modifiers = compact([fullWidth && 'fullWidth']);

  function onKeyDown(e) {
    rest?.onKeyDown?.(e);
    handleKeyDown(e);
  }

  return (
    <Dropdown.Target
      modifiers={modifiers}
      onClick={handleToggle}
      onMouseEnter={handleHover}
      {...rest}
      onKeyDown={onKeyDown}
    >
      {children}
    </Dropdown.Target>
  );
}

Target.contextTypes = {
  [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
};

Target.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Target;
