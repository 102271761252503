export function caseCallLinkHelper(data) {
  const {
    contact,
    contactNumber,
    stateStartTime,
    currentCaseId,
    linkCaseToCall: callLinkingMutation,
    destinationName,
    destinationNumber,
    destinationOrganization,
    sourceName,
    sourceOrganization,
  } = data;
  if (!contact) {
    // eslint-disable-next-line no-console
    console.trace('caseCallLinkHelper received null contact - data=', data);
    return null;
  }
  const { dialedNumber: dialedNumberAttr } = contact.getAttributes();
  const agentNumber = dialedNumberAttr && dialedNumberAttr.value;

  const inboundCall = contact?.isInbound?.();
  const direction = inboundCall ? 'INBOUND' : 'OUTBOUND';

  const caseId = currentCaseId;
  const { contactId } = contact;
  const sourceNumber = inboundCall ? contactNumber : null;
  const startTime = stateStartTime ? stateStartTime.toISOString() : null;

  return callLinkingMutation({
    amazonConnectContactId: contactId,
    caseId,
    destinationName,
    destinationNumber:
      destinationNumber || (inboundCall ? agentNumber : contactNumber),
    destinationOrganization,
    direction,
    sourceName,
    sourceNumber,
    sourceOrganization,
    startTime,
  });
}

export function caseCallUnlinkHelper({ contact, unlinkCaseFromCall }) {
  return unlinkCaseFromCall({ amazonConnectContactId: contact.contactId });
}
