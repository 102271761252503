import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { Formik } from 'formik';
import { setDisplayName, compose } from 'recompose';
import { get, omit, isEqual, map } from 'lodash';

import { Text, H4 } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import Panel from 'blocks/Panel';
import withContext from 'utils/withContext';
import patchFromChangedProps from 'utils/patchFromChangedProps';

import FormComponent from './FormComponent';
import withRequestAssets from './withRequestAssets';
import CaseRequestsPanelContext from '../CaseRequestsPanelContext';
import { defaultAsset, assetPropType } from './constants';

const DEBOUNCE_MS = 500;
const { delayedServiceModalModes } = CaseRequestsPanelContext;

const hasAnyDroppedUnit = (assets) => map(assets, 'droppedUnit').some(Boolean);

export class RequestAssetsForm extends PureComponent {
  static propTypes = {
    caseId: PropTypes.string,
    createPrimaryAsset: PropTypes.func.isRequired,
    createRelatedAsset: PropTypes.func.isRequired,
    isSavingAssets: PropTypes.bool.isRequired,
    primaryAsset: assetPropType.isRequired,
    relatedAssets: PropTypes.arrayOf(assetPropType).isRequired,
    updateAsset: PropTypes.func.isRequired,
    isDelayedService: PropTypes.bool.isRequired,
    showDelayedServiceModal: PropTypes.func.isRequired,
  };

  static defaultProps = { caseId: undefined };

  state = {
    primaryAsset: this.props.primaryAsset,
    relatedAssets: this.props.relatedAssets,
  };

  operations = new Map();

  currentOperationKey = undefined;

  UNSAFE_componentWillReceiveProps(nextProps) {
    const patch = patchFromChangedProps(nextProps, this.state, [
      'primaryAsset',
      'relatedAssets',
    ]);

    const didSave = this.props.isSavingAssets && !nextProps.isSavingAssets;

    if (
      nextProps.caseId !== this.props.caseId ||
      (didSave && !this.currentOperationKey) ||
      !this.currentOperationKey
    ) {
      this.setState(patch);
    }

    // Doing this here allows us to have the latest data below and prevent,
    // for example, running two consecutive "createPrimaryAsset" operations,
    // but instead run a "createPrimaryAsset" and then an "updateAsset".
    if (didSave) {
      this.nextOperationTimeout = setTimeout(() => {
        this.currentOperationKey = undefined;
        this.runNextOperation();
      }, DEBOUNCE_MS);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextOperationTimeout);
  }

  queueSavePrimaryAsset = (params) => {
    this.operations.set('savePrimaryAsset', params);
    this.runNextOperation();
  };

  queueSaveRelatedAsset = (params) => {
    this.operations.set(`saveRelatedAsset${params.index}`, params);
    this.runNextOperation();
  };

  runNextOperation = () => {
    const { updateAsset, createPrimaryAsset, createRelatedAsset } = this.props;

    if (this.currentOperationKey || !this.operations.size) {
      return;
    }

    const nextOperationKey = [...this.operations.keys()].shift();
    const operationParams = { ...this.operations.get(nextOperationKey) };
    const asset = {
      ...defaultAsset,
      ...omit(operationParams.assetData, ['id', 'canDelete']),
    };
    this.currentOperationKey = nextOperationKey;
    this.operations.delete(this.currentOperationKey);

    if (this.currentOperationKey === 'savePrimaryAsset') {
      const primaryAssetId = get(this.props, 'primaryAsset.id');

      if (primaryAssetId) {
        updateAsset({ id: primaryAssetId, asset });
      } else {
        createPrimaryAsset({ caseId: operationParams.caseId, asset });
      }
    }

    if (this.currentOperationKey.startsWith('saveRelatedAsset')) {
      const { index } = operationParams;
      const relatedAssetId = get(this.props, `relatedAssets.${index}.id`);

      if (relatedAssetId) {
        updateAsset({ id: relatedAssetId, asset });
      } else {
        createRelatedAsset({ caseId: operationParams.caseId, asset });
      }
    }
  };

  // Since we only get the full values from Formik, we compare them with
  // the ones we keep in state to determine which asset is being edited.
  handleChanges = (values) => {
    const { caseId, isDelayedService, showDelayedServiceModal } = this.props;
    const {
      primaryAsset: currentPrimaryAsset,
      relatedAssets: currentRelatedAssets,
    } = this.state;

    const { asset: newPrimaryAsset, relatedAssets: newRelatedAssets } = values;
    const prevAssets = [currentPrimaryAsset, ...currentRelatedAssets];
    const newAssets = [newPrimaryAsset, ...newRelatedAssets];
    const hadDroppedUnits = hasAnyDroppedUnit(prevAssets);
    const hasDroppedUnits = hasAnyDroppedUnit(newAssets);

    // Trigger the "Confirm Delayed Service" modal if no
    // asset was marked as dropped but now at least one is.
    if (!isDelayedService && !hadDroppedUnits && hasDroppedUnits) {
      showDelayedServiceModal(delayedServiceModalModes.confirmDelayedService);
    }

    // Trigger the "Confirm ERS" modal if at least
    // one asset was marked as dropped, but now none is.
    if (isDelayedService && hadDroppedUnits && !hasDroppedUnits) {
      showDelayedServiceModal(delayedServiceModalModes.confirmERS);
    }

    // Store the received values so that we can compare with those
    // instead of the ones in Apollo's cache, which might not be
    // up-to-date with what the user is seeing on screen.
    this.setState({
      primaryAsset: newPrimaryAsset,
      relatedAssets: newRelatedAssets,
    });

    // The primary asset is being edited.
    if (!isEqual(newPrimaryAsset, currentPrimaryAsset)) {
      this.queueSavePrimaryAsset({ caseId, assetData: newPrimaryAsset });

      return;
    }

    const index = newRelatedAssets.findIndex(
      (assetToIndex, i) => !isEqual(assetToIndex, currentRelatedAssets[i]),
    );

    // A related asset is being edited.
    if (index >= 0) {
      const assetData = newRelatedAssets[index];

      // If the user is changing the 2nd related asset and the first one is still
      // a placeholder, we save the first one first so that the user will see
      // the same UI after refreshing.
      if (index === 1 && !assetData.id && !newRelatedAssets[0].id) {
        this.queueSaveRelatedAsset({
          index: 0,
          caseId,
          assetData: newRelatedAssets[0],
        });
      }

      this.queueSaveRelatedAsset({ index, caseId, assetData });
    }
  };

  render() {
    const { primaryAsset: asset, relatedAssets } = this.state;

    return (
      <Row>
        <Column modifiers={['col', 'padScaleX_0', 'padScaleY_2']}>
          <Panel modifiers="padScale_0">
            <Row modifiers="middle">
              <Column modifiers="col_4" style={{ paddingLeft: 0 }}>
                <Row modifiers="middle">
                  <Column modifiers="padScale_0">
                    <H4 modifiers="fontWeightRegular">
                      <Trans>Asset 1</Trans>
                      &nbsp;
                      <Text modifiers={['small', 'textLight']}>
                        <Trans>(Required)</Trans>
                      </Text>
                    </H4>
                  </Column>
                </Row>
              </Column>
              {relatedAssets.map((_, index) => (
                <Column key={index} modifiers="col">
                  <Row modifiers="middle">
                    <Column modifiers="padScale_0">
                      <H4 modifiers="fontWeightRegular">
                        <Trans
                          id="Asset {index}"
                          values={{ index: index + 2 }}
                        />
                      </H4>
                    </Column>
                  </Row>
                </Column>
              ))}
            </Row>

            <Formik
              validate={this.handleChanges}
              initialValues={{ asset, relatedAssets }}
              validateOnBlur={false}
              enableReinitialize
            >
              {(props) => (
                <FormComponent {...props} disabled={!asset || !relatedAssets} />
              )}
            </Formik>
          </Panel>
        </Column>
      </Row>
    );
  }
}

export default compose(
  setDisplayName('RequestAssetsForm'),
  withRequestAssets,
  withContext(CaseRequestsPanelContext),
)(RequestAssetsForm);
