import { curry } from 'lodash';
import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_NOTES_QUERY } from '../withCaseNotes/caseNotesQuery';
import { NAME as CASE_UNIFIED_HISTORY_QUERY_NAME } from 'compositions/CaseActivityPanel/UnifiedHistory/withCaseUnifiedHistory/caseUnifiedHistoryQuery';

import createCaseNoteGQL from './createCaseNoteMutation';

const buildCreateMutationChild = curry(
  (WrappedComponent, componentProps, createCaseNote) => (
    <WrappedComponent {...componentProps} createCaseNote={createCaseNote} />
  ),
);

function withCreateMutation(WrappedComponent, componentProps) {
  return (
    <Mutation
      mutation={createCaseNoteGQL}
      refetchQueries={[CASE_NOTES_QUERY, CASE_UNIFIED_HISTORY_QUERY_NAME]}
    >
      {buildCreateMutationChild(WrappedComponent, componentProps)}
    </Mutation>
  );
}

const withCreateCaseNote = (WrappedComponent) => (componentProps) =>
  withCreateMutation(WrappedComponent, componentProps);

export default withCreateCaseNote;
