import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H3, InputGroup } from 'base-components';

import Panel from 'blocks/Panel';

import CaseInboundProgramPanelContext from './CaseInboundProgramPanelContext';
import InboundProgramLocationSelector from './InboundProgramLocationSelector';
import InboundProgramSelector from './InboundProgramSelector';
import { PANEL_TITLE } from './constants';

function CaseInboundProgramPanel({ caseNumber }) {
  return (
    <CaseInboundProgramPanelContext caseNumber={caseNumber}>
      <Panel modifiers="padScaleX_3" data-testid="CaseInboundProgramPanel">
        <Row>
          <Column modifiers="padScaleY_2">
            <H3 modifiers="fontWeightRegular">
              <Trans id={PANEL_TITLE} />
            </H3>
          </Column>
        </Row>
        <InputGroup
          style={{ marginBottom: px2rem(9) }}
          modifiers={['padScale_1']}
        >
          <InboundProgramSelector />
          <InboundProgramLocationSelector />
        </InputGroup>
      </Panel>
    </CaseInboundProgramPanelContext>
  );
}

CaseInboundProgramPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
};

export default CaseInboundProgramPanel;
