import { kebabCase } from 'lodash';
import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';

import { USER_ROLES } from 'features/rbac';

export const reportTypes = buildEnum(
  [
    'caseBillingReport',
    'casesPerHour',
    'dailyAgentMetrics',
    'dailyCasesMetrics',
    'dailyDealerReport',
    'dailyDealerReportByEmail',
    'emailDistributionReport',
    'dealerSelectionAuditReport',
    'assetValidationReport',
    'newStoreAccountsReport',
  ],
  // Because we use these values in the URL,
  // we want them prettier than the default.
  kebabCase,
);

export const reportTitles = {
  [reportTypes.caseBillingReport]: t`Case Billing Report`,
  [reportTypes.casesPerHour]: t`Cases Per Hour`,
  [reportTypes.dailyAgentMetrics]: t`Daily Agent Metrics`,
  [reportTypes.dailyCasesMetrics]: t`Daily Cases Metrics`,
  [reportTypes.dailyDealerReport]: t`Daily Service Provider Report`,
  [reportTypes.dailyDealerReportByEmail]: t`Daily Service Provider Report`,
  [reportTypes.emailDistributionReport]: t`Email Notification Distribution Report`,
  [reportTypes.dealerSelectionAuditReport]: t`Service Provider Selection Audit Report`,
  [reportTypes.assetValidationReport]: t`Asset Validation Report`,
  [reportTypes.newStoreAccountsReport]: t`New Store Account Creation Report`,
};

export const dealerRoles = [USER_ROLES.DEALER_USER, USER_ROLES.DEALER_ADMIN];
export const dealersAllowedReportTypes = [reportTypes.dailyDealerReportByEmail];

export const nonDealersAllowedReportTypes = [
  reportTypes.caseBillingReport,
  reportTypes.casesPerHour,
  reportTypes.dailyAgentMetrics,
  reportTypes.dailyCasesMetrics,
  reportTypes.dailyDealerReport,
  reportTypes.emailDistributionReport,
  reportTypes.dealerSelectionAuditReport,
  reportTypes.assetValidationReport,
  reportTypes.newStoreAccountsReport,
];

export const michelinOpsAllowedReportTypes = [
  reportTypes.dailyCasesMetrics,
  reportTypes.dailyDealerReport,
  reportTypes.emailDistributionReport,
  reportTypes.dealerSelectionAuditReport,
  reportTypes.assetValidationReport,
  reportTypes.newStoreAccountsReport,
];
