import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { unitAttributeTypes } from './unitAttributeNames';

const unitAttributesQuery = gql`
  query unitAttributesQuery($assetType: AssetType!) {
    unitAttributes(assetType: $assetType)
  }
`;

const useUnitAttributes = (assetType) => {
  const { data } = useQuery(unitAttributesQuery, {
    variables: { assetType },
  });

  return data?.unitAttributes || [];
};

const withUnitAttributes = (WrappedComponent) => (componentProps) => {
  const trailerAttributes = useUnitAttributes(unitAttributeTypes.TRAILER);
  const tractorAttributes = useUnitAttributes(unitAttributeTypes.TRACTOR);

  const unitAttributes = useMemo(
    () => ({
      [unitAttributeTypes.TRAILER]: trailerAttributes,
      [unitAttributeTypes.TRACTOR]: tractorAttributes,
    }),
    [trailerAttributes, tractorAttributes],
  );

  return (
    <WrappedComponent {...componentProps} unitAttributes={unitAttributes} />
  );
};

export default withUnitAttributes;
