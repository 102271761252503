import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/client/react/components';
import { curry, get } from 'lodash';

import CustomerWidgetContext from '../../CustomerWidgetContext';

export const CASE_PREFERENCES_UPDATED_AT_NAME = 'preferencesUpdatedAtQuery';

const customerQuery = gql`
  query preferencesUpdatedAtQuery($caseNumber: String!) {
    case(caseNumber: $caseNumber) {
      id
      customer(useCachedDataForCase: true) {
        ... on Customer {
          id
          preferencesUpdatedAt
        }

        ... on StoreCustomer {
          id
          preferencesUpdatedAt
        }
      }
    }
  }
`;

const buildQueryChild = curry(
  (WrappedComponent, componentProps, queryProps) => {
    const updatedAt = get(
      queryProps,
      'data.case.customer.preferencesUpdatedAt',
      null,
    );

    return <WrappedComponent {...componentProps} updatedAt={updatedAt} />;
  },
);

function buildWrappedComponentWithQuery(WrappedComponent, componentProps) {
  const { caseNumber, customerId } = componentProps;

  return (
    <Query
      query={customerQuery}
      skip={!caseNumber || !customerId}
      variables={{ caseNumber, customerId }}
      fetchPolicy="cache-and-network"
    >
      {buildQueryChild(WrappedComponent, componentProps)}
    </Query>
  );
}

export const buildConsumerChild = curry(
  (WrappedComponent, componentProps, consumerProps) => {
    const { caseNumber } = consumerProps;

    return buildWrappedComponentWithQuery(WrappedComponent, {
      ...componentProps,
      caseNumber,
      customerId: get(componentProps, 'customer.id'),
    });
  },
);

const withPreferencesUpdatedAt = (WrappedComponent) => (componentProps) => (
  <CustomerWidgetContext.Consumer>
    {buildConsumerChild(WrappedComponent, componentProps)}
  </CustomerWidgetContext.Consumer>
);

export default withPreferencesUpdatedAt;
