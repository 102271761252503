import { t } from '@lingui/macro';
import buildEnum from 'utils/buildEnum';

export const PANEL_TITLE = t`Payment`;

export const CREDIT_CARD_RULE_TYPES = {
  M: 'M', // Credit card Mandatory
  O: 'O', // Credit card Optional
  N: 'N', // Credit card not accepted
  Z: 'Z', // Credit card not accepted
};

/**
 * Valid values for the `PaymentMethod` type in the GraphQL API.
 */
export const PAYMENT_METHODS = {
  CARD_ON_FILE: 'CARD_ON_FILE',
  CASH: 'CASH',
  COM_CHECK: 'COM_CHECK',
  CREDIT_CARD_NO_PREAUTH: 'CREDIT_CARD_NO_PREAUTH',
  MICHELIN_LINE_OF_CREDIT: 'MICHELIN_LINE_OF_CREDIT',
  NONE: 'NONE',
  OTHER_NATIONAL_ACCOUNT: 'OTHER_NATIONAL_ACCOUNT',
  ONE_TIME_CARD: 'ONE_TIME_CARD',
  STORE_ACCOUNT: 'STORE_ACCOUNT',
  THIRD_PARTY_CREDIT_CARD_TXN: 'THIRD_PARTY_CREDIT_CARD_TXN',
};

const keyFor = (type) => (key) => `payment.${type}.${key}`;

export const fieldIds = {
  national: buildEnum(['newCC', 'existingCC'], keyFor('national')),
  store: buildEnum(['type'], keyFor('store')),
  other: buildEnum(['receipt'], keyFor('other')),
};
