import { Trans, t } from '@lingui/macro';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, setDisplayName } from 'recompose';

import { Row, Column } from 'styled-components-grid';

import RadioButton from 'components/RadioButton';
import VisuallyHidden from 'elements/VisuallyHidden';

import { withReadOnlyCase } from 'compositions/CaseStatus';

import withSetPaymentDetails from '../withSetPaymentDetails';
import { PAYMENT_METHODS, PANEL_TITLE } from '../constants';

const OPTIONS = {
  CASH: {
    key: 'other-cash',
    value: PAYMENT_METHODS.CASH,
    label: t`Cash`,
  },
  COM_CHECK: {
    key: 'other-com-check',
    value: PAYMENT_METHODS.COM_CHECK,
    label: t`COMcheck`,
  },
  OTHER_NATIONAL_ACCOUNT: {
    key: 'other-national-account',
    value: PAYMENT_METHODS.OTHER_NATIONAL_ACCOUNT,
    label: t`Other National Account`,
  },
  NONE: {
    key: 'other-none',
    value: PAYMENT_METHODS.NONE,
    label: t`Continue without payment method`,
  },
  CREDIT_CARD_NO_PREAUTH: {
    key: 'other-pre-auth',
    value: PAYMENT_METHODS.CREDIT_CARD_NO_PREAUTH,
    label: t`Credit Card - No Preauth`,
  },
};

function getPaymentLabel(paymentMethod) {
  return get(OPTIONS[paymentMethod], 'label', '');
}

class Other extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    paymentMethod: PropTypes.string.isRequired,
    setPaymentDetails: PropTypes.func.isRequired,
  };

  state = {
    selectedOption: get(OPTIONS[this.props.paymentMethod], 'key', ''),
  };

  handleChange = (selectedOption) => {
    this.props.setPaymentDetails({ paymentMethod: selectedOption.value });
    this.setState({ selectedOption: selectedOption.key });
  };

  render() {
    const { selectedOption } = this.state;
    const { isReadOnlyCase, paymentMethod } = this.props;
    const selectedOptionLabel = getPaymentLabel(paymentMethod);

    return (
      <Row>
        <Column modifiers={['col']}>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.CREDIT_CARD_NO_PREAUTH.key}
                checked={selectedOption === OPTIONS.CREDIT_CARD_NO_PREAUTH.key}
                label={<Trans id={OPTIONS.CREDIT_CARD_NO_PREAUTH.label} />}
                onChange={() =>
                  this.handleChange(OPTIONS.CREDIT_CARD_NO_PREAUTH)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.CASH.key}
                checked={selectedOption === OPTIONS.CASH.key}
                label={<Trans id={OPTIONS.CASH.label} />}
                onChange={() => this.handleChange(OPTIONS.CASH)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.COM_CHECK.key}
                checked={selectedOption === OPTIONS.COM_CHECK.key}
                label={<Trans id={OPTIONS.COM_CHECK.label} />}
                onChange={() => this.handleChange(OPTIONS.COM_CHECK)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.OTHER_NATIONAL_ACCOUNT.key}
                checked={selectedOption === OPTIONS.OTHER_NATIONAL_ACCOUNT.key}
                label={<Trans id={OPTIONS.OTHER_NATIONAL_ACCOUNT.label} />}
                onChange={() =>
                  this.handleChange(OPTIONS.OTHER_NATIONAL_ACCOUNT)
                }
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col']}>
              <RadioButton
                id={OPTIONS.NONE.key}
                checked={selectedOption === OPTIONS.NONE.key}
                label={<Trans id={OPTIONS.NONE.label} />}
                onChange={() => this.handleChange(OPTIONS.NONE)}
                readOnly={isReadOnlyCase}
              />
            </Column>
          </Row>
        </Column>

        {selectedOptionLabel && (
          <VisuallyHidden>
            <Trans id={PANEL_TITLE + ':'} />
            <Trans id={selectedOptionLabel} />
          </VisuallyHidden>
        )}
      </Row>
    );
  }
}

export default compose(
  setDisplayName('Other'),
  withSetPaymentDetails,
  withReadOnlyCase,
)(Other);
