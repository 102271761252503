import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';

import { Row, Column } from 'styled-components-grid';

import VisuallyHidden from 'elements/VisuallyHidden';

import withCustomerFromCall from '../withCustomerFromCall';
import CustomStoreCustomer from './CustomStoreCustomer';
import CustomerSelector from './CustomerSelector';
import OtherCustomer from './OtherCustomer';
import StoreCustomerSelector from './StoreCustomerSelector';

import { PANEL_TITLE } from '../constants';

function getCustomerInputForType(customerType, customerTypes) {
  switch (customerType) {
    case get(customerTypes, 'NATIONAL.type'):
      return CustomerSelector;
    case get(customerTypes, 'STORE.type'):
      return StoreCustomerSelector;
    case get(customerTypes, 'CUSTOM_STORE.type'):
      return CustomStoreCustomer;
    case get(customerTypes, 'OTHER.type'):
      return OtherCustomer;
    default:
      return CustomerSelector;
  }
}

export function CustomerInput({ customerType, caseCustomerName, ...rest }) {
  const CustomerInputForType = getCustomerInputForType(
    customerType,
    rest.customerTypes,
  );

  return (
    <Row>
      <Column modifiers={['col']}>
        <CustomerInputForType {...rest} />
      </Column>

      {caseCustomerName && (
        <VisuallyHidden>
          <Trans id={PANEL_TITLE + ':'} />
          {caseCustomerName}
        </VisuallyHidden>
      )}
    </Row>
  );
}

CustomerInput.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  customerType: PropTypes.string.isRequired,
  customerTypes: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  caseCustomerName: PropTypes.string,
};

export default compose(
  setDisplayName('CustomerInput'),
  withCustomerFromCall,
)(CustomerInput);
