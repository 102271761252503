import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Text } from 'base-components';

import HighlightText from 'components/HighlightText';

import UnitAttributesDropdown from './UnitAttributesDropdown';

function UnitAttributesDropdownList(props) {
  const { parentType, types, getItemValue, highlightText } = props;
  const renderItem = (type) => (
    <Text>
      <HighlightText text={highlightText}>{getItemValue(type)}</HighlightText>
    </Text>
  );
  return (
    <UnitAttributesDropdown.Content id={parentType}>
      <Dropdown.List name={parentType}>
        <Dropdown.ListItem key={parentType} id={parentType}>
          {renderItem(parentType)}
        </Dropdown.ListItem>
        {types.map((type) => (
          <Dropdown.ListItem key={type} id={type}>
            {renderItem(type)}
          </Dropdown.ListItem>
        ))}
      </Dropdown.List>
    </UnitAttributesDropdown.Content>
  );
}

UnitAttributesDropdownList.propTypes = {
  parentType: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  getItemValue: PropTypes.func.isRequired,
  highlightText: PropTypes.string,
};

export default UnitAttributesDropdownList;
