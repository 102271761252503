import gql from 'graphql-tag';

export default gql`
  mutation createNewStoreAccountsReport($startTime: Date!, $endTime: Date!) {
    createNewStoreAccountsReport(
      filters: { startTime: $startTime, endTime: $endTime }
    ) {
      jobId
    }
  }
`;
