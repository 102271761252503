import React from 'react';
import PropTypes from 'prop-types';
import { branch, compose, setDisplayName } from 'recompose';
import { Trans } from '@lingui/macro';
import moment from 'moment-timezone';

import { Row, Column } from 'styled-components-grid';

import Tag from 'blocks/Tag';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';
import withCustomerSelect from '../../withCustomerSelect';
import withStoreCustomerSelect from '../../withStoreCustomerSelect';
import withPreferencesUpdatedAt from './withPreferencesUpdatedAt';

const formatDate = (value) =>
  moment(value).tz(moment.tz.guess()).format('D MMM YYYY, h:mm A z');

function PreferencesUpdatedAt({ updatedAt, caseStatus }) {
  if (
    !updatedAt ||
    [CASE_STATUS.new, CASE_STATUS.dispatch].includes(caseStatus)
  ) {
    return null;
  }

  return (
    <Row modifiers={['middle']} style={{ marginBottom: 15 }}>
      <Column>
        <Tag>
          <Tag.Icon name="info-circle" modifiers={['warning', 'mini']} />
          <Tag.Text>
            <Trans
              id="Preferences confirmed at {date}"
              values={{
                date: formatDate(updatedAt),
              }}
            />
          </Tag.Text>
        </Tag>
      </Column>
    </Row>
  );
}

PreferencesUpdatedAt.propTypes = {
  caseStatus: PropTypes.string.isRequired,
  updatedAt: PropTypes.string,
  customer: PropTypes.shape({ id: PropTypes.string }),
};

PreferencesUpdatedAt.defaultProps = {
  updatedAt: null,
  customer: undefined,
};

const isNationalCustomer = ({ customerType, customerTypes }) =>
  customerType === customerTypes.NATIONAL.type;

export default compose(
  setDisplayName('PreferencesUpdatedAt'),
  /**
   * Order is important here. `withWheelPreferences` needs the current customer
   * as a prop, so we must run `withCustomerSelect/withStoreCustomerSelect`
   * first.
   */
  branch(isNationalCustomer, withCustomerSelect, withStoreCustomerSelect),
  withPreferencesUpdatedAt,
)(PreferencesUpdatedAt);
