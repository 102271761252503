/* eslint-disable camelcase */
import React from 'react';
import moment from 'moment-timezone';
import { defineMessage, t, Trans } from '@lingui/macro';
import { camelCase, find, get, startCase, snakeCase } from 'lodash';

import { Text } from 'base-components';
import { Row, Column } from 'styled-components-grid';

import { pickTranslationForValue } from 'components/FieldUpdates/utils';
import { getScalarTitle as getScalarTireOptionTitle } from 'compositions/CaseRequestsPanel/CaseRequestsPanelContext/withRequestLineOptions';

import {
  labelForReason as delayReasonTranslations,
  labelForStatus as caseStatusTranslations,
} from 'compositions/CaseDetailOverviewPanel/ManageDelaysModal/constants';

import {
  allAssetTypes,
  validationType,
  validationTypeLabels,
  validationFieldsLabels,
} from 'compositions/CaseAssetValidationPanel/constants';

import {
  searchAreaOptionsInMiles as proximityLevelsInMiles,
  searchAreaOptionsInKm as proximityLevelsInKm,
} from 'compositions/DealerLocator/constants';

import { getFieldDisplayValue } from 'compositions/CaseAssetValidationPanel/utils';

import countOfTime from 'utils/countOfTime';
import { formatPhone } from 'utils/format';
import { IMPERIAL, METRIC, getUnits } from 'utils/unit';
import { metersToMiles, milesToMeters } from 'utils/measure';
import { degToCompass, fahrenheitToCelsius } from 'utils/weather';

const metricUnits = getUnits(METRIC);
const imperialUnits = getUnits(IMPERIAL);

export const ITEMS_PER_PAGE = 50;

const requestLineFieldTitles = {
  unit_number: t`Unit`,
  requested_action: t`Requested Action`,
  tire_condition: t`Tire Condition`,
  axle_type: t`Axle Type`,
  tire_position: t`Tire Position`,
  product_type: t`Tire Type`,
  manufacturer_full_name: t`Brand`,
  tire_size: t`Tire Size`,
  sculpture_tread_name: t`Tread Design`,
  load_range: t`Load Range`,
  rim_type: t`Rim Type`,
  rim_material: t`Rim Material`,
  wheel_offset: t`Wheel Offset`,
};

const assetFieldTitles = {
  unit_number: t`Unit Number`,
  asset_type: t`Asset Type`,
  dropped_unit: t`Dropped Unit`,
  make_name: t`Make`,
  model_name: t`Model`,
  year: t`Year`,
  license_plate: t`License Plate`,
  license_state_abbreviation: t`License State`,
  vin: t`VIN`,
  odometer: t`Odometer`,
};

// The properties in these objects are sorted according
// to the order the UI wants them. Please do not change it.
export const fieldTitles = {
  other: {
    status: t`Status`,
    billable: t`Billable/Not Billable`,
    assigned_to_user_name: t`Assigned User`,
    assigned_to_email: t`Assigned User Email`,
    quick_note: t`Heads-Up`,
    fixpix_push_result: t`FIXPIX Status`,
    rolling_at: t`Roll Time`,
    cancel_reason: t`Cancel Reason`,
    cancel_reason_note: t`Other Cancel Reason`,
    proximity_level: t`Proximity Radius`,

    is_delayed_service: t`Delayed Service`,
    delayed_service_notes: t`Delayed Service Notes`,
    delayed_service_scheduled_date: t`Delayed Service Completion Date`,
    delayed_service_scheduled_dispatch_date: t`Delayed Service Dispatch At Date`,
  },

  request_line: requestLineFieldTitles,

  agreement_line: {
    ...requestLineFieldTitles,
    agreed: t`Agreed`,
  },

  supplied_line: {
    ...requestLineFieldTitles,
    supplied: t`Supplied`,
  },

  special_instructions: {
    special_instructions: t`Special Instructions`,
  },

  payment: {
    method: t`Payment Method`,
    credit_card_name: t`Name on Card`,
    credit_card_first_digit: t`Credit Card First Digit`,
    credit_card_last_4_digits: t`Credit Card Last Four Digits`,
    credit_card_expiration_month: t`Credit Card Expiration Month`,
    credit_card_expiration_year: t`Credit Card Expiration Year`,
    third_party_transaction_details: t`Transaction Receipt`,
  },

  primary_asset: assetFieldTitles,
  related_asset: assetFieldTitles,

  case_contact: {
    name: t`Name`,
    contact_type: t`Contact Type`,
    phone: t`Phone Number`,
    phone_ext: t`Extension`,
    phone_extension: t`Extension`,
    email: t`Email`,
    callback_eta: t`Call with ETA`,
    callback_roll_time: t`Call with Roll Time`,
  },

  inbound_program: {
    name: t`Program`,
    phone_number: t`Phone Number`,
    store: t`Store`,
    address: t`Location`,
    bill_to: t`Bill To #`,
    ship_to: t`Ship To #`,
  },

  billing: {
    po_number: t`PO Number`,
    reference_number: t`Reference Number`,
    ticket_number: t`Ticket/DR Number`,
    invoice_number: t`Invoice Number`,
    amount: t`Amount`,
    comment: t`Billing Comment`,
    dr_number: t`DR Number`,
    wo_number: t`Work Order Number`,
  },

  customer: {
    type: t`Account Type`,
    name: t`Name`,
    city: t`City`,
    state: t`State`,
    bill_to: t`Bill To #`,
    ship_to: t`Ship To #`,
    account_number: t`Account #`,
    zip: t`Zip Code`,
  },

  customer_domicile: {
    name: t`Name`,
    city: t`City`,
    state: t`State`,
    bill_to: t`Bill To #`,
    ship_to: t`Ship To #`,
  },

  asset_location: {
    search_value: t`Asset Location`,
    street_address: t`Address`,
    city: t`City`,
    province: t`State`,
    postal_code: t`Zip Code`,
    country: t`Country`,
    coordinates: t`GPS Coordinates`,
    highway: t`Highway`,
    mile_marker: t`Mile Marker`,
    note: t`Note`,
  },

  dispatched_status_conditions: {
    travel_estimate_distance_m: t`Travel Distance`,
    travel_estimate_time_s: t`Travel Time`,
    weather_forecast_current_time_s: t`Local Time`,
    weather_forecast_timezone: t`Timezone`,
    weather_forecast_current_summary: t`Summary`,
    weather_forecast_current_temperature_f: t`High°`,
    weather_forecast_current_wind_bearing_d: t`Wind Direction`,
    weather_forecast_current_wind_speed_mph: t`Wind Speed`,

    weather_forecast_current_precip_type: t`Precipitation`,
    weather_forecast_current_precip_probability: t`Precipitation %`,
    weather_forecast_today_temperature_high_f: t`Forecasted High°`,
    weather_forecast_today_temperature_low_f: t`Forecasted Low°`,
    weather_forecast_today_wind_bearing_d: t`Forecasted Wind Direction`,
    weather_forecast_today_wind_speed_mph: t`Forecasted Wind Speed`,
    weather_forecast_today_precip_type: t`Forecasted Precipitation`,
    weather_forecast_today_precip_probability: t`Forecasted Precipitation %`,
  },

  dealer_response: {
    response_time: t`Date/Time`,
    phone_number: t`Phone Number`,
    contact_person: t`Contact`,
    accepted: t`Accepted/Rejected`,
    reason: t`Reason`,
    eta_min_minutes: t`Minimum ETA`,
    eta_max_minutes: t`Max ETA`,
    note: t`Note`,
    asset_location_drive_distance: t`Drive Distance`,
    asset_location_drive_time: t`Drive Time`,
    technician_name: t`Technician`,
    technician_phone: t`Technician Phone`,
    actual_eta: t`Arrival time`,
  },

  custom_dealer: {
    name: t`Name`,
    mileage_range_min: t`Minimum Mileage`,
    mileage_range_max: t`Max Mileage`,
  },

  delay: {
    status: t`Status`,
    reason_type: t`Delay Reason`,
    start_time: t`Date/Time`,
    end_time: t`End Date/Time`,
    custom_reason: t`Custom Reason`,
  },

  asset_validation: {
    type_none: validationTypeLabels[validationType.none],
    type_offline: validationTypeLabels[validationType.offline],
  },

  asset_validation_entry_attempt: {
    timestamp: t`Assets Search Date`,
    reference: t`Assets Search Reference`,
    input: t`Assets Search Criteria`,
    asset: t`Selected Asset`,
  },
};

export const changeSummarySentences = {
  other: defineMessage({ message: '{userName} {performedAction} the case.' }),

  inbound_program: defineMessage({
    message: '{userName} {performedAction} the <0>inbound program</0>.',
  }),
  customer: defineMessage({
    message: '{userName} {performedAction} the <0>fleet</0>.',
  }),
  customer_domicile: defineMessage({
    message: '{userName} {performedAction} the <0>domicile</0>.',
  }),
  case_contact: defineMessage({
    message: '{userName} {performedAction} <0>contact</0> <1>{contactId}</1>.',
  }),
  payment: defineMessage({
    message: '{userName} {performedAction} the <0>payment</0>.',
  }),
  billing: defineMessage({
    message: '{userName} {performedAction} the <0>billing</0>.',
  }),
  asset_location: defineMessage({
    message: '{userName} {performedAction} the <0>asset location</0>.',
  }),
  dispatched_status_conditions: defineMessage({
    message:
      '{userName} {performedAction} the <0>asset location conditions</0>.',
  }),

  custom_dealer: defineMessage({
    message:
      '{userName} {performedAction} <0>out-of-network service provider</0> <1>{customDealerId}</1>.',
  }),
  dealer_response: defineMessage({
    message:
      '{userName} {performedAction} <0>call record</0> <1>{callRecordId}</1>, related to <2>{dealerType}</2> <3>{dealerSelectionId}</3>.',
  }),

  primary_asset: defineMessage({
    message: '{userName} {performedAction} <0>asset</0> <1>{assetId}</1>.',
  }),
  related_asset: defineMessage({
    message: '{userName} {performedAction} <0>asset</0> <1>{assetId}</1>.',
  }),

  request_line: defineMessage({
    message:
      '{userName} {performedAction} <0>service request</0> <1>{serviceRequestId}</1>.',
  }),
  agreement_line: defineMessage({
    message:
      '{userName} {performedAction} <0>agreed service</0> <1>{agreedServiceId}</1>, related to <2>service request</2> <3>{serviceRequestId}</3>.',
  }),
  supplied_line_attached: defineMessage({
    message:
      '{userName} {performedAction} <0>supplied service</0> <1>{suppliedServiceId}</1>, related to <2>service request</2> <3>{serviceRequestId}</3> and <4>agreed service</4> <5>{agreedServiceId}</5>.',
  }),
  supplied_line_detached: defineMessage({
    message:
      '{userName} {performedAction} <0>supplied service</0> <1>{suppliedServiceId}</1>.',
  }),

  special_instructions: defineMessage({
    message: '{userName} {performedAction} the <0>special instructions</0>.',
  }),

  delay: defineMessage({
    message:
      '{userName} {performedAction} a <0>delay</0> (<1>{reasonType}</1>).',
  }),

  asset_validation: defineMessage({
    message: '{userName} updated the <0>asset validation</0>.',
  }),

  asset_validation_entry_attempt: defineMessage({
    message:
      '{userName} updated the <0>asset validation</0> of "<1>{assetType}</1>" for <2>Asset {assetIndex}</2> <3>{assetId}</3>.',
  }),
};

export const relatedInfoPathsByType = {
  case_contact: { contactId: 'entityId' },
  custom_dealer: { customDealerId: 'entityId' },
  primary_asset: { assetId: 'entityId' },
  related_asset: { assetId: 'entityId' },
  request_line: { serviceRequestId: 'entityId' },

  dealer_response: {
    callRecordId: 'entityId',
    dealerType: (entry) => {
      const labels = {
        true: t`out-of-network service provider`,
        false: t`service provider`,
      };

      return labels[get(entry, 'info.custom_dealer') || 'false'];
    },
    dealerSelectionId: 'info.dealer_id',
  },

  agreement_line: {
    agreedServiceId: 'entityId',
    serviceRequestId: 'info.request_line_id',
  },

  supplied_line_attached: {
    suppliedServiceId: 'entityId',
    serviceRequestId: 'info.request_line_id',
    agreedServiceId: 'info.agreed_line_id',
  },
  supplied_line_detached: { suppliedServiceId: 'entityId' },

  delay: {
    reasonType: (entry) => {
      const reasonType = get(entry, 'info.reason_type', '');
      return get(delayReasonTranslations, reasonType.toUpperCase(), reasonType);
    },
  },

  asset_validation_entry_attempt: {
    assetId: 'info.asset_id',
    assetType: (entry) => {
      const type = get(entry, 'info.type') || '';

      return allAssetTypes[type] || type;
    },
    assetIndex: (entry) =>
      parseInt(get(entry, 'info.asset_index') || 0, 10) + 1,
  },
};

export const blacklistedFieldPaths = [
  'customer.customer_id',

  'asset_location.country_code',

  'asset_validation_entry_attempt.type',
  'asset_validation_entry_attempt.user_id',
  'asset_validation_entry_attempt.asset_validation_entry_id',
];

const booleanValueTransformer = (value) =>
  pickTranslationForValue(value, {
    TRUE: t`Yes`,
    FALSE: t`No`,
  });

const phoneValueTransformer = (value) => formatPhone(value);

const dateValueTransformer = (value) =>
  moment(value).tz(moment.tz.guess()).format('D MMM YYYY, h:mm A z');

// fahrenheit => 33ºF/7ºC
const temperatureValueTransformer = (value) => {
  const fahrenheit = parseFloat(value);
  const celsius = fahrenheitToCelsius(fahrenheit).toFixed(1);

  return (
    <Trans id="{fahrenheit}ºF / {celsius}ºC" values={{ fahrenheit, celsius }} />
  );
};

// 0.6 => 60%
const percentageFractionValueTransformer = (value) => {
  const percentage = parseFloat(value) * 100;

  return <Trans id="{percentage}%" values={{ percentage }} />;
};

const precipitationTypeValueTransformer = (value) =>
  value === 'snow' ? (
    <Trans id="Chance Of Snow" />
  ) : (
    <Trans id="Chance Of Rain" />
  );

// 200 => SSW
const windBearingValueTransformer = (value) => (
  <Trans id={degToCompass(value)} />
);

// mph => 9mi/h • 17km/h
const windSpeedValueTransformer = (value) => {
  const mph = parseInt(value, 10);
  const kmh = (milesToMeters(mph) / 1000).toFixed(1);

  return <Trans id="{mph}mi/h • {kmh}km/h" values={{ mph, kmh }} />;
};

const assetValidationInputOrAssetTransformer = (_, options) => {
  const { fieldName, rowData } = options;

  let prevValues = {};
  let newValues = {};

  try {
    prevValues = JSON.parse(rowData.previousValue) || {};
    newValues = JSON.parse(rowData.newValue) || {};
  } catch (error) {
    return '';
  }

  const data = fieldName === 'newValue' ? newValues : prevValues;

  const fields = Object.entries(data).reduce((acc, [key, value]) => {
    if (newValues[key] === prevValues[key]) return acc;

    return { ...acc, [camelCase(key)]: value };
  }, {});

  return (
    <Row style={{ flexWrap: 'nowrap' }}>
      <Column modifiers={['col', 'padScale_0']}>
        {Object.keys(fields).map((field) => (
          <Row key={field}>
            <Column modifiers="padScaleX_0">
              <Text modifiers={['textLight', 'small']}>
                {validationFieldsLabels[field]}:
              </Text>
            </Column>
            <Column modifiers={['col', 'padScaleX_3']}>
              <Text style={{ wordBreak: 'break-word' }}>
                {getFieldDisplayValue(field, fields) || <span>&mdash;</span>}
              </Text>
            </Column>
          </Row>
        ))}
      </Column>
    </Row>
  );
};

export const fieldValueTransformers = {
  other: {
    billable: (value) =>
      pickTranslationForValue(value, {
        TRUE: t`Billable`,
        FALSE: t`Not Billable`,
      }),

    fixpix_push_result: (value) =>
      pickTranslationForValue(value, {
        NOT_ATTEMPTED: '—',
        SUCCESS: t`Sent to FIXPIX`,
        ERROR: t`FIXPIX Failed`,
        OUTDATED: t`Outdated (Case has changes not sent to FIXPIX)`,
      }),

    rolling_at: dateValueTransformer,

    proximity_level: (value) => {
      const valueInMiles = proximityLevelsInMiles[value];
      const valueInKm = proximityLevelsInKm[value];

      return (
        <Trans
          id="{miles} {milesUnit} • {kms} {kmsUnit}"
          values={{
            miles: valueInMiles,
            milesUnit: imperialUnits.distanceUnitLabel,
            kms: valueInKm,
            kmsUnit: metricUnits.distanceUnitLabel,
          }}
        />
      );
    },
    is_delayed_service: booleanValueTransformer,
    delayed_service_scheduled_date: dateValueTransformer,
    delayed_service_scheduled_dispatch_date: dateValueTransformer,
  },

  'inbound_program.phone_number': phoneValueTransformer,

  'customer.type': (value) =>
    pickTranslationForValue(value, {
      NATIONAL: t`National`,
      STORE: t`Store`,
      CUSTOM_STORE: t`Store`,
      OTHER: t`Other`,
    }),

  case_contact: {
    contact_type: (value) => startCase(value),
    callback_eta: booleanValueTransformer,
    callback_roll_time: booleanValueTransformer,
  },

  'payment.method': (value) =>
    pickTranslationForValue(value, {
      CARD_ON_FILE: t`Existing Credit Card`,
      CASH: t`Cash`,
      COM_CHECK: t`COMcheck`,
      MICHELIN_LINE_OF_CREDIT: t`National Account`,
      NONE: t`Continue without payment method`,
      ONE_TIME_CARD: t`New Credit Card`,
      OTHER_NATIONAL_ACCOUNT: t`Other National Account`,
      STORE_ACCOUNT: t`Store Account`,
      THIRD_PARTY_CREDIT_CARD_TXN: t`Credit Card`,
    }),

  dispatched_status_conditions: {
    // meters => 17 mi • 24.9 Km
    travel_estimate_distance_m: (value) => {
      const meters = parseInt(value, 10);

      return (
        <Trans
          id="{miles} {milesUnit} • {kms} {kmsUnit}"
          values={{
            miles: metersToMiles(meters),
            milesUnit: imperialUnits.distanceUnitLabel,
            kms: meters / 1000,
            kmsUnit: metricUnits.distanceUnitLabel,
          }}
        />
      );
    },

    // seconds => 1 hr 35 min
    travel_estimate_time_s: (value) => countOfTime(parseInt(value, 10) * 1000),

    // timestamp => 31 Mar 2018, 12:00 PM EST
    weather_forecast_current_time_s: (value, { entry }) => {
      const timezone = get(
        find(entry.fields, { field: 'weather_forecast_timezone' }),
        'newValue',
      );

      return moment(value, 'X')
        .tz(timezone || moment.tz.guess())
        .format('D MMM YYYY, h:mm A z');
    },

    weather_forecast_current_temperature_f: temperatureValueTransformer,
    weather_forecast_current_precip_probability: percentageFractionValueTransformer,
    weather_forecast_current_precip_type: precipitationTypeValueTransformer,
    weather_forecast_current_wind_bearing_d: windBearingValueTransformer,
    weather_forecast_current_wind_speed_mph: windSpeedValueTransformer,
    weather_forecast_today_temperature_high_f: temperatureValueTransformer,
    weather_forecast_today_temperature_low_f: temperatureValueTransformer,
    weather_forecast_today_precip_probability: percentageFractionValueTransformer,
    weather_forecast_today_precip_type: precipitationTypeValueTransformer,
    weather_forecast_today_wind_bearing_d: windBearingValueTransformer,
    weather_forecast_today_wind_speed_mph: windSpeedValueTransformer,
  },

  dealer_response: {
    response_time: dateValueTransformer,
    phone_number: phoneValueTransformer,
    technician_phone: phoneValueTransformer,
    accepted: (value) =>
      pickTranslationForValue(value, {
        TRUE: t`Accepted`,
        FALSE: t`Rejected`,
      }),
  },

  primary_asset: {
    dropped_unit: booleanValueTransformer,
    asset_type: (value) => startCase(value),
    unit_attribute: (value) => startCase(value),
  },

  related_asset: {
    dropped_unit: booleanValueTransformer,
    asset_type: (value) => startCase(value),
    unit_attribute: (value) => startCase(value),
  },

  ...[
    'request_line',
    'agreement_line',
    'supplied_line',
    'supplied_line_attached',
    'supplied_line_detached',
  ].reduce(
    (rowTypes, rowType) => ({
      ...rowTypes,
      ...[
        'requested_action',
        'tire_condition',
        'axle_type',
        'tire_position',
        'product_type',
        'rim_type',
        'load_range',
      ].reduce(
        (acc, type) => ({
          ...acc,
          [`${rowType}.${type}`]: (value) =>
            getScalarTireOptionTitle(
              camelCase(type),
              snakeCase(value)?.toUpperCase(),
            ),
        }),
        {},
      ),
    }),
    {},
  ),

  'agreement_line.agreed': booleanValueTransformer,
  'supplied_line.supplied': booleanValueTransformer,
  'supplied_line_attached.supplied': booleanValueTransformer,
  'supplied_line_detached.supplied': booleanValueTransformer,

  delay: {
    status: (value) => pickTranslationForValue(value, caseStatusTranslations),

    start_time: dateValueTransformer,
    end_time: dateValueTransformer,

    reason_type: (value) =>
      pickTranslationForValue(value, delayReasonTranslations),
  },

  asset_validation: {
    type_none: booleanValueTransformer,
    type_offline: booleanValueTransformer,
  },

  asset_validation_entry_attempt: {
    input: assetValidationInputOrAssetTransformer,
    asset: assetValidationInputOrAssetTransformer,
    timestamp: dateValueTransformer,
  },
};

const isTypeField = ({ field }) => field === 'type';

// Transforms the single value from "asset_validation.type" into up to two
// field entries, to match the two checkboxes we present in the UI.
const assetValidationEntryTransformer = (entity) => {
  let { fields } = entity;

  const typeField = fields.find(isTypeField);

  if (!typeField) return entity;

  const { previousValue, newValue } = typeField;

  fields = fields.filter((f) => !isTypeField(f));

  if (previousValue && previousValue !== 'match_asset') {
    fields.push({
      field: `type_${previousValue}`,
      newValue: 'FALSE',
      previousValue: 'TRUE',
    });
  }

  if (newValue !== 'match_asset') {
    fields.push({
      field: `type_${newValue}`,
      newValue: 'TRUE',
      previousValue: 'FALSE',
    });
  }

  if (!fields.length) return null;

  return { ...entity, fields };
};

const isExternalAttrField = ({ field }) => field === 'external_attributes';
const assetExternalAttributesTransformer = (entity) => {
  let { fields } = entity;

  const externalAttributesField = fields.find(isExternalAttrField);

  if (!externalAttributesField) return entity;

  fields = fields.filter((f) => !isExternalAttrField(f));

  const { previousValue, newValue } = externalAttributesField;
  const previousValueObject = JSON.parse(previousValue);
  const newValueObject = JSON.parse(newValue);

  for (const [key, value] of Object.entries(newValueObject)) {
    const previousValue = previousValueObject?.[key]?.toString() || null;
    const newValue = value?.toString() || null;
    fields.push({
      field: key,
      previousValue,
      newValue,
    });
  }

  return { ...entity, fields };
};

export const entityTransformers = {
  asset_validation: assetValidationEntryTransformer,
  primary_asset: assetExternalAttributesTransformer,
  related_asset: assetExternalAttributesTransformer,
};
