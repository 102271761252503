import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { QuickActionButton } from 'base-components';

import ButtonText from './ButtonText';
import CaseCancelModal from './CaseCancelModal';
import withCancelCase from './withCancelCase';

export class CaseCancelButton extends Component {
  static propTypes = {
    label: PropTypes.node,
    caseId: PropTypes.string,
    caseNumber: PropTypes.string.isRequired,
    cancelCase: PropTypes.func.isRequired,
    textWhite: PropTypes.bool.isRequired,
    updatedStatus: PropTypes.string,
  };

  static defaultProps = {
    label: <Trans>Cancel</Trans>,
    caseId: undefined,
    updatedStatus: '',
  };

  state = { showModal: false };

  toggleModal = () => this.setState({ showModal: !this.state.showModal });

  handleCancelCase = (data) => this.props.cancelCase(data);

  render() {
    const { showModal } = this.state;
    const { caseId, label, caseNumber, textWhite } = this.props;
    const isDisabled = !caseId;

    return (
      <>
        <QuickActionButton
          onClick={this.toggleModal}
          disabled={isDisabled}
          modifiers={[
            isDisabled && 'disabled',
            'bgTransparent',
            'hoverTransparent',
          ]}
        >
          <ButtonText modifiers={[textWhite && 'textWhite', 'fontWeightLight']}>
            {label}
          </ButtonText>
        </QuickActionButton>
        {showModal && (
          <CaseCancelModal
            caseNumber={caseNumber}
            onCloseModal={this.toggleModal}
            onCancelCase={this.handleCancelCase}
            updatedStatus={this.updatedStatus}
          />
        )}
      </>
    );
  }
}

export default compose(
  setDisplayName('CaseCancelButton'),
  withCancelCase,
)(CaseCancelButton);
