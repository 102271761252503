import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans, Plural } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { Text, InputField } from 'base-components';
import { Row, Column } from 'styled-components-grid';
import VisuallyHidden from 'elements/VisuallyHidden';

import withContext from 'utils/withContext';
import {
  containsCreditCardNumber,
  excludeCreditCardNumber,
  CreditCardComplianceModal,
} from 'utils/creditCardNumberCompliance';

import CaseRequestsPanelContext from '../CaseRequestsPanelContext';

const MAX_CHARACTERS = 1000;

export const SpecialInstructionsInputField = ({
  readOnly,
  specialInstructions,
  updateSpecialInstructions,
  onFocusRequested,
}) => {
  const [value, setValue] = React.useState(specialInstructions ?? null);
  const [hasCCNumber, setHasCCNumber] = React.useState(false);
  const [ignoreCCNumber, setIgnoreCCNumber] = React.useState(false);

  const handleChange = ({ target: { value } }) => {
    setValue(value);
    // if value is empty, hold on saving value until blur
    // otherwise the input field will be hidden while user is still typing
    value !== '' && updateSpecialInstructions(value);

    if (!ignoreCCNumber && containsCreditCardNumber(value)) {
      setHasCCNumber(true);
    }
  };

  const handleBlur = () => {
    updateSpecialInstructions(value);
  };

  return (
    <>
      <InputField
        maxLength={MAX_CHARACTERS}
        name="special-instructions-input"
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={t`Enter note...`}
        value={value}
        modifiers={['height_auto']}
        readOnly={readOnly}
      >
        <Column modifiers={['col', 'padScaleY_0']}>
          <Row>
            <InputField.Label>
              <Text modifiers={['small', 'textLight']}>
                <Trans>Special Instructions (Optional)</Trans>
              </Text>
            </InputField.Label>
            <InputField.CharsLeftLabel>
              {(charsLeft) => (
                <Plural
                  value={charsLeft}
                  one="# Character Left"
                  other="# Characters Left"
                />
              )}
            </InputField.CharsLeftLabel>
          </Row>
          <Row>
            <InputField.TextArea
              style={{ maxHeight: 130 }}
              autosize
              ref={onFocusRequested}
              autoFocus={!specialInstructions}
            />
          </Row>
        </Column>
      </InputField>

      {hasCCNumber && (
        <CreditCardComplianceModal
          onDeleteCreditCardNumber={() => {
            handleChange({
              target: { value: excludeCreditCardNumber(value) ?? '' },
            });
            setHasCCNumber(false);
          }}
          onContinue={() => {
            setHasCCNumber(false);
            setIgnoreCCNumber(true);
          }}
        />
      )}

      {value && (
        <VisuallyHidden>
          <Trans>Special Instructions:</Trans>
          {value}
        </VisuallyHidden>
      )}
    </>
  );
};

SpecialInstructionsInputField.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  specialInstructions: PropTypes.string,
  updateSpecialInstructions: PropTypes.func.isRequired,
  onFocusRequested: PropTypes.func,
};

SpecialInstructionsInputField.defaultProps = {
  specialInstructions: null,
};

export default compose(
  setDisplayName('SpecialInstructionsInputField'),
  withContext(CaseRequestsPanelContext),
)(SpecialInstructionsInputField);
