import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop, isFunction } from 'lodash';

import DropdownBlock from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

import Action from './Action';
import Content from './Content';
import List from './List';
import Target from './Target';

class Dropdown extends Component {
  static Action = Action;

  static Content = Content;

  static Divider = DropdownBlock.Divider;

  static List = List;

  static ListItem = DropdownBlock.ListItem;

  static SectionHeader = DropdownBlock.SectionHeader;

  static SectionBody = DropdownBlock.SectionBody;

  static Target = Target;

  static modifiers = DropdownBlock.modifiers;

  static childContextTypes = {
    [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
  };

  static propTypes = {
    activeItem: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ]),
    arrow: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    fullWidth: PropTypes.bool,
    hideOnChange: PropTypes.bool,
    onChange: PropTypes.func,
    onExpandedChange: PropTypes.func,
    position: PropTypes.oneOf(['bottom', 'bottomLeft', 'bottomRight']),
    readOnly: PropTypes.bool,
    removeOnHide: PropTypes.bool,
    selectable: PropTypes.bool,
    showContent: PropTypes.bool,
    showOnHover: PropTypes.bool,
    zIndex: PropTypes.number,
  };

  static defaultProps = {
    activeItem: '',
    arrow: false,
    fullWidth: false,
    hideOnChange: false,
    onChange: noop,
    onExpandedChange: noop,
    position: 'bottom',
    readOnly: false,
    removeOnHide: true,
    selectable: true,
    showContent: false,
    showOnHover: false,
    zIndex: 1,
  };

  state = {
    activeItem: this.props.activeItem,
    showContent: this.props.showContent,
  };

  getChildContext() {
    return {
      [DROPDOWN_CONTEXT]: {
        activeItem: this.state.activeItem,
        arrow: this.props.arrow,
        fullWidth: this.props.fullWidth,
        handleHover: this.handleHover,
        handleItemClick: this.handleItemClick,
        handleToggle: this.toggleContent,
        hideContent: this.hideContent,
        position: this.props.position,
        readOnly: this.props.readOnly,
        removeOnHide: this.props.removeOnHide,
        selectable: this.props.selectable,
        showContent: this.state.showContent,
        zIndex: this.props.zIndex,
        registerChildrenRef: this.registerChildrenRef,
        handleKeyDown: this.handleKeyDown,
      },
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('focusout', this.handleFocusOutside);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activeItem !== nextProps.activeItem) {
      this.setState({ activeItem: nextProps.activeItem });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('focusout', this.handleFocusOutside);
  }

  setRef = (node) => {
    this.ref = node;
    return this.ref;
  };

  childrenRef = new Map([['main', new Map()]]);

  registerChildrenRef = (itemRef, itemId, parent) => {
    parent ||= 'main';

    if (!this.childrenRef.has(parent)) {
      this.childrenRef.set(parent, new Map());
    }

    this.childrenRef.get(parent).set(itemId, itemRef);
  };

  handleClickOutside = (e) => {
    if (this.state.showContent && !this.ref?.contains(e.target)) {
      this.toggleContent({ show: false });
    }
  };

  // Helps with navigating dropdown options using the keyboard
  handleFocusOutside = (e) => {
    if (this.state.showContent && !this.ref?.contains(e.target)) {
      this.toggleContent({ show: false });
    }
  };

  handleHover = () => {
    if (this.props.showOnHover) {
      this.toggleContent({ show: true });
    }
  };

  toggleContent = ({ show } = {}) => {
    if (this.props.readOnly) {
      return undefined;
    }

    // this allows us to handle all show / hide logic in a single location
    // you can explicitly tell the content to show / hide, or let it toggle.
    if (show !== undefined) {
      this.props.onExpandedChange(show);
      return this.setState({ showContent: show });
    }

    this.props.onExpandedChange(!this.state.showContent);
    return this.setState({ showContent: !this.state.showContent });
  };

  showContent = () => this.toggleContent({ show: true });

  hideContent = () => this.toggleContent({ show: false });

  handleItemClick = (e, itemId) => {
    this.props.onChange(e, itemId);
    if (this.props.hideOnChange) {
      this.toggleContent({ show: false });
      return this.setState({ activeItem: itemId });
    }
    return this.setState({ activeItem: itemId });
  };

  getOrderedChildren = (parentListName = 'main') => {
    if (!this.childrenRef.has(parentListName)) return [];

    const children = this.childrenRef.get(parentListName);

    const list = Array.from(children)
      .map(([_, el]) => el)
      .filter((el) => el);

    return list.sort((a, b) => {
      return a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_PRECEDING
        ? 1
        : -1;
    });
  };

  handleKeyDown = (e, parentListName) => {
    const list = parentListName
      ? this.getOrderedChildren(parentListName)
      : this.getOrderedChildren();

    const itemCount = list.length;
    const index = list.findIndex((el) => el === document.activeElement);

    const maybeOpen = () => {
      // if the content isn't showing, we want to show it before we do anything else
      if (!this.state.showContent && !['Tab', 'Escape'].includes(e.key)) {
        this.showContent();
      }
    };

    maybeOpen();

    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        this.state.showContent && this.hideContent();
        break;
      case 'Enter':
        e.preventDefault();
        // if the content is already showing, we want to select the first visible item
        const visibleItem =
          list[index] || list.find((el) => el.offsetParent !== null);
        visibleItem?.click();
        break;
      case 'ArrowUp':
        e.preventDefault();
        // find the previous item, if we're at the top, go to the bottom
        const previousIndex = index === 0 ? itemCount - 1 : index - 1;
        list[Math.max(previousIndex, 0)]?.focus();
        break;
      case 'ArrowDown':
        e.preventDefault();
        // find the next item, if we're at the bottom, go to the top
        const nextIndex = index === itemCount - 1 ? 0 : index + 1;
        list[Math.min(nextIndex, itemCount - 1)]?.focus();
        break;
      case 'ArrowRight':
        e.preventDefault();
        const itemId = list[index]?.getAttribute('id');
        const isParent = this.childrenRef.has(itemId);
        // if we're on a item with sub-items, go to the first item in the section
        if (isParent) {
          const subList = this.getOrderedChildren(itemId);
          subList[0]?.focus();
        }
        break;
      case 'ArrowLeft':
        e.preventDefault();
        // if we're on a sub-item, go back to the parent item
        if (parentListName) {
          const parentItem = this.getOrderedChildren().find(
            (el) => el.getAttribute('id') === parentListName,
          );
          parentItem?.focus();
        }
        break;
      default:
        break;
    }
  };

  render() {
    const { children, position, onExpandedChange, ...rest } = this.props;

    return (
      <DropdownBlock ref={this.setRef} modifiers={[position]} {...rest}>
        {isFunction(children)
          ? children({
              show: this.showContent,
              hide: this.hideContent,
              toggle: this.toggleContent,
              isVisible: this.state.showContent,
              onKeyDown: this.handleKeyDown,
            })
          : children}
      </DropdownBlock>
    );
  }
}

export default Dropdown;
