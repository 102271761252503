import styled from 'styled-components';

import { buildStyledComponent } from 'decisiv-ui-utils';

const styles = `
  position: absolute;
  left: -1000000px;
`;

/**
 *  VisuallyHidden
 * @type Component
 * @description A component that is visually hidden but still accessible to Guru's extension Custom Knowledge Triggers.
 * https://www.getguru.com/
 */
export default buildStyledComponent('VisuallyHidden', styled.span, styles, {
  defaultProps: {
    'aria-hidden': true,
  },
});
