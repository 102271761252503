import React, { Children } from 'react';
import PropTypes from 'prop-types';

import DropdownBlock from '../../blocks/Dropdown';

import { DROPDOWN_CONTEXT } from './constants';

function List({ children, name, ...rest }, context) {
  const {
    activeItem,
    handleItemClick,
    handleKeyDown,
    selectable,
    registerChildrenRef,
  } = context[DROPDOWN_CONTEXT];

  function getItemId(child, i) {
    // this ternary allows us to not have to add a name prop
    // for a dropdown that only contains a single list
    const identifier = child.props.id || i.toString();
    return name.length ? `${name}:${identifier}` : identifier;
  }

  function handleClick(e, itemId) {
    if (selectable) {
      handleItemClick(e, itemId);
    }
  }

  const listItems = Children.map(children, (child, i) => {
    const itemId = getItemId(child, i);
    const isActive = activeItem === itemId;
    const defaultModifiers = ['fluid'];

    const modifierProps =
      child?.props?.modifiers?.concat(isActive ? 'active' : []) || [];

    const listItem = React.cloneElement(child, {
      onClick: (e) => handleClick(e, itemId),
      onKeyDown: (e) => handleKeyDown(e, name),
      modifiers: defaultModifiers.concat(modifierProps),
      tabIndex: selectable ? 0 : -1,
      ref: (el) => {
        registerChildrenRef(el, itemId, name);
      },
    });

    return listItem;
  });

  return (
    <DropdownBlock.List name={name} {...rest}>
      {listItems}
    </DropdownBlock.List>
  );
}

List.contextTypes = {
  [DROPDOWN_CONTEXT]: PropTypes.shape({}).isRequired,
};

List.defaultProps = {
  name: '',
};

List.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default List;
