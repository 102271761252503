import PropTypes from 'prop-types';
import React from 'react';

import { modifiersForComponent } from '../../utils/modifiers';

import Button from './Button';
import Li from './Li';

const SelectableLi = React.forwardRef(
  ({ modifiers, children, ...rest }, ref) => (
    <Li modifiers={modifiersForComponent(modifiers, Li)}>
      <Button
        modifiers={modifiersForComponent(modifiers, Button)}
        ref={ref}
        {...rest}
      >
        {children}
      </Button>
    </Li>
  ),
);

SelectableLi.displayName = 'SelectableLi';

SelectableLi.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
};

SelectableLi.defaultProps = {
  modifiers: [],
};

export default SelectableLi;
