import React from 'react';
import { Trans } from '@lingui/macro';

import { Divider } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import StyledTopNav from 'blocks/TopNav';
import { CallLinkInfo } from 'compositions/CaseCallLinks';
import { AmazonConnect, contactDetailsEnabled } from 'features/amazonConnect';

import CallStatusIcon from './CallStatusIcon';

export default function CurrentCallInfo() {
  return (
    <AmazonConnect>
      {({ agent, contact, contactNumber, uiState }) => {
        if (!agent || !contact || !contactDetailsEnabled(uiState)) {
          return false;
        }

        const { dialedNumber } = contact.getAttributes();
        const agentNumber = dialedNumber && dialedNumber.value;

        const agentDisplayNumber = agentNumber || <Trans>(unknown)</Trans>;

        return (
          <Container modifiers="padScale_0">
            <Row modifiers={['height_100', 'middle', 'start']}>
              <Column style={{ height: '35px' }}>
                <Divider modifiers="vertical" />
              </Column>
              <Column modifiers="padScaleX_2">
                <CallStatusIcon />
                {contact?.isInbound() ? (
                  <Trans>
                    <StyledTopNav.Text
                      modifiers={['fontWeightLight', 'gapRight']}
                    >
                      Inbound Call from
                    </StyledTopNav.Text>
                    <StyledTopNav.Text
                      modifiers={['fontWeightMedium', 'gapRight']}
                    >
                      {contactNumber}
                    </StyledTopNav.Text>
                    <StyledTopNav.Text
                      modifiers={['fontWeightLight', 'gapRight']}
                    >
                      to
                    </StyledTopNav.Text>
                    <StyledTopNav.Text modifiers="fontWeightMedium">
                      {agentDisplayNumber}
                    </StyledTopNav.Text>
                  </Trans>
                ) : (
                  <Trans>
                    <StyledTopNav.Text
                      modifiers={['fontWeightLight', 'gapRight']}
                    >
                      Outbound Call to
                    </StyledTopNav.Text>
                    <StyledTopNav.Text
                      modifiers={['fontWeightMedium', 'gapRight']}
                    >
                      {contactNumber}
                    </StyledTopNav.Text>
                  </Trans>
                )}
              </Column>
              <Column modifiers="padScaleX_2">
                <CallLinkInfo contact={contact} />
              </Column>
            </Row>
          </Container>
        );
      }}
    </AmazonConnect>
  );
}
