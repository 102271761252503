import React, { useCallback } from 'react';
import { get, omit } from 'lodash';
import { useMutation, gql } from '@apollo/client';

import { NAME as REQUEST_LINES_QUERY } from '../withCaseRequestLines';
import { RequestLineFieldsFragment } from '../constants';
import { parseRequestLinesPatch } from '../utils';

const refetchQueries = [REQUEST_LINES_QUERY];

const mutation = gql`
  mutation updateRequestLine(
    $assetId: ID
    $id: ID!
    $loadRange: String
    $manufacturerFullName: String
    $productType: String
    $requestedAction: RequestedAction
    $rimType: RimType
    $sculptureTreadName: String
    $tireCondition: TireCondition
    $tirePosition: TirePosition
    $tireSize: String
    $axleType: AxleType
  ) {
    updateCaseRequestLine(
      input: {
        id: $id
        patch: {
          requestLine: {
            assetId: $assetId
            loadRange: $loadRange
            manufacturerFullName: $manufacturerFullName
            productType: $productType
            requestedAction: $requestedAction
            rimType: $rimType
            sculptureTreadName: $sculptureTreadName
            tireCondition: $tireCondition
            tirePosition: $tirePosition
            tireSize: $tireSize
            axleType: $axleType
          }
        }
      }
    ) {
      requestLine {
        ...RequestLineFields
      }
    }
  }
  ${RequestLineFieldsFragment}
`;

const withCaseUpdateRequestLines = (Component) => (props) => {
  const [mutate] = useMutation(mutation, { refetchQueries });

  const updateRequestedLine = useCallback(
    (lineData) => {
      const values = parseRequestLinesPatch(omit(lineData, 'asset'));
      const assetId = get(lineData, 'asset.id');

      return mutate({ variables: { ...values, assetId } });
    },
    [mutate],
  );

  return <Component {...props} updateRequestedLine={updateRequestedLine} />;
};

export default withCaseUpdateRequestLines;
