import gql from 'graphql-tag';

export default gql`
  mutation createPrimaryAssetMutation($caseId: ID!, $asset: AssetParams!) {
    createPrimaryAsset(input: { caseId: $caseId, assetParams: $asset }) {
      asset {
        id
        unitNumber
        droppedUnit
        assetType
        trailerType
        canDelete
        unitAttribute
      }
    }
  }
`;
