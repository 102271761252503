import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';
import { without, upperFirst, groupBy } from 'lodash';

import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';
import {
  H3,
  Icon,
  Accordion,
  InputGroup,
  Text,
  Tooltip,
} from 'base-components';

import Panel from 'blocks/Panel';
import withContext from 'utils/withContext';
import { CaseStatusContext } from 'compositions/CaseStatus';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import {
  containsCreditCardNumber,
  excludeCreditCardNumber,
  CreditCardComplianceModal,
} from 'utils/creditCardNumberCompliance';

import InputField from './InputField';
import withCaseBillingInfo from './withCaseBillingInfo';

import {
  fieldsConfig,
  ruleTypesLabels,
  requiredFieldsLabels,
  requiredFieldsTooltip,
} from './constants';

const panelStyles = { paddingTop: px2rem(4) };
const accordionDividerStyles = { marginTop: px2rem(10) };

const fieldsConfigKeys = Object.keys(fieldsConfig);

export const fieldsKeysToCheckCreditCardNumber = without(
  fieldsConfigKeys,
  'invoiceNumber',
  'amount',
);

export class CaseBillingPanel extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    billingInfo: PropTypes.shape({
      amount: PropTypes.string,
      comment: PropTypes.string,
      poNumber: PropTypes.string,
      woNumber: PropTypes.string,
      ticketNumber: PropTypes.string,
      invoiceNumber: PropTypes.string,
      releaseNumber: PropTypes.string,
      contractNumber: PropTypes.string,
      referenceNumber: PropTypes.string,
    }),
    isFeatureEnabled: PropTypes.func.isRequired,
    purchaseOrderRule: PropTypes.string.isRequired,
    releaseNumberRule: PropTypes.string.isRequired,
    contractNumberRule: PropTypes.string.isRequired,
    referenceNumberRule: PropTypes.string.isRequired,
    updateCaseBillingInfo: PropTypes.func.isRequired,
  };

  static defaultProps = { billingInfo: {} };

  state = {
    ignoredFields: [],
    fieldWithCreditCardNumber: null,
    detailsExpanded: false,
  };

  onChange = (name, value) => {
    this.props.updateCaseBillingInfo({
      ...this.props.billingInfo,
      [name]: value,
    });

    if (
      fieldsKeysToCheckCreditCardNumber.includes(name) &&
      !this.state.ignoredFields.includes(name) &&
      containsCreditCardNumber(value)
    ) {
      this.setState({ fieldWithCreditCardNumber: name });
    }
  };

  isFieldReadOnly = (name) => {
    const featureName = `updateBilling${upperFirst(name)}`;

    return !this.props.isFeatureEnabled(featureName);
  };

  rules = (name) => {
    const rules = {
      poNumber: this.props.purchaseOrderRule,
      releaseNumber: this.props.releaseNumberRule,
      contractNumber: this.props.contractNumberRule,
      referenceNumber: this.props.referenceNumberRule,
    };

    return name ? rules[name] : rules;
  };

  isFieldRequired = (name) => {
    return !this.rules(name) ? false : this.rules(name) !== 'NOT_REQUIRED';
  };

  requiredFieldsKeys = () => {
    return fieldsConfigKeys.filter(this.isFieldRequired);
  };

  additionalFieldsKeys = () => {
    return without(fieldsConfigKeys, 'poNumber', ...this.requiredFieldsKeys());
  };

  mainFieldsKeys = () => {
    return without(fieldsConfigKeys, ...this.additionalFieldsKeys());
  };

  renderRequiredFieldTooltip = (children) => {
    return (
      <Tooltip position="right">
        <Tooltip.Target>{children}</Tooltip.Target>
        <Tooltip.Content
          style={{
            whiteSpace: 'normal',
            width: px2rem(300),
            lineHeight: 1.5,
          }}
        >
          <Trans id={requiredFieldsTooltip} />
        </Tooltip.Content>
      </Tooltip>
    );
  };

  renderRequiredMessage = () => {
    const groups = Object.entries(
      groupBy(Object.entries(this.rules()), ([, rule]) => rule),
    ).filter(([type]) => type && type !== 'NOT_REQUIRED');

    if (!groups.length) return null;

    return groups.map(([ruleType, groupRules]) => (
      <Row key={ruleType}>
        <Column>
          {this.renderRequiredFieldTooltip(
            <Icon name="info-circle" modifiers="info" />,
          )}
        </Column>
        <Column modifiers="col">
          <Text modifiers="fontWeightMedium">
            <Trans id={ruleTypesLabels[ruleType]} />
          </Text>
          &nbsp;
          <Text>
            {groupRules.map(([field], index) => (
              <Fragment key={field}>
                <Trans id={requiredFieldsLabels[field]} />
                {index < groupRules.length - 1 && <span>, </span>}
              </Fragment>
            ))}
          </Text>
        </Column>
      </Row>
    ));
  };

  getFieldLabel = (name) => {
    return this.isFieldRequired(name)
      ? fieldsConfig[name].label
      : fieldsConfig[name].optionalLabel;
  };

  renderField = (name) => {
    const { billingInfo } = this.props;

    const { detailsExpanded } = this.state;
    const isFieldRequired = this.isFieldRequired(name);

    const field = (
      <InputGroup.Row>
        <InputGroup.Column modifiers={['col']}>
          <CaseShortcut
            action={{
              parent: CASE_SHORTCUT_PANELS.billing,
              id: `${name}BillingAction`,
              name: fieldsConfig[name].label,
              shortcut: fieldsConfig[name].shortcut,
              autoFocus: isFieldRequired || detailsExpanded,
              perform: (onFocus) => {
                if (!isFieldRequired && !detailsExpanded) {
                  this.setState({ detailsExpanded: true }, () => {
                    // attempt to focus after accordion is expanded
                    setTimeout(onFocus, 300);
                  });
                }
              },
            }}
            passRef="onFocusRequested"
          >
            <InputField
              {...fieldsConfig[name]}
              name={name}
              label={this.getFieldLabel(name)}
              onChange={this.onChange}
              readOnly={this.isFieldReadOnly(name)}
              defaultValue={billingInfo[name] || ''}
            />
          </CaseShortcut>
        </InputGroup.Column>
      </InputGroup.Row>
    );

    return isFieldRequired ? this.renderRequiredFieldTooltip(field) : field;
  };

  renderCreditCardComplianceModal = () => {
    const { billingInfo } = this.props;
    const { fieldWithCreditCardNumber: field } = this.state;

    const onDelete = () => {
      this.onChange(field, excludeCreditCardNumber(billingInfo[field]));
      this.setState({ fieldWithCreditCardNumber: null });
    };

    const onContinue = () =>
      this.setState({
        ignoredFields: this.state.ignoredFields.concat(field),
        fieldWithCreditCardNumber: null,
      });

    return (
      <CreditCardComplianceModal
        onContinue={onContinue}
        onDeleteCreditCardNumber={onDelete}
      />
    );
  };

  render() {
    const { caseNumber } = this.props;
    const { fieldWithCreditCardNumber, detailsExpanded } = this.state;

    return (
      <Panel
        style={panelStyles}
        modifiers={['padScaleX_3', 'padScaleY_0']}
        data-testid="CaseBillingPanel"
      >
        <Row>
          <CaseShortcut
            action={{
              parent: CASE_SHORTCUT_PANELS.billing,
              id: 'goToBillingAction',
              name: t`Go to Billing`,
              shortcut: ['b', '0'],
              icon: 'arrow-right',
            }}
            passRef
          >
            <Column modifiers="padScaleY_2">
              <H3 modifiers="fontWeightRegular">
                <Trans>Billing</Trans>
              </H3>
            </Column>
          </CaseShortcut>
        </Row>

        {this.renderRequiredMessage()}

        <Row>
          <Column modifiers={['col', 'padScaleY_2']}>
            <InputGroup modifiers={['padScaleY_4']}>
              {this.mainFieldsKeys().map((name) => (
                <Fragment key={`${caseNumber}-${name}`}>
                  {this.renderField(name)}
                </Fragment>
              ))}
            </InputGroup>
          </Column>
        </Row>
        <Row>
          <Column modifiers={['col', 'padScaleY_0']}>
            <Accordion.Divider style={accordionDividerStyles} />
            <Accordion
              expanded={detailsExpanded}
              onExpandedChange={({ expanded }) =>
                this.setState({ detailsExpanded: expanded })
              }
            >
              <Accordion.Head>
                <Container modifiers="fluid">
                  <Accordion.Title>
                    <Row modifiers="middle">
                      <Column modifiers="col">
                        <Trans>Additional Details</Trans>
                      </Column>
                    </Row>
                  </Accordion.Title>
                </Container>
              </Accordion.Head>
              <Accordion.Body eagerRender>
                <InputGroup modifiers={['padScaleY_4']}>
                  {this.additionalFieldsKeys().map((name) => (
                    <Fragment key={`${caseNumber}-${name}`}>
                      {this.renderField(name)}
                    </Fragment>
                  ))}
                </InputGroup>
              </Accordion.Body>
            </Accordion>
          </Column>
        </Row>

        {fieldWithCreditCardNumber && this.renderCreditCardComplianceModal()}
      </Panel>
    );
  }
}

export default compose(
  setDisplayName('CaseBillingPanel'),
  withContext(CaseStatusContext),
  withCaseBillingInfo,
)(CaseBillingPanel);
