import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { t, Trans } from '@lingui/macro';
import { get, noop } from 'lodash';

import { Divider } from 'base-components';
import { Column, Container, Row } from 'styled-components-grid';

import { AmazonConnect } from 'features/amazonConnect';

import DisabledSoftPhone from './DisabledSoftPhone';
import ContactAttribute from './ContactAttribute';

const languages = {
  EN: t`English`,
  ES: t`Spanish`,
  FR: t`French`,
};

/**
 * The div into which the Amazon Connect CCP will be rendered.
 * Sets up styling for the size of the popover and to remove
 * the border from the CCP iframe.
 */
const ConnectContainer = styled.div`
  width: 350px;
  height: 510px;
  display: ${(props) => (props.initialized ? 'block' : 'none')};

  iframe {
    border: none;
  }
`;

/**
 * Builds the DOM node into which the Amazon Connect CCP
 * will be rendered, and initializes the CCP.
 *
 * Exported for testing only.
 */
export class ConnectCcpDiv extends React.Component {
  static propTypes = {
    initialized: PropTypes.bool.isRequired,
    initCCP: PropTypes.func.isRequired,
    terminateCCP: PropTypes.func.isRequired,
    contact: PropTypes.shape({
      isInbound: PropTypes.func.isRequired,
      getAttributes: PropTypes.func.isRequired,
    }),
  };

  static defaultProps = {
    contact: undefined,
  };

  componentDidMount() {
    this.props.initCCP(this.connectDiv);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-console
    console.trace('Amazon Connect Soft Phone unmounting & terminating CCP...');
    this.props.terminateCCP();
  }

  renderContactAttributes = () => {
    const { contact } = this.props;

    if (!contact || !contact?.isInbound?.()) {
      return null;
    }

    const attributes = contact.getAttributes();
    const inboundProgram = get(attributes, 'customerName.value', '');
    const language = get(attributes, 'language.value', '').toUpperCase();
    const payment = get(attributes, 'paymentType.value', '');

    return (
      <Container modifiers="padScale_0" style={{ textAlign: 'left' }}>
        <Row>
          <Column modifiers="col">
            <ContactAttribute
              label={<Trans id="Inbound Program" />}
              value={inboundProgram}
            />
          </Column>
        </Row>
        <Divider />
        <Row>
          <Column modifiers="col">
            <ContactAttribute
              label={<Trans id="Language" />}
              value={<Trans id={languages[language] || language} />}
            />
          </Column>
          <Divider modifiers="vertical" style={{ height: 'auto' }} />
          <Column modifiers="col">
            <ContactAttribute label={<Trans id="Payment" />} value={payment} />
          </Column>
        </Row>
      </Container>
    );
  };

  render() {
    const { initialized } = this.props;
    return (
      <>
        <ConnectContainer
          id="CCPContainer"
          initialized={initialized}
          ref={(x) => (this.connectDiv = x)}
        />
        {initialized && this.renderContactAttributes()}
        {!initialized && <DisabledSoftPhone />}
      </>
    );
  }
}

/**
 * Render the Amazon Connect CCP.
 */
export function SoftPhone() {
  return (
    <Column modifiers={['col', 'padScale_0']}>
      <AmazonConnect>
        {({ initialized, initialize, terminate, contact }) => (
          <ConnectCcpDiv
            initialized={initialized}
            initCCP={initialize || noop}
            terminateCCP={terminate || noop}
            contact={contact}
          />
        )}
      </AmazonConnect>
    </Column>
  );
}

export default SoftPhone;
