import { t } from '@lingui/macro';

import buildEnum from 'utils/buildEnum';
import { reportTypes } from 'pages/Reports/constants';
import { CASE_STATUS } from 'compositions/CaseStatus';

const buildOptions = (types, titles) =>
  Object.entries(types)
    .reduce((acc, [, value]) => [...acc, [value, titles[value]]], [])
    .filter(Boolean);

const dailyDealerReportTitle = t`Enter your criteria below to run a report on all the case activity per service provider over a 24-hour period (start time is 9 AM).`;

export const instructionsByType = {
  [reportTypes.caseBillingReport]: t`Enter your criteria below to run a report on all the billing activity over a 24-hour period (start time is 12 AM).`,
  [reportTypes.casesPerHour]: t`Enter your criteria below to run a report on the total number of cases created per hour over a 24-hour period (start time is 12 AM).`,
  [reportTypes.dailyAgentMetrics]: t`Enter your criteria below to run a report on all the cases created, dispatched, and rolled per agent over a 24-hour period (start time is 12 AM).`,
  [reportTypes.dailyCasesMetrics]: t`Enter your criteria below to run a report on all the case activity per hour over a 24-hour period (start time is 12 AM).`,
  [reportTypes.dailyDealerReport]: dailyDealerReportTitle,
  [reportTypes.dailyDealerReportByEmail]: dailyDealerReportTitle,

  [reportTypes.assetValidationReport]: t`Enter your criteria below to run a report on the summary or breakdown of asset validation attempts by inbound program, and over a period of time.`,
};

export const configTypes = buildEnum([
  'reportType',
  'reportingPeriod',
  'customReportingPeriod',
  'billableStatus',
  'caseNumber',
  'unitNumber',
  'customerName',
  'customerLocation',
  'inboundProgramName',
  'dealerName',
  'dealerLocation',
  'poNumber',
  'paymentMethod',
  'status',
  'exportType',
  'dateRangeType',
  'reportLevel',
  'recipientAddresses',
  'detailLevel',
  'inboundProgramIds',
  'inboundProgramSelectionType',
  'btStModeType',
]);

export const configTypesTitles = {
  [configTypes.reportType]: t`Report`,
  [configTypes.dateRangeType]: t`Filter By`,
  [configTypes.btStModeType]: t`BT/ST Mode`,
  [configTypes.reportingPeriod]: t`Reporting Period`,
  [configTypes.billableStatus]: t`Billable/Not Billable`,
  [configTypes.caseNumber]: t`Case Number`,
  [configTypes.unitNumber]: t`Unit Number`,
  [configTypes.customerName]: t`Fleet Name`,
  [configTypes.customerLocation]: t`Fleet Location`,
  [configTypes.inboundProgramName]: t`Inbound Program`,
  [configTypes.dealerName]: t`Service Provider Name`,
  [configTypes.dealerLocation]: t`Service Provider Location`,
  [configTypes.poNumber]: t`PO Number`,
  [configTypes.paymentMethod]: t`Payment Type`,
  [configTypes.status]: t`Status`,
  [configTypes.exportType]: t`File Format`,
  [configTypes.recipientAddresses]: t`Send to Email Addresses (comma separated)`,
};

export const configTypesModalTitles = {
  [configTypes.recipientAddresses]: t`Recipient Addresses`,
  [configTypes.detailLevel]: t`Level of Detail`,
  [configTypes.inboundProgramIds]: t`Inbound Programs`,
};

export const configTypesPlaceholders = {
  [configTypes.caseNumber]: t`Search by case number...`,
  [configTypes.unitNumber]: t`Search by unit number...`,
  [configTypes.customerName]: t`Search by fleet name or bill to #...`,
  [configTypes.customerLocation]: t`Search by fleet name, location or ship to #...`,
  [configTypes.inboundProgramName]: t`Search by program name...`,
  [configTypes.dealerName]: t`Search by service provider name or bill to #...`,
  [configTypes.dealerLocation]: t`Search by service provider name, location or ship to #...`,
  [configTypes.poNumber]: t`Search by PO number...`,
  [configTypes.paymentMethod]: t`Select payment type...`,
  [configTypes.status]: t`Select status...`,
  [configTypes.recipientAddresses]: t`Enter email addresses...`,
};

export const configTypesWarnings = {
  [configTypes.caseNumber]: t`Select an existing case.`,
  [configTypes.unitNumber]: t`Select an existing unit.`,
  [configTypes.customerName]: t`Select an existing fleet.`,
  [configTypes.customerLocation]: t`Select an existing fleet.`,
  [configTypes.inboundProgramName]: t`Select an existing program.`,
  [configTypes.dealerName]: t`Select an existing service provider.`,
  [configTypes.dealerLocation]: t`Select an existing service provider.`,
  [configTypes.poNumber]: t`Select an existing PO.`,
};

const dailyDealerConfigVars = [
  configTypes.caseNumber,
  configTypes.customerLocation,
  configTypes.customerName,
  configTypes.dealerName,
  configTypes.dealerLocation,
  configTypes.inboundProgramName,
  configTypes.paymentMethod,
  configTypes.poNumber,
  configTypes.unitNumber,
  configTypes.exportType,
  configTypes.dateRangeType,
  configTypes.reportLevel,
  configTypes.recipientAddresses,
];

export const customConfigVarsByType = {
  [reportTypes.caseBillingReport]: [
    configTypes.dealerName,
    configTypes.dealerLocation,
    configTypes.inboundProgramName,
  ],
  [reportTypes.dailyCasesMetrics]: [
    configTypes.caseNumber,
    configTypes.customerLocation,
    configTypes.customerName,
    configTypes.dealerName,
    configTypes.dealerLocation,
    configTypes.inboundProgramName,
    configTypes.poNumber,
    configTypes.status,
    configTypes.unitNumber,
  ],
  [reportTypes.dailyDealerReport]: dailyDealerConfigVars,
  [reportTypes.dailyDealerReportByEmail]: dailyDealerConfigVars,
  [reportTypes.assetValidationReport]: [
    configTypes.detailLevel,
    configTypes.inboundProgramIds,
  ],
};

// The order of the types defines the order of the dropdown options
export const reportingPeriodTypes = buildEnum([
  'today',
  'yesterday',
  'last7days',
  'last30days',
  'last90days',
  'custom',
]);

export const reportingPeriodTitles = {
  [reportingPeriodTypes.today]: t`Today`,
  [reportingPeriodTypes.yesterday]: t`Yesterday`,
  [reportingPeriodTypes.last7days]: t`Last 7 Days`,
  [reportingPeriodTypes.last30days]: t`Last 30 Days`,
  [reportingPeriodTypes.last90days]: t`Last 90 Days`,
  [reportingPeriodTypes.custom]: t`Custom`,
};

export const reportingPeriodOptions = buildOptions(
  reportingPeriodTypes,
  reportingPeriodTitles,
);

// The order of the types defines the order of the dropdown options
export const billableStatusTypes = buildEnum([
  'any',
  'billable',
  'notBillable',
]);

export const billableStatusTitles = {
  [billableStatusTypes.any]: t`All`,
  [billableStatusTypes.billable]: t`Billable`,
  [billableStatusTypes.notBillable]: t`Not Billable`,
};

export const billableStatusOptions = buildOptions(
  billableStatusTypes,
  billableStatusTitles,
);

export const statusTitles = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.dry_run_canceled]: t`Dry Run`,
};

// The order of the types defines the order of the dropdown options
export const paymentMethodTypes = buildEnum([
  'cash',
  'none',
  'comCheck',
  'cardOnFile',
  'oneTimeCard',
  'creditCardNoPreauth',
  'thirdPartyCreditCardTxn',
  'michelinLineOfCredit',
  'otherNationalAccount',
  'storeAccount',
]);

export const paymentMethodTitles = {
  [paymentMethodTypes.cash]: t`Cash`,
  [paymentMethodTypes.none]: t`Continue without payment method`,
  [paymentMethodTypes.comCheck]: t`COMcheck`,
  [paymentMethodTypes.cardOnFile]: t`Credit Card - Existing `,
  [paymentMethodTypes.oneTimeCard]: t`Credit Card - New`,
  [paymentMethodTypes.creditCardNoPreauth]: t`Credit Card - No Preauth`,
  [paymentMethodTypes.thirdPartyCreditCardTxn]: t`Credit Card - Transaction Receipt`,
  [paymentMethodTypes.michelinLineOfCredit]: t`National Account`,
  [paymentMethodTypes.otherNationalAccount]: t`Other National Account`,
  [paymentMethodTypes.storeAccount]: t`Store Account`,
};

export const paymentMethodTypesOptions = buildOptions(
  paymentMethodTypes,
  paymentMethodTitles,
);

export const exportTypes = buildEnum(['csv', 'pdf']);

export const exportTypesTitles = {
  [exportTypes.csv]: t`CSV`,
  [exportTypes.pdf]: t`PDF`,
};

export const dateRangeTypes = buildEnum(['creationTime', 'rollTime']);

export const dateRangeTypesTitles = {
  [dateRangeTypes.creationTime]: t`Filter by creation date`,
  [dateRangeTypes.rollTime]: t`Filter by rolling date`,
};

export const dateRangeTypesModalTitles = {
  [dateRangeTypes.creationTime]: t`Creation date`,
  [dateRangeTypes.rollTime]: t`Rolling date`,
};

export const btStModeTypes = buildEnum(['bt', 'st']);

export const btStModeTypesTitles = {
  [btStModeTypes.bt]: t`Search by Fleet/Service Provider name`,
  [btStModeTypes.st]: t`Search by Fleet/Service Provider location`,
};

export const defaultReportConfig = {
  [configTypes.dateRangeType]: dateRangeTypes.creationTime,
  [configTypes.btStModeType]: btStModeTypes.bt,
  [configTypes.customReportingPeriod]: undefined,
  [configTypes.exportType]: exportTypes.csv,
  [configTypes.reportingPeriod]: reportingPeriodTypes.today,
  [configTypes.billableStatus]: billableStatusTypes.any,
};

export const createReportJobStatusTypes = buildEnum([
  'loading',
  'failure',
  'success',
]);

export const reportStatusTypes = buildEnum(['loading', 'failure', 'success']);

export const reportLevels = buildEnum(['billTo', 'shipTo']);

export const defaultReportLevel = reportLevels.shipTo;

export const locationFieldTypes = buildEnum(['billTo', 'shipTo']);

export const getLocationFieldKey = (locType) =>
  Object.keys(locationFieldTypes).find(
    (key) => locationFieldTypes[key] === locType,
  );

export const getLocationFieldMarker = (locType) =>
  locType ? `#${getLocationFieldKey(locType)}:` : undefined;

const findLocationTypeFromField = (value) =>
  Object.values(locationFieldTypes).find((locType) =>
    value?.includes(getLocationFieldMarker(locType)),
  );

export const getLocationFieldValue = (value) => {
  const marker = getLocationFieldMarker(findLocationTypeFromField(value));
  return (marker && value?.split(marker)?.[1]) || value;
};

export const getLocationFieldDisplayValue = (value) => {
  const marker = getLocationFieldMarker(findLocationTypeFromField(value));
  return (marker && value?.split(marker)?.[0]) || value;
};

export const locationFieldValueTransformers = {
  [configTypes.customerName]: getLocationFieldValue,
  [configTypes.customerLocation]: getLocationFieldValue,
  [configTypes.dealerName]: getLocationFieldValue,
  [configTypes.dealerLocation]: getLocationFieldValue,
};

export const configValuesTransformers = {
  [reportTypes.assetValidationReport]: {
    [configTypes.inboundProgramIds]: (ids) => ids.map((id) => parseInt(id, 10)),
  },
  [reportTypes.dailyCasesMetrics]: locationFieldValueTransformers,
  [reportTypes.dailyDealerReport]: locationFieldValueTransformers,
  [reportTypes.dailyDealerReportByEmail]: locationFieldValueTransformers,
  [reportTypes.caseBillingReport]: locationFieldValueTransformers,
};
