import React from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { t, Trans } from '@lingui/macro';
import { compose, setDisplayName, withState } from 'recompose';

import { px2rem } from 'decisiv-ui-utils';
import { Row, Column } from 'styled-components-grid';
import { H3, Checkbox } from 'base-components';

import Panel from 'blocks/Panel';
import withContext from 'utils/withContext';
import InputField from 'compositions/CaseBillingPanel/InputField';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

import {
  CASE_PANELS,
  PANEL_STATUSES,
  withReadOnlyCase,
  withCasePanelStatusActions,
} from 'compositions/CaseStatus';

import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import {
  containsCreditCardNumber,
  excludeCreditCardNumber,
  CreditCardComplianceModal,
} from 'utils/creditCardNumberCompliance';

import ValidateAssetModal from './ValidateAssetModal';
import AssetsValidationStatus from './AssetsValidationStatus';
import CaseAssetValidationContext from './CaseAssetValidationContext';

import {
  validationType,
  validationTypeLabels,
  assetValidationPropType,
} from './constants';

const { matchAsset, offline, none } = validationType;
const { partial, invalid, complete, incomplete } = PANEL_STATUSES;

const panelStyles = { paddingTop: px2rem(4), paddingBottom: px2rem(12) };

const allRequirementsMet = ({ requirements }) =>
  Object.values(requirements).every((r) => r.isValidated);

const hasMismatchedRequirement = ({ requirements }) =>
  Object.values(requirements).some((r) => r.isMismatch);

const buildFieldPrefix = (index) => (
  <span>
    {t`Asset ${index + 1}:`}
    &nbsp;
  </span>
);

const buildEntriesStatus = (entries, severity) =>
  entries.reduce((acc, entry) => {
    const { asset } = entry;
    const { index, type } = asset;

    return {
      ...acc,
      [`assetValidation.entries.${index}.${type}`]: {
        prefix: buildFieldPrefix(index),
        severity,
        translationKey: `assetValidation.entries.${type}`,
      },
    };
  }, {});

const missingNotesConfig = { 'assetValidation.notes': 'missing' };

const getPanelStatus = memoize((validationData, validationStatus) => {
  const { type, notes } = validationData;
  const entries = Object.values(validationStatus);
  const ignoreValidationStatus = [offline, none].includes(type);
  const hasRequestedAssets = entries.some((entry) => !!entry.asset.type);

  if (!hasRequestedAssets && !ignoreValidationStatus) return [incomplete];
  if (ignoreValidationStatus && !notes) return [partial, missingNotesConfig];
  if (ignoreValidationStatus || !entries.length) return [complete];

  const invalidEntries = entries.filter(hasMismatchedRequirement);
  const partialEntries = entries.filter((e) => !allRequirementsMet(e));

  if (invalidEntries.length || partialEntries.length) {
    return [
      invalidEntries.length ? invalid : partial,
      {
        ...buildEntriesStatus(partialEntries, 'missing'),
        ...buildEntriesStatus(invalidEntries, 'invalid'),
      },
    ];
  }

  return [complete];
});

const CaseAssetValidationPanel = (props) => {
  const { caseNumber, onNotesFocusRequested } = props;
  const { setCaseAssetValidation, setCasePanelStatus } = props;
  const { requiresAssetValidation, isLoadingAssetValidation } = props;
  const { hideAssetValidationModal, shouldShowAssetValidationModal } = props;
  const { isReadOnlyCase, assetValidation = {}, assetValidationStatus } = props;
  const { notesContainsCreditCardNumber, setContainsCreditCardNumber } = props;
  const { ignoreCreditCardNumber, setIgnoreCreditCardNumber } = props;

  if (
    !requiresAssetValidation ||
    (isLoadingAssetValidation && isEmpty(assetValidation))
  ) {
    return null;
  }

  const [panel, fields] = getPanelStatus(
    assetValidation,
    assetValidationStatus,
  );

  setCasePanelStatus(panel, fields);

  const onCheckboxChange = ({ target: { value, checked } }) =>
    setCaseAssetValidation({ type: checked ? value : matchAsset });

  return (
    <Row modifiers="padScale_1">
      <Column modifiers="col">
        <Panel modifiers="padScaleX_3" style={panelStyles}>
          <Row modifiers="middle">
            <CaseShortcut
              action={{
                parent: CASE_SHORTCUT_PANELS.assetValidation,
                id: 'goToAssetValidationAction',
                name: t`Go to Asset Validation`,
                shortcut: ['a', 'v', '0'],
                priority: 2,
                icon: 'arrow-right',
              }}
            >
              {({ onFocusRequested }) => (
                <Column modifiers="padScaleY_2" ref={onFocusRequested}>
                  <H3 modifiers="fontWeightRegular">
                    <Trans>Asset Validation</Trans>
                  </H3>
                </Column>
              )}
            </CaseShortcut>
          </Row>
          <Row>
            <Column modifiers="col">
              <AssetsValidationStatus {...props} />

              {shouldShowAssetValidationModal && (
                <ValidateAssetModal
                  {...props}
                  onClose={() => hideAssetValidationModal()}
                  readOnly={isReadOnlyCase}
                  caseNumber={caseNumber}
                />
              )}
            </Column>
          </Row>
          <Row>
            <Column modifiers="padScaleY_3">
              <Checkbox
                id={`assetValidationType_${offline}`}
                name={`assetValidationType_${offline}`}
                value={offline}
                label={<Trans id={validationTypeLabels[offline]} />}
                checked={assetValidation.type === offline}
                readOnly={isReadOnlyCase}
                onChange={onCheckboxChange}
              />
            </Column>
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_0']}>
              <Checkbox
                id={`assetValidationType_${none}`}
                name={`assetValidationType_${none}`}
                value={none}
                label={<Trans id={validationTypeLabels[none]} />}
                checked={assetValidation.type === none}
                readOnly={isReadOnlyCase}
                onChange={onCheckboxChange}
              />
            </Column>
          </Row>
          <Row style={{ marginTop: px2rem(10) }}>
            <Column modifiers="col">
              <CaseShortcut
                action={{
                  parent: CASE_SHORTCUT_PANELS.assetValidation,
                  id: 'assetValidationNotesAction',
                  name: t`Notes`,
                  shortcut: ['a', 'v', 'n'],
                  priority: 1,
                }}
              >
                {({ onFocusRequested: onShortcutFocusRequested }) => (
                  <InputField
                    name="assetValidationNotes"
                    label={
                      assetValidation.type === matchAsset ? (
                        <Trans>Notes (Optional)</Trans>
                      ) : (
                        <Trans>Notes (Required)</Trans>
                      )
                    }
                    onFocusRequested={(ref) => {
                      onNotesFocusRequested(ref);
                      onShortcutFocusRequested(ref);
                    }}
                    onChange={(_, notes) => {
                      setCaseAssetValidation({ notes });

                      if (
                        !ignoreCreditCardNumber &&
                        containsCreditCardNumber(notes)
                      ) {
                        setContainsCreditCardNumber(true);
                      }
                    }}
                    readOnly={isReadOnlyCase}
                    multiline
                    maxLength={1000}
                    defaultValue={assetValidation.notes || ''}
                    showCharsCounter
                  />
                )}
              </CaseShortcut>
            </Column>

            {notesContainsCreditCardNumber && (
              <CreditCardComplianceModal
                onDeleteCreditCardNumber={() => {
                  setCaseAssetValidation({
                    notes: excludeCreditCardNumber(assetValidation.notes),
                  });
                  setContainsCreditCardNumber(false);
                }}
                onContinue={() => {
                  setContainsCreditCardNumber(false);
                  setIgnoreCreditCardNumber(true);
                }}
              />
            )}
          </Row>
        </Panel>
      </Column>
    </Row>
  );
};

CaseAssetValidationPanel.propTypes = {
  caseNumber: PropTypes.string.isRequired,
  isReadOnlyCase: PropTypes.bool.isRequired,
  assetValidation: assetValidationPropType.isRequired,
  setCasePanelStatus: PropTypes.func.isRequired,
  assetValidationStatus: PropTypes.shape({}).isRequired,
  onNotesFocusRequested: PropTypes.func.isRequired,
  setCaseAssetValidation: PropTypes.func.isRequired,
  requiresAssetValidation: PropTypes.bool.isRequired,
  isLoadingAssetValidation: PropTypes.bool.isRequired,
  hideAssetValidationModal: PropTypes.func.isRequired,
  shouldShowAssetValidationModal: PropTypes.bool.isRequired,
  notesContainsCreditCardNumber: PropTypes.bool.isRequired,
  setContainsCreditCardNumber: PropTypes.func.isRequired,
  ignoreCreditCardNumber: PropTypes.bool.isRequired,
  setIgnoreCreditCardNumber: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('CaseAssetValidationPanel'),
  withReadOnlyCase,
  withContext(CaseAssetValidationContext),
  withCasePanelStatusActions(CASE_PANELS.assetValidation),
  withFocusReceiver('assetValidation.notes', 'onNotesFocusRequested'),
  withState(
    'notesContainsCreditCardNumber',
    'setContainsCreditCardNumber',
    false,
  ),
  withState('ignoreCreditCardNumber', 'setIgnoreCreditCardNumber', false),
)(React.memo(CaseAssetValidationPanel));
