import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';

import { Container, Row, Column } from 'styled-components-grid';
import { Checkbox } from 'base-components';

const FilterByAssets = ({ setFilterbyAssets, shouldFilterByAssets }) => {
  const toggleFilter = () => setFilterbyAssets(!shouldFilterByAssets);
  return (
    <Container>
      <Row>
        <Column modifiers={['col', 'padScale_0']}>
          <Checkbox
            id="filterTirePreferences"
            name="filterTirePreferences"
            label={t`Filter by Assets Unit Type & Attribute`}
            checked={shouldFilterByAssets}
            onChange={toggleFilter}
          />
        </Column>
      </Row>
    </Container>
  );
};

FilterByAssets.propTypes = {
  shouldFilterByAssets: PropTypes.bool,
  setFilterbyAssets: PropTypes.func,
};

export default FilterByAssets;
