/* eslint-disable import/prefer-default-export */
import { defineMessage } from '@lingui/macro';

import { CASE_STATUS } from 'compositions/CaseStatus/constants';

const statusChanged = defineMessage({ message: 'changed the status to' });

export const messageForStatus = {
  [CASE_STATUS.new]: defineMessage({ message: 'created case {caseNumber}' }),
  [CASE_STATUS.dispatched]: statusChanged,
  [CASE_STATUS.dispatch]: statusChanged,
  [CASE_STATUS.enRoute]: statusChanged,
  [CASE_STATUS.arrived]: statusChanged,
  [CASE_STATUS.rolling]: statusChanged,
  [CASE_STATUS.closed]: statusChanged,
  [CASE_STATUS.canceled]: statusChanged,
  [CASE_STATUS.dry_run_canceled]: statusChanged,
};
