import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { noop, omit } from 'lodash';
import { Form, Field } from 'formik';
import { compose, setDisplayName } from 'recompose';

import { Column, Row } from 'styled-components-grid';
import { Dropdown, InputField, InputGroup, Text } from 'base-components';

import PhoneNumberInput from 'elements/PhoneNumberInput';
import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';
import withPatternValidationOnKeyUp from 'utils/withPatternValidationOnKeyUp';
import { invalidCharsForExporting } from 'constants';
import { CaseShortcut, CASE_SHORTCUT_PANELS } from 'features/keyShortcuts';

import Error from './Error';
import ContactCallback from './ContactCallback';
import ContactCallButton from './ContactCallButton';
import ContactDeleteButton from './ContactDeleteButton';

import { getContactErrors } from '../utils';
import { contactFields, fieldIds } from './constants';

const StyledForm = styled(Form)`
  margin-bottom: 10px;
  padding-bottom: 10px;

  &::after {
    left: -12px;
    right: -12px;
    height: 1px;
    bottom: 10px;
    content: '';
    position: absolute;
    background: #e3e7ee;
  }
`;

export function renderContactTypes(contactTypes) {
  return contactTypes.map((contactType) => (
    <Dropdown.ListItem
      key={contactType.option}
      id={contactType.option}
      type="button"
    >
      <Text name="contactType" value={contactType.option}>
        {contactType.text}
      </Text>
    </Dropdown.ListItem>
  ));
}

export function selectContactType(selected, contactTypes) {
  const selectedType = contactTypes.find((type) => selected === type.option);

  return selectedType ? selectedType.text : '';
}

const isTollFree = (phoneNumber, phoneExt) => {
  const tollFreeRegex = /^(\+1)?(800|888|877|866|855|844|833|822|811|800)/;
  const isTollFreeNumber = tollFreeRegex.test(phoneNumber);
  return isTollFreeNumber || phoneExt?.length > 0;
};

export class ContactDetailsForm extends Component {
  static propTypes = {
    caseId: PropTypes.string.isRequired,
    contactId: PropTypes.string,
    index: PropTypes.number.isRequired,
    contactTypes: PropTypes.arrayOf(
      PropTypes.shape({
        default: PropTypes.bool,
        option: PropTypes.string,
        text: PropTypes.string,
      }),
    ).isRequired,
    deletable: PropTypes.bool.isRequired,
    errors: PropTypes.shape({
      [contactFields.phone]: PropTypes.string,
      [contactFields.email]: PropTypes.string,
    }).isRequired,
    formId: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isReadOnlyCase: PropTypes.bool.isRequired,
    onDelete: PropTypes.func,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    touched: PropTypes.shape({
      [contactFields.phone]: PropTypes.bool,
      [contactFields.email]: PropTypes.bool,
    }).isRequired,
    values: PropTypes.shape({
      [contactFields.callbackEta]: PropTypes.bool,
      [contactFields.callbackRollTime]: PropTypes.bool,
      [contactFields.contactType]: PropTypes.string,
      [contactFields.email]: PropTypes.string,
      [contactFields.name]: PropTypes.string,
      [contactFields.phone]: PropTypes.string,
      [contactFields.phoneExt]: PropTypes.string,
    }).isRequired,

    // from withPatternValidationOnKeyUp
    hasInvalidPattern: PropTypes.func,
    onValidatingFieldBlur: PropTypes.func,
    onValidatingFieldKeyUp: PropTypes.func,
    showValidationError: PropTypes.func,

    nameFocusHandler: PropTypes.func.isRequired,
    phoneFocusHandler: PropTypes.func.isRequired,
    emailFocusHandler: PropTypes.func.isRequired,
    phoneExtFocusHandler: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onDelete: noop,
    hasInvalidPattern: noop,
    onValidatingFieldBlur: noop,
    onValidatingFieldKeyUp: noop,
    showValidationError: noop,
  };

  static getDerivedStateFromProps(props, state) {
    const newName = props?.values?.[contactFields.name] || '';

    return newName !== state.contactName ? { contactName: newName } : null;
  }

  state = { contactName: this.props?.values?.[contactFields.name] || '' };

  onContactTypeChange = (event, itemId) => {
    event.preventDefault();
    if (itemId) {
      this.props.handleChange(contactFields.contactType)(itemId);
      this.props.handleSubmit(this.props.values);
    }
  };

  onEtaChange = (event) => {
    this.props.handleChange(contactFields.callbackEta)(event);
    this.props.handleSubmit(this.props.values);
  };

  onRollbackChange = (event) => {
    this.props.handleChange(contactFields.callbackRollTime)(event);
    this.props.handleSubmit(this.props.values);
  };

  handleResetNameClick = (event) => {
    event.stopPropagation();
    this.setState({ contactName: '' });
    this.props.handleChange(contactFields.name)('');
    this.props.handleSubmit(this.props.values);
  };

  handleNameChange = ({ target: { value } }, showValidationError) => {
    const { handleChange, hasInvalidPattern } = this.props;

    this.setState({ contactName: value });

    if (
      !value ||
      showValidationError ||
      !hasInvalidPattern(contactFields.name, value)
    ) {
      handleChange(contactFields.name)(value);
    }
  };

  handleNameBlur = (event) => {
    const { onValidatingFieldBlur, setFieldTouched } = this.props;
    onValidatingFieldBlur(contactFields.name);
    this.handleNameChange(event, true);
    setFieldTouched(contactFields.name);
  };

  handleNameKeyUp = (event) => {
    const { onValidatingFieldKeyUp } = this.props;
    const { showValidationError } = onValidatingFieldKeyUp(
      contactFields.name,
      event,
    );

    if (showValidationError) {
      this.handleNameChange(event, true);
    }
  };

  bindFocusHandler = (focusHandler) => (el) => focusHandler(el);

  render() {
    const {
      caseId,
      contactId,
      index,
      contactTypes,
      deletable,
      errors: allErrors,
      isReadOnlyCase,
      onDelete,
      setFieldValue,
      setFieldTouched,
      touched = {},
      values,
      showValidationError,
      hasInvalidPattern,
      nameFocusHandler,
      phoneFocusHandler,
      emailFocusHandler,
      phoneExtFocusHandler,
    } = this.props;

    const { contactName } = this.state;

    const isContactNameValid =
      touched[contactFields.name] &&
      !hasInvalidPattern(contactFields.name, contactName);

    const errors =
      showValidationError(contactFields.name) && !isContactNameValid
        ? getContactErrors({ ...values, [contactFields.name]: contactName })
        : omit(allErrors, [contactFields.name]);

    const phoneNumber = values[contactFields.phone];
    const enableCallButton = !!phoneNumber && !errors[contactFields.phone];
    const isPhoneNumberValid =
      touched[contactFields.phone] && !errors[contactFields.phone];

    const hasValidPhoneNumber =
      !!contactFields.phone && !errors[contactFields.phone];

    const phoneExt = values[contactFields.phoneExt];
    const callbackUnavailable = isTollFree(phoneNumber, phoneExt);

    return (
      <StyledForm>
        <InputGroup>
          <InputGroup.Row>
            <InputGroup.Column modifiers={['col']}>
              <Field name={contactFields.name}>
                {({ field }) => (
                  <InputField
                    {...field}
                    maxLength={50}
                    placeholder={t`Enter name...`}
                    readOnly={isReadOnlyCase}
                    value={contactName}
                    isValid={
                      !showValidationError(contactFields.name) ||
                      isContactNameValid
                    }
                    onChange={this.handleNameChange}
                    onBlur={this.handleNameBlur}
                  >
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Row>
                        <InputField.Label>
                          <Trans>Name</Trans>
                        </InputField.Label>
                      </Row>
                      <Row>
                        <CaseShortcut
                          action={{
                            parent: CASE_SHORTCUT_PANELS.contact,
                            id: `contactAction-${index}`,
                            name: t`Contact ${index + 1}`,
                            shortcut: ['c', `${index + 1}`],
                            priority: 2,
                          }}
                        >
                          {({ onFocusRequested }) => (
                            <InputField.TextField
                              autoComplete="off"
                              onKeyUp={this.handleNameKeyUp}
                              ref={(ref) => {
                                this.bindFocusHandler(nameFocusHandler)(ref);
                                onFocusRequested(ref);
                              }}
                            />
                          )}
                        </CaseShortcut>
                        {field.value && (
                          <InputField.ActionButton
                            icon="times"
                            type="button"
                            onClick={this.handleResetNameClick}
                            modifiers={['padScaleX_0', 'hoverDanger']}
                          />
                        )}
                      </Row>
                    </Column>
                  </InputField>
                )}
              </Field>
            </InputGroup.Column>
          </InputGroup.Row>
          <InputGroup.Row>
            <InputGroup.Column
              style={{ zIndex: isPhoneNumberValid ? 'auto' : 1 }}
              modifiers={['col']}
            >
              <Field name={contactFields.phone}>
                {({ field }) => (
                  <InputField
                    {...field}
                    readOnly={isReadOnlyCase}
                    isValid={isPhoneNumberValid}
                  >
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Row>
                        <InputField.Label>
                          <Trans>Phone Number</Trans>
                        </InputField.Label>
                      </Row>
                      <Row>
                        <Column modifiers={['col', 'padScaleY_0']}>
                          <PhoneNumberInput
                            onBlur={() => setFieldTouched(contactFields.phone)}
                            onChange={(value) => {
                              setFieldTouched(contactFields.phone);
                              setFieldValue(field.name, value);
                            }}
                            placeholder={t`(###) ###-####`}
                            readOnly={isReadOnlyCase}
                            value={field.value}
                            innerRef={this.bindFocusHandler(phoneFocusHandler)}
                          />
                        </Column>
                      </Row>
                    </Column>
                  </InputField>
                )}
              </Field>
            </InputGroup.Column>
            <InputGroup.Column modifiers={['col']} style={{ maxWidth: 110 }}>
              <Field name={contactFields.phoneExt}>
                {({ field }) => (
                  <InputField
                    {...field}
                    isValid={
                      touched[contactFields.phoneExt] &&
                      !errors[contactFields.phoneExt]
                    }
                    maxLength={10}
                    placeholder={t`###`}
                    readOnly={isReadOnlyCase}
                  >
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Row>
                        <InputField.Label>
                          <Trans>Ext. (Optional)</Trans>
                        </InputField.Label>
                      </Row>
                      <Row>
                        <InputField.TextField
                          ref={this.bindFocusHandler(phoneExtFocusHandler)}
                          onBlur={() => setFieldTouched(contactFields.phoneExt)}
                          onChange={(e) => {
                            setFieldTouched(contactFields.phoneExt);
                            setFieldValue(field.name, e.target.value);
                          }}
                        />
                      </Row>
                    </Column>
                  </InputField>
                )}
              </Field>
            </InputGroup.Column>
          </InputGroup.Row>
          <InputGroup.Row>
            <InputGroup.Column modifiers={['col']}>
              <Field name={contactFields.email}>
                {({ field }) => (
                  <InputField
                    {...field}
                    isValid={
                      touched[contactFields.email] &&
                      !errors[contactFields.email]
                    }
                    maxLength={255}
                    placeholder={t`Enter email address...`}
                    readOnly={isReadOnlyCase}
                  >
                    <Column modifiers={['col', 'padScaleY_0']}>
                      <Row>
                        <InputField.Label>
                          <Trans>Email (Optional)</Trans>
                        </InputField.Label>
                      </Row>
                      <Row>
                        <InputField.TextField
                          ref={this.bindFocusHandler(emailFocusHandler)}
                          onBlur={() => setFieldTouched(contactFields.email)}
                          onChange={(e) => {
                            setFieldTouched(contactFields.email);
                            setFieldValue(field.name, e.target.value);
                          }}
                        />
                      </Row>
                    </Column>
                  </InputField>
                )}
              </Field>
            </InputGroup.Column>
            <InputGroup.Column modifiers={['col']} style={{ maxWidth: 110 }}>
              <Dropdown
                fullWidth
                hideOnChange
                onChange={this.onContactTypeChange}
                readOnly={isReadOnlyCase}
              >
                {({ isVisible }) => (
                  <>
                    <Dropdown.Target style={{ zIndex: isVisible ? 2 : 'auto' }}>
                      <InputField
                        name={contactFields.contactType}
                        onChange={noop}
                        readOnly={isReadOnlyCase}
                        value={selectContactType(
                          values[contactFields.contactType],
                          contactTypes,
                        )}
                      >
                        <Column modifiers={['col', 'padScaleY_0']}>
                          <Row>
                            <InputField.Label>
                              <Trans>Contact Type</Trans>
                            </InputField.Label>
                          </Row>
                          <Row>
                            <InputField.TextField
                              autoComplete="off"
                              style={{
                                caretColor: 'transparent',
                                cursor: 'pointer',
                              }}
                            />
                            <InputField.ActionButton
                              icon={isVisible ? 'chevron-up' : 'chevron-down'}
                              modifiers={['hoverInfo']}
                              onClick={noop}
                              type="button"
                            />
                            <Dropdown.Content>
                              <Dropdown.List>
                                {renderContactTypes(contactTypes)}
                              </Dropdown.List>
                            </Dropdown.Content>
                          </Row>
                        </Column>
                      </InputField>
                    </Dropdown.Target>
                  </>
                )}
              </Dropdown>
            </InputGroup.Column>
          </InputGroup.Row>

          <Error errors={errors} />

          <Row modifiers="middle" style={{ marginTop: 5 }}>
            <Column modifiers="fluid">
              <ContactCallback
                type="ETA"
                name={contactFields.callbackEta}
                caseId={caseId}
                onToggle={this.onEtaChange}
                isChecked={values[contactFields.callbackEta]}
                contactId={contactId}
                isReadOnly={isReadOnlyCase}
                contactName={values[contactFields.name]}
                hasValidPhoneNumber={hasValidPhoneNumber}
                isUnavailable={callbackUnavailable}
              />
            </Column>
            <Column modifiers={['end', 'fluid', 'display_flex', 'col']}>
              <ContactCallButton
                enableCallButton={enableCallButton}
                name={contactName}
                phone={phoneNumber}
              />
            </Column>

            {isReadOnlyCase || (
              <Column modifiers={['end', 'display_flex']}>
                <ContactDeleteButton
                  deletable={deletable}
                  onDelete={onDelete}
                />
              </Column>
            )}
          </Row>
          <Row>
            <Column modifiers={['fluid', 'padScaleY_0']}>
              <ContactCallback
                type="ROLL_TIME"
                name={contactFields.callbackRollTime}
                caseId={caseId}
                onToggle={this.onRollbackChange}
                isChecked={values[contactFields.callbackRollTime]}
                contactId={contactId}
                isReadOnly={isReadOnlyCase}
                contactName={values[contactFields.name]}
                hasValidPhoneNumber={hasValidPhoneNumber}
                isUnavailable={callbackUnavailable}
              />
            </Column>
          </Row>
        </InputGroup>
      </StyledForm>
    );
  }
}

const buildFocusReceiver = (field, prop) =>
  withFocusReceiver((p) => field.replace('{index}', p.index), prop);

export default compose(
  setDisplayName('ContactDetailsForm'),
  withPatternValidationOnKeyUp({ fields: { name: invalidCharsForExporting } }),
  buildFocusReceiver(fieldIds.name, 'nameFocusHandler'),
  buildFocusReceiver(fieldIds.phone, 'phoneFocusHandler'),
  buildFocusReceiver(fieldIds.email, 'emailFocusHandler'),
  buildFocusReceiver(fieldIds.phoneExt, 'phoneExtFocusHandler'),
)(ContactDetailsForm);
