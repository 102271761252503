import React from 'react';
import PropTypes from 'prop-types';

import { px2rem } from 'decisiv-ui-utils';
import { Dropdown, Text } from 'base-components';

import HighlightText from 'components/HighlightText';

import { unitAttributeTypes } from '../unitAttributeNames';

import UnitAttributesDropdown from './UnitAttributesDropdown';

function UnitTypesDropdownList(props) {
  const { types, getItemValue, highlightText, indentList } = props;
  const renderItem = (type) => (
    <Text style={indentList ? { textIndent: px2rem(10) } : {}}>
      <HighlightText text={highlightText}>{getItemValue(type)}</HighlightText>
    </Text>
  );
  return (
    <Dropdown.List>
      {types.map((type) =>
        Object.values(unitAttributeTypes).includes(type) ? (
          <UnitAttributesDropdown.Trigger key={type} id={type}>
            {renderItem(type)}
          </UnitAttributesDropdown.Trigger>
        ) : (
          <Dropdown.ListItem key={type} id={type}>
            {renderItem(type)}
          </Dropdown.ListItem>
        ),
      )}
    </Dropdown.List>
  );
}

UnitTypesDropdownList.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
  getItemValue: PropTypes.func.isRequired,
  highlightText: PropTypes.string,
  indentList: PropTypes.bool,
};

export default UnitTypesDropdownList;
