import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';

import { AmazonConnectProvider } from 'features/amazonConnect';

import withContext from 'utils/withContext';
import { CaseStatusContext } from 'compositions/CaseStatus';

import withCallHistoryActions from './withCallHistoryActions';
import { caseCallLinkHelper } from './helpers';

/**
 * Catch-all to link ALL outbound calls to the current case. This
 * will setup a case/call link with basically just the Amazon Connect
 * contact ID and the case ID. If we actually know more about the
 * call (e.g. that it is to a specific dealer or dealer contact),
 * then a subsequent case/call link mutation will also be triggered
 * by whatever component has that information.
 *
 * There's no harm (other than wasted bandwidth) firing multiple case/call
 * link mutations for the same case/call pair. The backend just updates
 * the existing link when new information is sent.
 *
 * @see CallAndLinkToCase
 * @see AutoLinkNewCaseToCall
 */
class AutoLinkDialedCallsToCase extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,

    // from CaseStatusContext:
    caseId: PropTypes.string,

    // from withCallHistoryActions:
    linkCaseToCall: PropTypes.func.isRequired,

    // from AmazonConnect:
    addContactConnectingListener: PropTypes.func.isRequired,
  };

  static defaultProps = {
    caseId: null,
  };

  componentDidMount() {
    this.listenForOutgoingCalls();
  }

  componentWillUnmount() {
    if (this.removeListener) {
      this.removeListener();
      this.removeListener = null;
    }
  }

  /**
   * Event handler called by AmazonConnect when a call connects. If the call
   * is _our_ call, then we link it to the current case.
   */
  onContactConnecting = (data, next, meta) => {
    // we want to run last, so that we can check if the call has already been linked
    next();

    if (meta.callLinkedToCase) {
      // eslint-disable-next-line no-console
      console.log(
        '[AutoLinkDialedCallsToCase] Call has already been linked to case.',
      );

      return;
    }

    // eslint-disable-next-line no-console
    console.log('[AutoLinkDialedCallsToCase] linking call with data', data);

    if (this.shouldAutoLinkCall(data)) {
      const { caseId, linkCaseToCall } = this.props;
      const { contact, contactNumber, stateStartTime } = data;

      caseCallLinkHelper({
        contact,
        contactNumber,
        stateStartTime,
        currentCaseId: caseId,
        linkCaseToCall,
        destinationNumber: contactNumber,
      });
    }
  };

  shouldAutoLinkCall = ({ contact }) => {
    const { caseId } = this.props;
    return caseId && contact && !contact?.isInbound?.();
  };

  listenForOutgoingCalls = () => {
    this.removeListener = this.props.addContactConnectingListener(
      this.onContactConnecting,
    );
  };

  render() {
    return this.props.children;
  }
}

export default compose(
  setDisplayName('AutoLinkDialedCallsToCase'),
  withContext(CaseStatusContext),
  withCallHistoryActions,
  withContext(AmazonConnectProvider),
)(AutoLinkDialedCallsToCase);
