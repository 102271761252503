import PropTypes from 'prop-types';

export const defaultAsset = {
  assetType: null,
  droppedUnit: false,
  unitNumber: '',
  trailerType: null,
  readOnlyStatus: 'WRITABLE',
};

export const FIELD_NAMES = {
  ASSET_TYPE: 'assetType',
  TRAILER_TYPE: 'trailerType',
  DROPPED_UNIT: 'droppedUnit',
  UNIT_NUMBER: 'unitNumber',
  EXTERNAL_ATTRIBUTES: 'externalAttributes',
};

const { ASSET_TYPE, DROPPED_UNIT, UNIT_NUMBER } = FIELD_NAMES;

export const assetPropType = PropTypes.shape({
  [ASSET_TYPE]: PropTypes.string,
  [DROPPED_UNIT]: PropTypes.bool,
  [UNIT_NUMBER]: PropTypes.string,
});
