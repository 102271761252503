import React from 'react';
import PropTypes from 'prop-types';
import { compose, setDisplayName } from 'recompose';
import { includes } from 'lodash';

import withContext from 'utils/withContext';

import BaseCaseCancelButton from 'compositions/CaseCancelButton';

import CaseStatusContext from '../CaseStatusContext';
import { CASE_STATUS } from '../constants';

export function CaseCancelButton({ caseNumber, caseId, status, textWhite }) {
  return includes(
    [
      CASE_STATUS.canceled,
      CASE_STATUS.closed_canceled,
      CASE_STATUS.dry_run_canceled,
    ],
    status,
  ) ? null : (
    <BaseCaseCancelButton
      caseId={caseId}
      caseNumber={caseNumber}
      textWhite={textWhite}
    />
  );
}

CaseCancelButton.propTypes = {
  caseId: PropTypes.string,
  status: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,
  textWhite: PropTypes.bool.isRequired,
};

CaseCancelButton.defaultProps = {
  caseId: undefined,
};

export default compose(
  setDisplayName('CaseCancelButton'),
  withContext(CaseStatusContext),
)(CaseCancelButton);
