import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';

import { Checkbox } from 'base-components';
import { px2rem } from 'decisiv-ui-utils';

import CallbackState from './CallbackState';

const labelForType = {
  ETA: t`Call with ETA`,
  ROLL_TIME: t`Call with Roll Time`,
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

function ContactCallback(props) {
  const { contactName, hasValidPhoneNumber, isUnavailable } = props;
  const { name, type, isChecked, isReadOnly, onToggle, contactId } = props;

  const showCallbackState =
    !!contactName && hasValidPhoneNumber && (isChecked || isUnavailable);

  return (
    <Container>
      <Checkbox
        name={name}
        label={
          <span style={isUnavailable ? { fontSize: px2rem(9) } : {}}>
            <Trans id={labelForType[type]} />
          </span>
        }
        checked={isChecked}
        onChange={onToggle}
        readOnly={isReadOnly}
      />
      {showCallbackState && (
        <CallbackState
          type={type}
          contactId={contactId}
          isUnavailable={isUnavailable}
        />
      )}
    </Container>
  );
}

ContactCallback.propTypes = {
  type: PropTypes.oneOf(['ETA', 'ROLL_TIME']).isRequired,
  name: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  contactId: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  contactName: PropTypes.string,
  hasValidPhoneNumber: PropTypes.bool,
  isUnavailable: PropTypes.bool,
};

export default ContactCallback;
