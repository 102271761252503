import React from 'react';
import { Trans } from '@lingui/macro';

import { MessageMedium } from 'base-components';
import { Container } from 'styled-components-grid';

function ErrorMessage() {
  return (
    <Container style={{ whiteSpace: 'normal' }} className="no-results">
      <MessageMedium>
        <MessageMedium.Header>
          <MessageMedium.Icon name="search" />
        </MessageMedium.Header>
        <MessageMedium.Section>
          <MessageMedium.Title>
            <Trans>No Matches Found</Trans>
          </MessageMedium.Title>
        </MessageMedium.Section>
        <MessageMedium.Section>
          <MessageMedium.Text>
            <Trans>
              Try removing or editing some of your criteria or search for
              different words
            </Trans>
          </MessageMedium.Text>
        </MessageMedium.Section>
      </MessageMedium>
    </Container>
  );
}

export default ErrorMessage;
