import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { camelCase, get, includes, uniqueId } from 'lodash';
import { compose, setDisplayName, onlyUpdateForKeys } from 'recompose';

import { DataTable, Text, GhostIndicator } from 'base-components';
import { Container, Row, Column } from 'styled-components-grid';

import {
  cellIsUnlocked,
  getRowModifiers,
  shouldRowUpdate,
  getDropdownOptions,
  getFirstUnmetDependencyIndexForCell,
} from './utils';

import DropdownCell from './DropdownCell';
import TableContainer from './TableContainer';
import columnsConfigBuilder from './columnsConfigBuilder';
import TireOptionsDropdownCell from './TireOptionsDropdownCell';
import UnitOptionsDropdownCell from './UnitOptionsDropdownCell';
import RequiredDependencyMessage from './RequiredDependencyMessage';
import { getDropdownProps } from '../utils';

const placeholderRequestLines = [{ id: uniqueId() }];

/**
 * Renders a table with one column for each item in `columns.js`.
 *
 * Each column is setup with a dependencies array which sets the fields
 * the user must first select for the dependant cell to be "unlocked".
 *
 * Each data row has a dynamic number of cells depending on whether
 * or not the user has selected values for the fields a cell depends on.
 * The first cell with unmet dependencies will have a colSpan attribute
 * with the number of cells that depend on the field it represents + 1.
 *
 * The options provided for each dropdown can either be constant, and in
 * that case are loaded from the backend just once, or are dependant on
 * values of other dropdowns and are loaded whenever those change.
 */
export class RequestLinesTable extends Component {
  static propTypes = {
    caseNumber: PropTypes.string.isRequired,
    requestLines: PropTypes.arrayOf(PropTypes.object).isRequired,
    isReadOnlyCase: PropTypes.bool,
    updateRequestLine: PropTypes.func.isRequired,
    requestLineOptions: PropTypes.shape({}).isRequired,
    isLoadingRequestLineOptions: PropTypes.bool.isRequired,
    genericTireOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    dropdownPropsGetter: PropTypes.func,
    shouldHeadUpdate: PropTypes.func,
    resetTireOptionsOfTypes: PropTypes.func.isRequired,
    allowGenericTireOptionsSelection: PropTypes.bool,
    rowsType: PropTypes.oneOf(['requested', 'agreed', 'supplied']),
  };

  static defaultProps = {
    isReadOnlyCase: false,
    shouldHeadUpdate: undefined,
    dropdownPropsGetter: getDropdownProps,
    allowGenericTireOptionsSelection: true,
    rowsType: 'requested',
  };

  constructor(props) {
    super(props);

    this.state = {
      columns: this.buildColumns(props),
      metadata: { rowsType: props.rowsType },
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { isReadOnlyCase, genericTireOptions } = newProps;
    let patch = {};

    if (
      this.props.isReadOnlyCase !== isReadOnlyCase ||
      this.props.genericTireOptions.join('') !== genericTireOptions.join('')
    ) {
      patch.metadata = {
        ...this.state.metadata,
        isReadOnlyCase,
        genericTireOptions,
      };
    }

    if (this.props.requestLineOptions !== newProps.requestLineOptions) {
      patch.columns = this.buildColumns(newProps);
    }

    if (Object.keys(patch).length) this.setState(patch);
  }

  getCellConfig = (params) => {
    const { columns } = this.state;
    const { rowData = {}, colIndex } = params;
    const { requestLines, isReadOnlyCase, rowsType } = this.props;
    const { allowGenericTireOptionsSelection: allowGeneric } = this.props;

    const columnConfig = columns[colIndex];
    const nonPreferred = (rowData.nonPreferredSelections || []).map(camelCase);

    const data = {
      ...params,
      name: columnConfig.name,
      columns,
      rowType: rowsType,
      columnConfig,
      isReadOnlyCase,
      totalRequestLines: requestLines.length,
      genericTireOptions: this.props.genericTireOptions,
      isNonPreferredSelection: includes(nonPreferred, columnConfig.name),
    };

    const dropdownProps = this.props.dropdownPropsGetter(data, allowGeneric);

    return { ...data, ...dropdownProps, dropdownProps };
  };

  // DOM attributes to set on the table cell
  getCellAttrs = (params) => {
    const config = this.getCellConfig(params);

    const { depsMessageColSpan, rowData } = config;

    const attrs =
      rowData.readOnly || cellIsUnlocked(config)
        ? {}
        : { colSpan: depsMessageColSpan || 1 };

    return { 'data-row-id': rowData.id, ...attrs };
  };

  buildColumns = (props) => {
    const columnsConfig = [
      ...columnsConfigBuilder(props),
      ...(props.additionalColumns || []),
    ];

    return columnsConfig.map((config) => {
      const { name, ...rest } = config;

      return {
        ...rest,
        name,
        cellKeyGetter: () => name,
        cellDataGetter: (data) => data[name],
        headerCellRenderer: this.renderHeaderCell,
        dataCellRenderer: this.renderCell,
        dataCellAttrsGetter: this.getCellAttrs,
      };
    });
  };

  handleValueChange = (params, newValue, autoSelectingSingleOption = false) => {
    const { name, rowData } = params;
    const { id: rowId } = rowData;

    if (rowData[name] === newValue) return;

    const { resetTireOptionsOfTypes, updateRequestLine } = this.props;

    // If we are auto selecting the only available option, then we do not
    // clear the dependent dropdowns, to prevent an infinite loop caused
    // by dependent dropdowns also potentially auto selecting their single
    // options, and their mutations finishing out of order, re-triggering
    // the auto selection process.
    let dependentKeys = [];

    if (!autoSelectingSingleOption) {
      dependentKeys = this.state.columns.reduce((acc, col, colIndex) => {
        const cellValue = rowData[col.name];
        const config = this.getCellConfig({ ...params, colIndex, cellValue });

        return (config.dependsOn || []).includes(name)
          ? [...acc, col.name]
          : acc;
      }, []);
    }

    /**
     * Here we update the changed field's value, as well as any field that
     * depends on it, in order to display the "unmet dependencies" message.
     */
    const dependentValues = dependentKeys.reduce(
      (acc, key) => ({ ...acc, [key]: null }),
      {},
    );

    updateRequestLine(rowId, { [name]: newValue, ...dependentValues });

    /**
     * Clear the tire options for those fields that depend on
     * the changed field, so that they can get fresh options.
     */
    if (dependentKeys.length) resetTireOptionsOfTypes(dependentKeys, rowId);
  };

  renderHeaderCell = (params) => {
    const { title, headerRenderer } = params;
    const { isReadOnlyCase, requestLines } = this.props;

    const totalRequestLines = requestLines.length;
    const callbacksParams = { ...params, isReadOnlyCase, totalRequestLines };

    if (headerRenderer) return headerRenderer(callbacksParams);

    return (
      <Container
        modifiers={['padScaleX_2', 'padScaleY_3', 'height_100', 'flex_column']}
      >
        <Text modifiers={['small', 'textLight']}>
          <Trans id={title} />
        </Text>
      </Container>
    );
  };

  renderCellPlaceholder = () => (
    <Row>
      <Column modifiers="col">
        <GhostIndicator />
      </Column>
    </Row>
  );

  /**
   * Renders the content of the cell, not the `td` tag itself.
   */
  renderCell = (cellValue, data) => {
    const params = this.getCellConfig({ ...data, cellValue });

    const { caseNumber, rowsType } = this.props;
    const { isLoadingRequestLineOptions: isLoading } = this.props;
    const { columnConfig, rowData = {}, dependsOn, dropdownProps } = params;

    const { name, title, renderer, autocomplete } = columnConfig;
    const { depsMessage, showDepsMessageInToolTip } = columnConfig;

    const hasDeps = Array.isArray(dependsOn);

    /**
     * We return `null` if this cell is not the first with unmet dependencies.
     * If that is the case, then a cell has already been rendered with a
     * colSpan that will stretch it to fill this cell's space as well as other
     * subsequent cells that also have unmet dependencies.
     *
     * Returning `null` means DataTable will not render the wrapping `td` tag.
     */
    if (hasDeps && !rowData.readOnly) {
      const index = getFirstUnmetDependencyIndexForCell(params);

      // Special case for Axle Type and Tire Position that depend
      // on the Unit being defined, and because they are not rendered
      // right after the Unit column.
      if (index === 0 && name === 'tirePosition') return null;

      // This works for the other inter-dependent columns because
      // they are all rendered side-by-side.
      if (index > 0 && index + 1 < params.colIndex) return null;
    }

    // If true, it means the row is new and is still being saved,
    // and until we have the final ID, the row can't be updated.
    const isTempRow = get(rowData, 'id', '').startsWith('temp_');

    if (isLoading || isTempRow) return this.renderCellPlaceholder();

    /**
     * If this cell is the first with unmet dependencies we render a message
     * stating which field first needs to be selected. The message spans the
     * whole width of the cell that will get an adequate colSpan attribute
     * returned by `this.getCellAttrs`.
     */
    if (!rowData.readOnly && !cellIsUnlocked(params)) {
      return (
        <RequiredDependencyMessage
          message={depsMessage}
          tooltip={!!showDepsMessageInToolTip}
        />
      );
    }

    if (renderer) return renderer(params);

    // This dropdown requires loading data from the case.
    if (name === 'asset') {
      return (
        <UnitOptionsDropdownCell
          name={name}
          value={cellValue}
          rowData={rowData}
          rowType={rowsType}
          onChange={(val) => this.handleValueChange(params, val)}
          readOnly={rowData.readOnly}
          caseNumber={caseNumber}
          autocomplete={autocomplete}
          title={title}
          {...dropdownProps}
        />
      );
    }

    /**
     * If this dropdown has dependencies, we offload the responsibility
     * and let it load its own options based on its dependencies values.
     */
    if (hasDeps) {
      return (
        <TireOptionsDropdownCell
          name={name}
          value={cellValue}
          rowData={rowData}
          rowType={rowsType}
          onChange={(...args) => this.handleValueChange(params, ...args)}
          readOnly={rowData.readOnly}
          dependsOn={dependsOn}
          autocomplete={autocomplete}
          columnsConfig={this.state.columns}
          isNonPreferredSelection={params.isNonPreferredSelection}
          title={title}
          {...dropdownProps}
        />
      );
    }

    // Otherwise, we render the dropdown for the field.
    return this.renderDropdown(params);
  };

  renderDropdown = (params) => {
    const { rowsType } = this.props;
    const { name, columnConfig, cellValue, dropdownProps } = params;
    const { rowData = {}, isNonPreferredSelection, dependsOn } = params;

    const props = {
      name,
      value: cellValue,
      rowData,
      rowType: rowsType,
      options: getDropdownOptions(params),
      onReset: () => this.handleValueChange(params, null),
      onChange: (newValue) => this.handleValueChange(params, newValue),
      readOnly: rowData.readOnly,
      optional: columnConfig.optional,
      dependsOn,
      autocomplete: columnConfig.autocomplete,
      autoSelectSingleOption: true,
      isNonPreferredSelection,
      title: columnConfig.title,
      ...dropdownProps,
    };

    return <DropdownCell {...props} />;
  };

  render() {
    const { columns } = this.state;
    const { requestLines, shouldHeadUpdate } = this.props;
    const { isLoadingRequestLineOptions: isLoading } = this.props;

    const showPlaceholder = isLoading || !requestLines.length;

    return (
      <TableContainer>
        <DataTable
          key={columns.length}
          scrollX
          columns={columns}
          modifiers=""
          tableData={showPlaceholder ? placeholderRequestLines : requestLines}
          tableMetaData={this.state.metadata}
          shouldRowUpdate={shouldRowUpdate}
          shouldHeadUpdate={shouldHeadUpdate}
          rowModifiersGetter={getRowModifiers}
          rowsRequireRowIndex={false}
        />
      </TableContainer>
    );
  }
}

export default compose(
  setDisplayName('RequestLinesTable'),
  onlyUpdateForKeys([
    'rowsType',
    'requestLines',
    'isReadOnlyCase',
    'isLoadingAssets',
    'requestLineOptions',
    'genericTireOptions',
    'isLoadingRequestLines',
    'isLoadingRequestLineOptions',
  ]),
)(RequestLinesTable);
