import React from 'react';
import { curry } from 'lodash';
import { Mutation } from '@apollo/client/react/components';

import { NAME as CASE_DELAYS_QUERY_NAME } from 'compositions/CaseDetailOverviewPanel/withCaseDelays/caseDelaysQuery';
import { NAME as CASE_NOTES_QUERY_NAME } from 'compositions/CaseActivityPanel/Notes/withCaseNotes/caseNotesQuery';
import { NAME as CASE_UNIFIED_HISTORY_QUERY_NAME } from 'compositions/CaseActivityPanel/UnifiedHistory/withCaseUnifiedHistory/caseUnifiedHistoryQuery';

import cancelCaseMutation from './cancelCaseMutation';

const buildCreateMutationChild = curry(
  (WrappedComponent, componentProps, cancelCase) => (
    <WrappedComponent
      {...componentProps}
      cancelCase={(data) => {
        cancelCase({
          variables: {
            caseId: componentProps.caseId,
            ...data,
          },
        });
      }}
    />
  ),
);

const withCancelCase = (WrappedComponent) => (componentProps) => (
  <Mutation
    skip={!componentProps.caseId}
    mutation={cancelCaseMutation}
    refetchQueries={[
      CASE_DELAYS_QUERY_NAME,
      CASE_NOTES_QUERY_NAME,
      CASE_UNIFIED_HISTORY_QUERY_NAME,
    ]}
  >
    {buildCreateMutationChild(WrappedComponent, componentProps)}
  </Mutation>
);
export default withCancelCase;
