import { invert } from 'lodash';

import { CASE_STATUS } from 'compositions/CaseStatus';
import { COLUMN_NAMES } from 'compositions/CasesDataTable/constants';

// CasesSearch only understands column names so we need to map
// them to and from the pretty query string params we want to use.
export const columnNameToSortByParam = {
  [COLUMN_NAMES.status]: 'status',
  [COLUMN_NAMES.timeSummaries]: 'start-time',
  [COLUMN_NAMES.inboundProgram]: 'inbound-program',
  [COLUMN_NAMES.caseNumber]: 'case-number',
  [COLUMN_NAMES.customer]: 'customer-name',
  [COLUMN_NAMES.unitNo]: 'unit-number',
  [COLUMN_NAMES.dealer]: 'dealer-name',
  [COLUMN_NAMES.eta]: 'eta',
};

export const sortByParamToColumnName = invert(columnNameToSortByParam);

// CasesSearch only understands the case status defined in the
// API schema, so we need to map them to the pretty values we
// want to use instead.
export const apiCaseStatusToStatusParam = {
  [CASE_STATUS.new]: 'case-creation',
  [CASE_STATUS.dispatch]: 'to-dispatch',
  [CASE_STATUS.dispatched]: 'dispatched',
  [CASE_STATUS.rolling]: 'vehicle-rolling',
  [CASE_STATUS.canceled]: 'canceled',
  [CASE_STATUS.closed]: 'closed',
  [CASE_STATUS.closed_canceled]: 'closed_canceled',
  [CASE_STATUS.dry_run_canceled]: 'dry_run_canceled',
};

export const statusParamToAPICaseStatus = invert(apiCaseStatusToStatusParam);

// Don't transform the values of these params to lower case,
// either because we want to display the value as-is (`query`) or
// because the value's case makes it distinct (pagination cursors).
export const paramsWithPreservedCase = ['query', 'before', 'after'];
