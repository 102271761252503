import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import invariant from 'invariant';

import { px2rem } from 'decisiv-ui-utils';
import Icon from 'decisiv-iconix-react';
import BaseDropdown from 'base-components/blocks/Dropdown';

const getContentElement = (id) =>
  document.querySelector(`[data-subdropdown-content="${id}"]`);

const getTriggerElement = (id) =>
  document.querySelector(`[data-subdropdown-trigger="${id}"]`);

const SubDropdownContext = React.createContext();

const useSubDropdownContext = (id) => {
  const context = React.useContext(SubDropdownContext);
  invariant(
    context,
    'SubDropdown compound components cannot be rendered outside the SubDropdown component',
  );
  invariant(
    id,
    'SubDropdown compound components must be rendered with an id prop',
  );

  const dropdownId = `${context.parentId}.${id}`.toLowerCase();
  const open = context.open[dropdownId];
  const { registerId } = context;

  React.useEffect(() => {
    registerId(dropdownId);
  }, [registerId, dropdownId]);

  return { open, dropdownId };
};

const useHandleHover = (ids, handleOpen) => {
  const closingTimeoutById = React.useRef({});
  const handleHover = React.useCallback(
    (id) => {
      const triggerEl = getTriggerElement(id);
      const contentEl = getContentElement(id);
      if (!contentEl || !triggerEl) return;

      function clearClosingTimeout(id) {
        if (closingTimeoutById.current[id]) {
          clearTimeout(closingTimeoutById.current[id]);
        }
      }

      function handleMouseEnter() {
        clearClosingTimeout(id);
        handleOpen(id, true);
      }

      function handleMouseLeave(e) {
        clearClosingTimeout(id);
        // use a timeout to allow the mouse to move from the trigger to the content
        closingTimeoutById.current[id] = setTimeout(() => {
          const contentEl = getContentElement(id);
          if (contentEl?.contains(e.relatedTarget)) return;
          handleOpen(id, false);
        }, 500);
      }

      contentEl.addEventListener('mouseenter', handleMouseEnter);
      triggerEl.addEventListener('mouseenter', handleMouseEnter);
      triggerEl.addEventListener('focusin', handleMouseEnter);
      contentEl.addEventListener('mouseleave', handleMouseLeave);
      triggerEl.addEventListener('mouseleave', handleMouseLeave);
      triggerEl.addEventListener('focusout', handleMouseLeave);
      return () => {
        contentEl.removeEventListener('mouseenter', handleMouseEnter);
        triggerEl.removeEventListener('mouseenter', handleMouseEnter);
        triggerEl.removeEventListener('focusin', handleMouseEnter);
        contentEl.removeEventListener('mouseleave', handleMouseLeave);
        triggerEl.removeEventListener('mouseleave', handleMouseLeave);
        triggerEl.removeEventListener('focusout', handleMouseLeave);
      };
    },
    [handleOpen],
  );

  React.useEffect(() => {
    const removeListeners = ids.map((id) => handleHover(id));
    return () => {
      removeListeners?.forEach((removeListener) => removeListener?.());
    };
  }, [handleHover, ids]);
};

const SubDropdownProvider = ({ isAvailable, children }) => {
  const parentId = React.useMemo(() => uuid(), []);
  const [registeredIds, setRegisteredIds] = React.useState([]);
  const [open, setOpen] = React.useState({});

  React.useEffect(() => {
    // close all subdropdowns when the parent dropdown closes
    if (!isAvailable) setOpen({});
  }, [isAvailable]);

  const handleOpen = React.useCallback((id, value) => {
    const next = { [id]: value };
    setOpen((prev) => (value ? next : { ...prev, ...next }));
  }, []);

  const registerId = React.useCallback(
    (id) => setRegisteredIds((prev) => [...new Set([...prev, id])]),
    [],
  );

  useHandleHover(registeredIds, handleOpen);

  const value = React.useMemo(() => ({ open, parentId, registerId }), [
    open,
    parentId,
    registerId,
  ]);

  return (
    <SubDropdownContext.Provider value={value}>
      {children}
    </SubDropdownContext.Provider>
  );
};

SubDropdownProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isAvailable: PropTypes.bool,
};

const SubDropdownTrigger = React.forwardRef(({ children, ...props }, ref) => {
  const { open, dropdownId } = useSubDropdownContext(props.id);
  const modifiers = (props?.modifiers || []).concat(open ? 'selected' : []);
  return (
    <BaseDropdown.ListItem
      data-subdropdown-trigger={dropdownId}
      style={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      {...props}
      modifiers={modifiers}
      ref={ref}
    >
      {children}
      <Icon
        modifiers={['textLight', 'mini']}
        name="chevron-right"
        style={{ textDecoration: 'none' }}
      />
    </BaseDropdown.ListItem>
  );
});

SubDropdownTrigger.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

const SubDropdownContent = ({ id, children }) => {
  const { open, dropdownId } = useSubDropdownContext(id);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });
  const positionRef = React.useRef(position);

  React.useLayoutEffect(() => {
    const contentEl = getContentElement(dropdownId);
    const triggerEl = getTriggerElement(dropdownId);
    if (!contentEl || !triggerEl || !open) return;

    const triggerRect = triggerEl.getBoundingClientRect();
    const contentRect = contentEl.getBoundingClientRect();

    // if the content is already positioned, use that as the starting point
    const prevTop = positionRef.current.top;
    const prevLeft = positionRef.current.left;

    // make sure the content is always aligned with the trigger
    const top = prevTop + triggerRect.top - contentRect.top;
    let left = triggerRect.width;

    // if the content is going to overflow the right side of the window
    // move it to the left of the trigger
    if (triggerRect.right + contentRect.width > window.innerWidth) {
      left = prevLeft + triggerRect.x - contentRect.x - contentRect.width;
    }

    positionRef.current = { top, left };
    setPosition(positionRef.current);
  }, [open, dropdownId]);

  return (
    <BaseDropdown.Content
      data-subdropdown-content={dropdownId}
      hideContent={!open}
      style={{
        maxHeight: px2rem(300),
        overflowY: 'auto',
        top: '100%',
        left: '0',
        zIndex: 3,
        transform: `translate(${position.left}px, ${position.top}px)`,
      }}
    >
      {children}
    </BaseDropdown.Content>
  );
};

SubDropdownContent.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default {
  Provider: SubDropdownProvider,
  Trigger: SubDropdownTrigger,
  Content: SubDropdownContent,
};
