import { useState, useEffect } from 'react';
import { get, lowerCase } from 'lodash';

import assetTypeNames from '../assetTypeNames';
import trailerTypeNames from '../trailerTypeNames';
import unitAttributeNames from '../unitAttributeNames';

export const getIsLoading = (props) =>
  props.isLoadingAssetTypes ||
  props.isLoadingTrailerTypes ||
  props.isLoadingInboundProgram;

export const getAssetBaseName = (props) => props.name.replace('.assetType', '');

export const getAssetType = (props) =>
  assetTypeNames[get(props.values, props.name, '')];

export const getTrailerType = (props) => {
  const assetBaseName = getAssetBaseName(props);
  return trailerTypeNames[get(props.values, `${assetBaseName}.trailerType`)];
};

export const getUnitAttribute = (props) => {
  const assetBaseName = getAssetBaseName(props);
  const unitAttribute = get(props.values, `${assetBaseName}.unitAttribute`);
  return unitAttributeNames[unitAttribute] || unitAttribute;
};

export const parseInputValue = (value = '') => {
  const [activeTypeValue, unitAttributeValue] = value?.split(' - ');
  return { activeTypeValue, unitAttributeValue };
};

export const getInputValue = ({ assetType, unitAttribute }) => {
  const assetTypeValue = assetTypeNames[assetType] || assetType;
  const unitAttributeValue = unitAttributeNames[unitAttribute] || unitAttribute;
  return unitAttributeValue
    ? `${assetTypeValue} - ${unitAttributeValue}`
    : assetTypeValue;
};

export const useInitialInputValue = (props) => {
  const { isUsxpress } = props;
  const isLoading = getIsLoading(props);

  const assetType = getAssetType(props);
  const unitAttribute = getUnitAttribute(props);
  const trailerType = getTrailerType(props);

  const isTrailerType =
    lowerCase(assetType) === lowerCase(assetTypeNames.TRAILER);

  const initialInputValue = isLoading
    ? ''
    : isUsxpress && isTrailerType && trailerType
    ? trailerType
    : getInputValue({ assetType, unitAttribute });

  const [inputValue, setInputValue] = useState(initialInputValue);

  useEffect(() => {
    if (!isLoading) {
      setInputValue(initialInputValue);
    }
  }, [isLoading, initialInputValue]);

  return [inputValue, setInputValue];
};

export const useUnitTypes = (props, inputValue) => {
  const { assetTypes, trailerTypes, isUsxpress } = props;

  const assetTypesWithoutTrailer = assetTypes.filter(
    (name) => name !== 'TRAILER',
  );

  const assetTypesToUse = isUsxpress ? assetTypesWithoutTrailer : assetTypes;

  const filterTypesByValue = (types, value) =>
    types.filter((name) => lowerCase(name).includes(lowerCase(value)));

  const filteredAssetTypes = filterTypesByValue(assetTypesToUse, inputValue);
  const filteredTrailerTypes = filterTypesByValue(trailerTypes, inputValue);

  const activeType =
    assetTypesToUse.filter((type) => assetTypeNames[type] === inputValue)[0] ||
    '';

  const isInvalidTrailerType =
    isUsxpress &&
    !Object.values(trailerTypeNames).filter(
      (name) => lowerCase(name) === lowerCase(inputValue),
    )[0];

  const isInvalidAssetType = !!inputValue && activeType === '';
  const isInvalidType = isInvalidTrailerType || isInvalidAssetType;

  const isNotFound =
    isInvalidType &&
    filteredTrailerTypes.length + filteredAssetTypes.length === 0;

  return {
    filteredAssetTypes,
    filteredTrailerTypes,
    activeType,
    isInvalidType,
    isNotFound,
  };
};
