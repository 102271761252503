/* istanbul ignore file */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { t, Trans } from '@lingui/macro';
import { get, find } from 'lodash';

import { Text, Popover, ButtonMicro, Tooltip } from 'base-components';
import { FormattedRelative } from 'decisiv-domain-utils';
import { px2rem } from 'decisiv-ui-utils';
import { Container, Row, Column } from 'styled-components-grid';

import CaseStatusBadge from 'elements/CaseStatusBadge';
import getActiveCaseDelay from 'utils/getActiveCaseDelay';

import CaseDelaysTimeline from 'compositions/CaseDetailOverviewPanel/ManageDelaysModal/CaseDelaysTimeline';
import { CASE_STATUS } from 'compositions/CaseStatus';
import { ITEMS_PER_PAGE } from 'compositions/CasesSearch/constants';
import { getDateAtAgentTZ } from 'compositions/CaseRequestsPanel/CaseRequestsPanelHeader/utils';

import HeaderCell from '../HeaderCell';
import { COLUMN_NAMES } from '../constants';
import { buildBaseColumnConfig } from '../utils';

const canceledStatuses = [CASE_STATUS.canceled, CASE_STATUS.closed_canceled];

const CASE_STATUSES = {
  [CASE_STATUS.new]: t`Case Creation`,
  [CASE_STATUS.dispatch]: t`To Dispatch`,
  [CASE_STATUS.dispatched]: t`Dispatched`,
  [CASE_STATUS.enRoute]: t`En Route`,
  [CASE_STATUS.arrived]: t`Arrived`,
  [CASE_STATUS.rolling]: t`Vehicle Rolling`,
  [CASE_STATUS.closed]: t`Closed`,
  [CASE_STATUS.canceled]: t`Canceled`,
  [CASE_STATUS.closed_canceled]: t`Closed - Canceled`,
  [CASE_STATUS.dry_run_canceled]: t`Canceled - Dry Run`,
};

function StatusIcon(props) {
  const { icon, statusColor, popoverPosition } = props;
  const { popoverContent, showOnHover, tooltipContent } = props;

  const renderTooltip = (target) => (
    <Tooltip position="top">
      <Tooltip.Target>{target}</Tooltip.Target>
      <Tooltip.Content>{tooltipContent}</Tooltip.Content>
    </Tooltip>
  );

  const popoverTarget = (toggle) => (
    <Popover.Target>
      <ButtonMicro
        style={{ cursor: showOnHover ? 'default' : 'pointer' }}
        modifiers={[statusColor, 'mini']}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
      >
        <ButtonMicro.Icon name={icon} style={{ paddingRight: 0 }} />
      </ButtonMicro>
    </Popover.Target>
  );

  return (
    <Column modifiers="padScaleY_0">
      <Popover
        arrow
        showOnHover={showOnHover}
        position={popoverPosition}
        zIndex={2}
      >
        {({ toggle }) => (
          <>
            {tooltipContent
              ? renderTooltip(popoverTarget(toggle))
              : popoverTarget(toggle)}
            {popoverContent}
          </>
        )}
      </Popover>
    </Column>
  );
}

StatusIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  popoverContent: PropTypes.node.isRequired,
  statusColor: PropTypes.string.isRequired,
  popoverPosition: PropTypes.string,
  showOnHover: PropTypes.bool,
  tooltipContent: PropTypes.node,
};

StatusIcon.defaultProps = {
  popoverPosition: undefined,
  showOnHover: true,
  tooltipContent: undefined,
};

export function CaseStatusCell(props) {
  const { hasFailedAutomatedCall } = props;
  const { assetLocationTimezone: tz, caseDelays } = props;
  const { status, createdAt, isDelayed, statusHistory } = props;
  const { delayedServiceScheduledDate: delayedScheduledDate } = props;
  const { delayedServiceScheduledDispatchDate: delayedDispatchDate } = props;
  const { quickNote, rowIndex, isDelayedService, delayedServiceNotes } = props;

  const activeDelays = isDelayed && getActiveCaseDelay(caseDelays, true);
  const reversedHistory = [...statusHistory].reverse();

  const date =
    status === CASE_STATUS.new
      ? createdAt
      : get(find(reversedHistory, { newStatus: status }), 'changedAt');

  const showDelayedService =
    isDelayedService && !canceledStatuses.includes(status);
  const showDelaysPopover = (isDelayed && activeDelays) || showDelayedService;
  const dispatchDateAtAgentTZ = getDateAtAgentTZ(delayedDispatchDate, tz);

  const showDispatchAtDate =
    isDelayedService &&
    dispatchDateAtAgentTZ &&
    [CASE_STATUS.new, CASE_STATUS.dispatch].includes(status);

  return (
    <Container modifiers="padScaleY_2">
      <Row>
        <Column>
          <CaseStatusBadge status={status} />
        </Column>
        <Column modifiers={['col', 'padScaleY_0']}>
          <Row>
            <Column modifiers="padScaleY_0">
              <Text>
                <Trans id={CASE_STATUSES[status]} />
              </Text>
            </Column>
            {quickNote && (
              <StatusIcon
                icon="info-circle"
                statusColor="info"
                popoverPosition={rowIndex > 0 ? 'topRight' : 'bottomRight'}
                popoverContent={
                  <Popover.Content style={{ left: px2rem(-20) }}>
                    <Container modifiers={['padScaleX_4', 'padScaleY_2']}>
                      <Text modifiers="noWrap">{quickNote}</Text>
                    </Container>
                  </Popover.Content>
                }
              />
            )}
            {hasFailedAutomatedCall && (
              <StatusIcon
                icon="phone-arrow"
                statusColor="danger"
                popoverPosition={rowIndex > 0 ? 'topRight' : 'bottomRight'}
                popoverContent={
                  <Popover.Content style={{ left: px2rem(-20) }}>
                    <Container modifiers={['padScaleX_4', 'padScaleY_2']}>
                      <Text modifiers="noWrap">
                        <Trans>
                          Failed to notify one or more contacts about the ETA or
                          Roll Time information.
                        </Trans>
                      </Text>
                    </Container>
                  </Popover.Content>
                }
              />
            )}
            {showDelaysPopover && (
              <StatusIcon
                icon="hourglass"
                statusColor="danger"
                showOnHover={false}
                popoverPosition={
                  rowIndex < ITEMS_PER_PAGE / 2 ? 'bottomRight' : 'topRight'
                }
                tooltipContent={<Trans>See delays</Trans>}
                popoverContent={
                  <Popover.Content
                    style={{
                      width: px2rem(360),
                      cursor: 'default',
                      marginLeft: px2rem(-20),
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Row>
                      <Column modifiers={['padScale_3', 'flex_1']}>
                        <CaseDelaysTimeline
                          {...{
                            status,
                            caseDelays: activeDelays || [],
                            showDelayedService,
                            delayedServiceNotes,
                            assetLocationTimezone: tz,
                            delayedServiceScheduledDate: delayedScheduledDate,
                            delayedServiceScheduledDispatchDate: delayedDispatchDate,
                          }}
                        />
                      </Column>
                    </Row>
                  </Popover.Content>
                }
              />
            )}
          </Row>
          <Row>
            <Column modifiers={['col', 'padScaleY_0']}>
              <Text modifiers={['small', 'textLight']}>
                <FormattedRelative
                  value={date}
                  render={(value) => (
                    <span>
                      {value}
                      &nbsp;&nbsp;&middot;&nbsp;&nbsp;
                    </span>
                  )}
                />
              </Text>
              <Text modifiers={['small', 'textLight']}>
                {moment(date).format('D MMM, h:mm A z')}
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
      {showDispatchAtDate && (
        <Row>
          <Column modifiers="col">
            <Text modifiers="warning">
              <Trans>Dispatch at: {dispatchDateAtAgentTZ}</Trans>
            </Text>
          </Column>
        </Row>
      )}
    </Container>
  );
}

CaseStatusCell.propTypes = {
  status: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  isDelayed: PropTypes.bool.isRequired,
  statusHistory: PropTypes.arrayOf(PropTypes.shape({})),
  caseDelays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      startTime: PropTypes.string.isRequired,
      endTime: PropTypes.string,
    }),
  ),
  quickNote: PropTypes.string,
  rowIndex: PropTypes.number.isRequired,
  isDelayedService: PropTypes.bool,
  delayedServiceNotes: PropTypes.string,
  assetLocationTimezone: PropTypes.string,
  delayedServiceScheduledDate: PropTypes.string,
  delayedServiceScheduledDispatchDate: PropTypes.string,
  hasFailedAutomatedCall: PropTypes.bool.isRequired,
};

CaseStatusCell.defaultProps = {
  status: CASE_STATUS.new,
  statusHistory: [],
  caseDelays: [],
  quickNote: null,
  isDelayedService: null,
  delayedServiceNotes: null,
  assetLocationTimezone: null,
  delayedServiceScheduledDate: null,
  delayedServiceScheduledDispatchDate: null,
};

export default (...args) => ({
  ...buildBaseColumnConfig(COLUMN_NAMES.status, ...args),
  cellDataGetter: (data) => data,
  headerCellRenderer: (props) => (
    <HeaderCell {...props} label={<Trans>Status</Trans>} />
  ),
  dataCellRenderer: (data, { rowIndex }) => (
    <CaseStatusCell {...data.node} rowIndex={rowIndex} />
  ),
});
