import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { compose, setDisplayName } from 'recompose';

import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';
import { Text } from 'base-components';
import { Column, Row } from 'styled-components-grid';

import withContext from 'utils/withContext';
import CaseStatusBadge from 'elements/CaseStatusBadge';

import { CASE_STATUS } from 'compositions/CaseStatus';

import StaticListDropdown from './StaticListDropdown';
import ReportConfigBuilderContext from './Context';

import {
  configTypes,
  configTypesPlaceholders,
  statusTitles,
} from './constants';

const options = [
  [CASE_STATUS.new],
  [CASE_STATUS.dispatch],
  [CASE_STATUS.dispatched],
  [CASE_STATUS.rolling],
  [CASE_STATUS.canceled],
  [CASE_STATUS.closed],
  [CASE_STATUS.dry_run_canceled],
];

const StatusOption = buildStyledComponent(
  'StatusOption',
  styled(Row),
  ({ theme }) => css`
    padding: ${px2rem(5)} ${px2rem(10)};
    flex: 1;
    align-items: center;
    margin: ${px2rem(-5)} ${px2rem(-10)};

    span[class] {
      text-decoration: none;
    }

    .StyledBadge span {
      color: ${theme.colors.base.chrome000};
    }
  `,
  {
    themePropTypes: {
      colors: PropTypes.shape({
        base: PropTypes.shape({
          chrome000: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    },
  },
);

const renderOption = (status) => (
  <StatusOption>
    <Column modifiers="padScale_0">
      <CaseStatusBadge status={status} />
    </Column>
    <Column modifiers={['col', 'padScaleX_2', 'padScaleY_0']}>
      <Text>
        <Trans id={statusTitles[status]} />
      </Text>
    </Column>
  </StatusOption>
);

const StatusListDropdown = ({ config, updateReportConfig }) => (
  <StaticListDropdown
    name={configTypes.status}
    label={<Trans>Status</Trans>}
    value={
      (statusTitles?.[config?.[configTypes.status]] &&
        i18n._(statusTitles[config[configTypes.status]])) ||
      ''
    }
    style={{ marginTop: px2rem(10) }}
    options={options}
    onChange={updateReportConfig}
    clearable
    activeItem={config[configTypes.status]}
    placeholder={i18n._(configTypesPlaceholders[configTypes.status])}
    optionRenderer={renderOption}
  />
);

StatusListDropdown.propTypes = {
  config: PropTypes.shape({}).isRequired,
  updateReportConfig: PropTypes.func.isRequired,
};

export default compose(
  setDisplayName('StatusListDropdown'),
  withContext(ReportConfigBuilderContext),
)(StatusListDropdown);
