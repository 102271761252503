import styled from 'styled-components';
import PropTypes from 'prop-types';
import { buildStyledComponent, px2rem } from 'decisiv-ui-utils';

import SelectableLi from '../../elements/SelectableLi';

const styles = ({ theme }) => `
  display: flex;
  padding: ${px2rem(5)} ${px2rem(10)};
  &:hover, &:focus {
    cursor: pointer;

    a {
      color: ${theme.colors.base.linkHover};
      text-decoration: underline;
    }
  }
`;

const themePropTypes = {
  colors: PropTypes.shape({
    base: PropTypes.shape({
      linkHover: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

// these modifiers are passed down to ContainerSelectable
// and are not on the li element directly
const propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  modifiers: [],
  type: 'button',
};

export default buildStyledComponent(
  'Dropdown__ListItem',
  styled(SelectableLi),
  styles,
  {
    defaultProps,
    propTypes,
    themePropTypes,
  },
);
