import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { compose, setDisplayName } from 'recompose';

import { InputField } from 'base-components';
import { Column, Row } from 'styled-components-grid';
import VisuallyHidden from 'elements/VisuallyHidden';

import withFocusReceiver from 'setup/FocusProvider/withFocusReceiver';

/**
 * This is the read-only input field that shows the current selected
 * value for the `UnitTypeDropdown`.
 */
export const UnitTypeInputField = (props) => {
  const { isDropdownVisible, onChange, onToggle, onReset, innerRef } = props;
  const { onBlur, value, readOnly, isValid, onFocusRequested } = props;

  return (
    <InputField
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      readOnly={readOnly}
      isValid={isValid}
      onFocus={onToggle}
    >
      <Column modifiers={['col', 'padScaleY_0']}>
        <Row>
          <InputField.Label>
            <Trans>Unit Type</Trans>
          </InputField.Label>
        </Row>
        <Row>
          <InputField.TextField
            ref={(ref) => {
              onFocusRequested(ref);
              innerRef?.(ref);
            }}
          />
          {value && (
            <InputField.ActionButton
              icon="times"
              title="Clear"
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
              modifiers={['padScaleX_0', 'hoverDanger']}
              tabIndex={-1}
            />
          )}
          <InputField.ActionButton
            icon={isDropdownVisible ? 'chevron-up' : 'chevron-down'}
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            modifiers={['hoverInfo']}
            tabIndex={-1}
          />
        </Row>
      </Column>

      {value && (
        <VisuallyHidden>
          <Trans>Unit Type:</Trans>
          {value}
        </VisuallyHidden>
      )}
    </InputField>
  );
};

UnitTypeInputField.propTypes = {
  isDropdownVisible: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  value: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  focusReceiverId: PropTypes.string.isRequired,
  onFocusRequested: PropTypes.func.isRequired,
  innerRef: PropTypes.func,
};

UnitTypeInputField.defaultProps = {
  value: '',
};

export default compose(
  setDisplayName('UnitTypeInputField'),
  withFocusReceiver((p) => p.focusReceiverId),
)(UnitTypeInputField);
