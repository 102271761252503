/* eslint-disable import/prefer-default-export */
import { t } from '@lingui/macro';

const defaultFieldConfig = {
  maxLength: 50,
};

export const fieldsConfig = {
  poNumber: {
    ...defaultFieldConfig,
    label: t`PO Number`,
    optionalLabel: t`PO Number (optional)`,
    placeholder: t`Enter PO number...`,
    shortcut: ['b', '1'],
  },

  referenceNumber: {
    ...defaultFieldConfig,
    label: t`Reference Number`,
    optionalLabel: t`Reference Number (optional)`,
    placeholder: t`Enter reference number...`,
    shortcut: ['b', '2'],
  },

  ticketNumber: {
    ...defaultFieldConfig,
    label: t`Ticket/DR Number`,
    optionalLabel: t`Ticket/DR Number (optional)`,
    placeholder: t`Enter ticket/DR number...`,
    shortcut: ['b', '3'],
  },

  woNumber: {
    ...defaultFieldConfig,
    label: t`Work Order Number`,
    optionalLabel: t`Work Order Number (optional)`,
    placeholder: t`Enter work order number...`,
    shortcut: ['b', '4'],
  },

  invoiceNumber: {
    ...defaultFieldConfig,
    label: t`Invoice Number`,
    optionalLabel: t`Invoice Number (optional)`,
    placeholder: t`Enter invoice number...`,
    shortcut: ['b', '5'],
  },

  contractNumber: {
    ...defaultFieldConfig,
    label: t`Contract Number`,
    optionalLabel: t`Contract Number (optional)`,
    placeholder: t`Enter contract number...`,
    shortcut: ['b', '6'],
  },

  releaseNumber: {
    ...defaultFieldConfig,
    label: t`Release Number`,
    optionalLabel: t`Release Number (optional)`,
    placeholder: t`Enter release number...`,
    shortcut: ['b', '7'],
  },

  amount: {
    ...defaultFieldConfig,
    label: t`Amount`,
    optionalLabel: t`Amount (optional)`,
    placeholder: t`Enter amount...`,
    textFieldPrefix: '$',
    pattern: /^[0-9,.]+$/,
    shortcut: ['b', '8'],
  },

  comment: {
    ...defaultFieldConfig,
    label: t`Billing Comment`,
    optionalLabel: t`Billing Comment (optional)`,
    placeholder: t`Enter Comment...`,
    maxLength: 300,
    multiline: true,
    showCharsCounter: true,
    shortcut: ['b', '9'],
  },
};

export const ruleTypesLabels = {
  REQUIRED: t`Required Billing Preference:`,
  REQUIRED_BEFORE_ERS: t`Required Before ERS:`,
};

export const requiredFieldsLabels = {
  poNumber: t`PO Number`,
  contractNumber: t`Contract Number`,
  releaseNumber: t`Release Number`,
  referenceNumber: t`Reference Number`,
};

export const requiredFieldsTooltip = t`Billing preference required by fleet. If fleet fails to provide value, continue with case dispatch to service provider for completion.`;
