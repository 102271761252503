import { t } from '@lingui/macro';
import buildEnum from 'utils/buildEnum';

export const PANEL_TITLE = t`Fleet`;

const keyFor = (type) => (key) => `customer.${type}.${key}`;

export const fieldIds = {
  national: buildEnum(['customer', 'domicile', 'terminal'], keyFor('national')),
  store: buildEnum(['customer'], keyFor('store')),
  customStore: buildEnum(['name'], keyFor('customStore')),
  other: buildEnum(['name', 'zip'], keyFor('other')),
};
