import { stringify } from 'qs';

const URL = 'https://maps.googleapis.com/maps/api/js';

/**
 * Build the URL to retrieve the Google Maps API JavaScript, based
 * on the specified options.
 * @param {String} apiKey
 * @param {Array<String>} libraries
 * @param {String} clientID
 * @param {String} version
 * @param {String} callback
 * @param {String} channel
 * @param {String} language
 * @param {String} region
 * @returns {string}
 */
export function googleMapsApi({
  apiKey = process.env.REACT_APP_GOOGLE_API_KEY,
  libraries = ['geometry', 'drawing', 'places'],
  clientID,
  version = process.env.REACT_APP_GOOGLE_MAP_VERSION || '3.52',
  callback,
  channel,
  language,
  region,
} = {}) {
  if (!(apiKey || clientID)) {
    throw new Error(
      'googleMapsApi() requires a either an apiKey or a clientID',
    );
  }

  // client ID and API key are mutually-exclusive - see
  // https://developers.google.com/maps/documentation/javascript/get-api-key#clientID-features
  if (apiKey && clientID) {
    throw new Error(
      'googleMapsApi() cannot accept BOTH an apiKey and a clientID',
    );
  }

  const params = {
    key: apiKey,
    callback,
    libraries: libraries.join(','),
    client: clientID,
    v: version,
    channel,
    language,
    region,
  };

  const paramStr = stringify(params, { skipNulls: true });

  return `${URL}?${paramStr}`;
}

export default googleMapsApi;
